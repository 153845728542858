<template>
  <svg
    id="Component_38_1"
    data-name="Component 38 – 1"
    height="33.028"
    viewBox="0 0 33.028 33.028"
    width="33.028"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Bg"
      d="M16.514,0A16.514,16.514,0,1,1,0,16.514,16.514,16.514,0,0,1,16.514,0Z"
      fill="#50badd"
    />
    <g id="Group_88" data-name="Group 88" transform="translate(7.142 7.141)">
      <rect
        id="Rectangle_6"
        data-name="Rectangle 6"
        fill="none"
        height="5"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(0.859 0.859)"
        width="5"
      />
      <rect
        id="Rectangle_7"
        data-name="Rectangle 7"
        fill="none"
        height="5"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(12.859 0.859)"
        width="5"
      />
      <rect
        id="Rectangle_8"
        data-name="Rectangle 8"
        fill="none"
        height="5"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(0.859 12.859)"
        width="5"
      />
      <rect
        id="Rectangle_9"
        data-name="Rectangle 9"
        fill="none"
        height="5"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(12.859 12.859)"
        width="5"
      />
      <line
        id="Line_8"
        data-name="Line 8"
        fill="none"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(8.859 -0.141)"
        y2="4"
      />
      <line
        id="Line_9"
        data-name="Line 9"
        fill="none"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(-0.141 9.859)"
        x1="3"
      />
      <path
        id="Path_5"
        d="M100.227,61v3.171H96"
        data-name="Path 5"
        fill="none"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(-91.002 -54.639)"
      />
      <line
        id="Line_10"
        data-name="Line 10"
        fill="none"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(11.859 9.859)"
        x2="3"
      />
      <line
        id="Line_11"
        data-name="Line 11"
        fill="none"
        stroke="#285197"
        stroke-miterlimit="10"
        stroke-width="2"
        transform="translate(8.859 11.859)"
        y2="4"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconProfileQrcode'
}
</script>

<style scoped></style>
