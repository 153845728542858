<template>
  <div class="bottom-nav">
    <router-link class="bottom-nav-item" to="/storyline">
      <IconHome :color="selectedFeed ? 'white' : undefined" />
    </router-link>
    <router-link class="bottom-nav-item" to="/search">
      <IconSearch :color="selectedSearch ? 'white' : undefined" />
    </router-link>
<!--    <router-link class="bottom-nav-item" to="/qrcode">-->
<!--      <IconQRCode :color="selectedQrCode ? 'white' : undefined" />-->
<!--    </router-link>-->
    <router-link class="bottom-nav-item" to="/following">
      <IconLike :color="selectedLikes ? 'white' : undefined" />
    </router-link>
    <router-link class="bottom-nav-item" to="/account">
      <IconProfile :color="selectedAccount ? 'white' : undefined" />
    </router-link>
  </div>
</template>

<script>
import IconHome from '@/assets/IconHome'
import IconSearch from '@/assets/IconSearch'
// import IconQRCode from '@/assets/IconQRCode'
import IconLike from '@/assets/IconLike'
import IconProfile from '@/assets/IconProfile'

export default {
  name: 'BottomNav',
  components: { IconProfile, IconLike, IconSearch, IconHome },
  data() {
    return {
      selectedFeed: false,
      selectedSearch: false,
      selectedQrCode: false,
      selectedLikes: false,
      selectedAccount: false
    }
  },
  mounted() {
    this.checkSelection(this.$route)
  },
  watch: {
    $route(to) {
      this.checkSelection(to)
    }
  },
  methods: {
    checkSelection(route) {
      route.matched.forEach(e => {

        if (e.name === 'feed') {
          this.selectedFeed = true
          this.selectedSearch = false
          this.selectedQrCode = false
          this.selectedLikes = false
          this.selectedAccount = false
        } else if (e.name === 'search') {
          this.selectedFeed = false
          this.selectedSearch = true
          this.selectedQrCode = false
          this.selectedLikes = false
          this.selectedAccount = false
        } else if (e.name === 'qr_code') {
          this.selectedFeed = false
          this.selectedSearch = false
          this.selectedQrCode = true
          this.selectedLikes = false
          this.selectedAccount = false
        } else if (e.name === 'following') {
          this.selectedFeed = false
          this.selectedSearch = false
          this.selectedQrCode = false
          this.selectedLikes = true
          this.selectedAccount = false
        } else if (e.name === 'setting') {
          this.selectedFeed = false
          this.selectedSearch = false
          this.selectedQrCode = false
          this.selectedLikes = false
          this.selectedAccount = true
        }
      })
    }
  }
}
</script>

<style scoped>
.bottom-nav {
  height: 48px;
  background: #162740;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px #80000000;
}

.bottom-nav-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
