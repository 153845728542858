<template>
  <div>
    <top-nav title="Update Storyline" show-back />
    <perfect-scrollbar class="flex-fill">
      <h2 class="text-center text-primary title-text">
        Select a privacy setting for {{ this.name }}’s Storyline.
      </h2>
      <div
        :class="isPublic ? 'active-item' : ''"
        class="flex-container row-container privacy-item separator"
        @click="privacyType = 'PUBLIC'"
      >
        <IconPublic :color="isPublic ? '#F5FF00' : null" />
        <div class="flex-fill flex-container column-container option-container">
          <h4 :class="isPublic ? 'text-white' : 'text-gray-2'">
            Public
          </h4>
          <p :class="isPublic ? 'text-white' : 'text-gray-2'">
            Storyline can be viewed by anyone who performs a search on the app,
            scans the associated QR code, or follows a shared link.
          </p>
        </div>
      </div>
      <div
        :class="isUnlisted ? 'active-item' : ''"
        class="flex-container row-container privacy-item separator"
        @click="privacyType = 'UNLISTED'"
      >
        <IconUnlisted :color="isUnlisted ? '#F5FF00' : null" />
        <div class="flex-fill flex-container column-container option-container">
          <h4 :class="isUnlisted ? 'text-white' : 'text-gray-2'">
            Unlisted
          </h4>
          <p :class="isUnlisted ? 'text-white' : 'text-gray-2'">
            Storyline can be viewed by someone who scans the associated QR code
            or follows a shared link. It will not appear in general searches.
          </p>
        </div>
      </div>
      <div
        :class="isPrivate ? 'active-item' : ''"
        class="flex-container row-container privacy-item"
        v-on:click="privacyType = 'PRIVATE'"
      >
        <IconPrivate :color="isPrivate ? '#F5FF00' : null" />
        <div class="flex-fill flex-container column-container option-container">
          <h4 :class="isPrivate ? 'text-white' : 'text-gray-2'">
            Private
          </h4>
          <p :class="isPrivate ? 'text-white' : 'text-gray-2'">
            Only those who request access and are approved by a Storyeditor may
            view the Storyline.
          </p>
        </div>
      </div>
    </perfect-scrollbar>
    <button class="primary-button button-container" @click="onSave">
      Save
    </button>
  </div>
</template>

<script>
import TopNav from '../Shared/TopNav'
import { request } from '../../utils/interceptor'
import IconPublic from '../../assets/IconPublic'
import IconUnlisted from '../../assets/IconUnlisted'
import IconPrivate from '../../assets/IconPrivate'

export default {
  name: 'EditorUpdatePrivacy',
  components: { IconPrivate, IconUnlisted, IconPublic, TopNav },
  data() {
    return {
      privacyType: 'PUBLIC',
      storyline: {}
    }
  },
  mounted() {
    request.get(`/storylines/${this.$route.params.storylineId}`).then(e => {
      this.storyline = e.data
      this.privacyType = e.data.privacy
    })
  },
  computed: {
    isPublic() {
      return this.privacyType === 'PUBLIC'
    },
    isPrivate() {
      return this.privacyType === 'PRIVATE'
    },
    isUnlisted() {
      return this.privacyType === 'UNLISTED'
    },
    name() {
      return `${this.storyline.first_name} ${this.storyline.middle_name} ${this.storyline.last_name} `
    }
  },
  methods: {
    onSave() {
      request
        .patch(`/storylines/${this.$route.params.storylineId}`, {
          privacy: this.privacyType
        })
        .then(() => {
          this.$notify({
            group: 'storyline',
            title: 'Successfully updated'
          })
          this.$router.back()
        })
        .catch(() =>
          this.$notify({
            group: 'storyline',
            title: "You can't updated this storyline",
            type: 'error'
          })
        )
    }
  }
}
</script>

<style scoped>
.title-text {
  padding: 32px 15vw;
  width: 100%;
}

@media screen and (min-width: 675px) {
  .title-text {
    padding: 32px 100px;
  }
}

.button-container {
  width: calc(100vw - 64px);
  margin: 24px 32px;
}

@media screen and (min-width: 675px) {
  .button-container {
    width: 611px;
  }
}

.privacy-item {
  border-radius: 4px;
  margin: 0 24px;
  padding: 24px 20px;
}

.active-item {
  background: linear-gradient(20deg, #006cb1, #285197 70%);
}

.option-container {
  margin-left: 24px;
}
</style>
