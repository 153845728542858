<template>
  <div class="memory-actions">
    <span v-if="isAuthed" class="action">
      <svg
        v-if="!isLiked"
        class="icon"
        height="18"
        viewBox="0 0 24.243 20.448"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
        @click="likeMemory"
      >
        <path
          class="path"
          d="M17.432,0c-2.078,0-4.509,2.356-5.811,3.874C10.32,2.356,7.889,0,5.811,0,2.132,0,0,2.869,0,6.522,0,12.59,11.622,19.369,11.622,19.369S23.243,12.59,23.243,6.779C23.243,3.127,21.111,0,17.432,0Z"
          fill="none"
          stroke-width="1"
          transform="translate(0.5 0.5)"
        />
      </svg>
      <svg
        v-else
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 15"
        @click="dislikeMemory"
      >
        <path
          id="Shape"
          d="M13.5,0C11.891,0,10.008,1.825,9,3,7.992,1.825,6.109,0,4.5,0,1.651,0,0,2.222,0,5.05,0,9.75,9,15,9,15s9-5.25,9-9.75C18,2.422,16.349,0,13.5,0Z"
          fill="#285197"
        />
      </svg>

      {{ likeCount }}
    </span>
    <span v-if="isAuthed" class="action">
      <svg
        class="icon"
        height="18"
        viewBox="0 0 22.952 21.109"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
        @click="addComment"
      >
        <path
          class="path"
          d="M9.976,19.625,7.7,16.24H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H18.952a3,3,0,0,1,3,3V13.241a3,3,0,0,1-3,3H13.7L11.851,19.55a1.105,1.105,0,0,1-1.876.075Z"
          fill="none"
          stroke-width="1"
          transform="translate(0.5 0.5)"
        />
      </svg>
      <a @click="share" class="icon-share">
        <IconShare color="#d5d6dc" />
      </a>
      <a v-if="isManaging" @click="deleteMemory" class="icon-delete">
        <svg width="32" height="32" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path d="M589 307v-51H435v51H307v51h410v-51M333 410v358h358V410H333zm102 307h-51V461h51v256zm103 0h-52V461h52v256zm102 0h-51V461h51v256z"/>
        </svg>
      </a>
    </span>
    <span v-if="layout !== 'gird'" class="time-ago">{{ daysAgo }}</span>
  </div>
</template>

<style lang="scss" scoped>
.icon-share {
  margin-left: 12px;
  &:hover {
    cursor: pointer;
  }
}
.icon-delete {
  margin-left: 12px;
  margin-top: 2px;
  fill: #d5d6dc;
  &:hover {
    cursor: pointer;
    fill: #e57373;
  }
}
</style>

<script>
import { formatDistance } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import { mapState } from 'vuex'
import { request } from '../../utils/interceptor'
import { MEMORIES_URL } from '../../utils/endpoints'
import IconShare from '../../assets/IconShare'

export default {
  name: 'MemoryActions',
  props: {
    creationDate: String,
    likeCount: Number,
    commentCount: Number,
    isLiked: {
      type: Boolean,
      default: false
    },
    memoryId: String,
    storylineId: String
  },
  components: {
    IconShare
  },
  computed: {
    ...mapState('storyline', ['layout']),
    daysAgo() {
      if (!this.creationDate) return ''
      return formatDistance(parseISO(this.creationDate), Date.now())
    },
    isAuthed() {
      return this.$store.state?.user?.authToken?.length > 0
    },
    isManaging() {
      return this.$store.state?.storyline?.isManaging ?? false
    }
  },
  methods: {
    likeMemory() {
      request
        .post(
          `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
            this.memoryId
          }/like`
        )
        .then(() => {
          this.isLiked = true
          this.likeCount++
        })
    },
    dislikeMemory() {
      request
        .post(
          `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
            this.memoryId
          }/unlike`
        )
        .then(() => {
          this.isLiked = false
          this.likeCount--
        })
    },
    addComment() {
      this.$router.push({
        name: 'memory_comments',
        params: { memoryId: this.memoryId, storylineId: this.storylineId }
      })
    },
    share() {
      const path = this.$router.resolve({
        name: 'memory_details',
        params: { memoryId: this.memoryId, storylineId: this.storylineId }
      }).href;
      const link = "https://app.storyline.co" + path;
      const shareLink = "http://www.facebook.com/share.php?u=" + link;
      
      window.open(shareLink, '_blank');
    },
    deleteMemory() {
      if(confirm('Are you sure you want to delete this memory?')) {
        request
        .delete(
          `${MEMORIES_URL.replace('{id}', this.storylineId)}/${this.memoryId}`
        )
        .then(() => {
          window.location.reload();
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.memory-actions {
  display: flex;
  padding: 20px 35px;
  @media screen and (max-width: 650px) {
    padding: 16px;
  }

  .action {
    align-items: center;
    color: var(--gray-3);
    display: flex;
    font-size: 12px;
    margin-right: 20px;

    .icon {
      margin-right: 8px;
      cursor: pointer;

      .path {
        stroke: #c6c8d1;
      }
    }
  }

  .time-ago {
    color: #c6c8d1;
    font-size: 12px;
    margin-left: auto;
  }
}
</style>
