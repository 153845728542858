<template>
  <svg
    class="icon"
    height="27.545"
    viewBox="0 0 19.283 27.545"
    width="19.283"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0 0)">
      <path
        :fill="layout === 'full' ? '#50badd' : 'white'"
        d="M4529.841,1749.653v-17.51a.887.887,0,0,0-.887-.886h-17.51a.885.885,0,0,0-.886.886v17.51a.886.886,0,0,0,.886.887h17.51a.888.888,0,0,0,.887-.887Zm-1.774-16.623v15.735h-15.736V1733.03Z"
        transform="translate(-4510.558 -1727.07)"
      />
      <path
        :fill="layout === 'full' ? '#50badd' : 'white'"
        d="M4529.841,1753.725a.886.886,0,0,0-.887-.886h-17.51a.887.887,0,0,0,0,1.773h17.51A.886.886,0,0,0,4529.841,1753.725Z"
        transform="translate(-4510.558 -1727.067)"
      />
      <path
        :fill="layout === 'full' ? '#50badd' : 'white'"
        d="M4529.841,1727.957a.886.886,0,0,0-.887-.886h-17.51a.887.887,0,0,0,0,1.773h17.51A.886.886,0,0,0,4529.841,1727.957Z"
        transform="translate(-4510.558 -1727.071)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFull',
  props: {
    layout: String
  }
}
</script>

<style scoped>
. {
  fill: #50badd;
}
</style>
