<template>
  <div
    :class="sliderIndex !== 0 ? 'primary-background' : ''"
    class="column-container height-100"
  >
    <input
      accept="image/*"
      id="fileUpload"
      hidden
      type="file"
      @change="onFileUpload"
    />
    <top-nav
      :description="description"
      :extended="sliderIndex !== 0"
      :message="message"
      :title="title"
      show-back
    />
    <slider
      v-model="sliderIndex"
      :autoplay="false"
      :controlBtn="false"
      :indicators="false"
      animation="fade"
      class="flex-fill slider-container"
      height="auto"
      :touch="false"
    >
      <slider-item>
        <div class="height-100 width-100">
          <perfect-scrollbar
            v-if="images.length > 0"
            class="flex-container row-container primary-background  width-100 images-container"
          >
            <div
              v-for="(image, index) in images"
              :key="index"
              class="image-container"
            >
              <img :src="image" alt="image-upload" class="image-item" />
              <div class="image-overlay width-100 height-100">
                <div class="cancel-button" @click="deleteMedia(index)">
                  <IconClose />
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <perfect-scrollbar class="input-container height-100">
            <p
              v-if="showErrorMessage && !images"
              class="text-primary text-error width-100 text-center"
            >
              A written memory or photo is required.
            </p>
            <textarea
              v-model="body"
              class="width-100 memory-text-edit"
              autofocus
              placeholder="Type your memory"
            />
          </perfect-scrollbar>
        </div>
      </slider-item>
      <slider-item>
        <perfect-scrollbar
          class="flex-fill height-100 input-container flex-container column-container"
        >
          <div class="flex-container row-container">
            <div class="flex-fill category-space">
              <div v-for="(item, index) in lifeCategories" :key="item.id">
                <button
                  v-if="index % 2 === 0"
                  :class="
                    item.id === selectedLifeCategory.id
                      ? 'selected-category'
                      : ''
                  "
                  class="outline-button category-button"
                  v-on:click="onSelectLifeCategory(item)"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
            <div class="flex-fill">
              <div v-for="(item, index) in lifeCategories" :key="item.id">
                <button
                  v-if="index % 2 === 1"
                  :class="
                    item.id === selectedLifeCategory.id
                      ? 'selected-category'
                      : ''
                  "
                  class="outline-button category-button"
                  v-on:click="onSelectLifeCategory(item)"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <button
              class="outline-button category-button"
              :class="
                selectedLifeCategory.id == null
                  ? 'selected-category'
                  : ''
              "
              @click="onSelectLifeCategory(null)"
            >
              Not Applicable
            </button>
          </div>
          <div>
            <button
              class="outline-button"
              @click="showCreateCategoryModal = true"
            >
              Create a new life category
            </button>
          </div>
        </perfect-scrollbar>
      </slider-item>
      <slider-item>
        <perfect-scrollbar
          class="flex-fill height-100 input-container flex-container column-container"
        >
          <div class="flex-container row-container">
            <div class="flex-fill category-space">
              <div
                v-for="(item, index) in relationShipCategories"
                :key="item.id"
              >
                <button
                  v-if="index % 2 === 0"
                  :class="
                    item.id === selectedRelationShipCategory.id
                      ? 'selected-category'
                      : ''
                  "
                  class="outline-button category-button"
                  v-on:click="onSelectRelationShipCategory(item)"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
            <div class="flex-fill">
              <div
                v-for="(item, index) in relationShipCategories"
                :key="item.id"
              >
                <button
                  v-if="index % 2 === 1"
                  :class="
                    item.id === selectedRelationShipCategory.id
                      ? 'selected-category'
                      : ''
                  "
                  class="outline-button category-button"
                  v-on:click="onSelectRelationShipCategory(item)"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <button
              class="outline-button"
              @click="showCreateCategoryModal = true"
            >
              Create a new relationship category
            </button>
          </div>
        </perfect-scrollbar>
      </slider-item>
      <slider-item>
        <div class="width-100 height-100 flex-container column-container">
          <h4 class="width-100 text-center created-message text-white">
            What happens now?
          </h4>
          <div
            class="created-content category-button flex-container row-container"
          >
            <div class="created-badge">1</div>
            <p class="text-primary flex-fill">
              The Storyeditor(s) managing this Storyline will be notified of
              your submission and given the opportunity to review and approve
              your memory before it appears for others to see.
            </p>
          </div>
        </div>
      </slider-item>
    </slider>
    <div class="bottom-button flex-container row-container align-center">
      <button
        v-if="sliderIndex === 0"
        class="asset-button primary-button"
        @click="chooseFiles"
      >
        <IconImage />
      </button>
      <button v-if="sliderIndex > 0 && sliderIndex < 3" class="primary-button mr-1" v-on:click="onBack">
        Back
      </button>
      <div v-if="sliderIndex > 0 && sliderIndex < 3" id="memory-button-spacer"></div>
      <button class="primary-button" v-on:click="onContinue">
        {{ buttonTitle }}
      </button>
    </div>
    <v-easy-dialog v-model="showCreateCategoryModal" focus-on="#my-input">
      <div class="width-100 flex-container column-container">
        <h4 class="width-100 category-button text-center">
          Input the Category Name
        </h4>
        <input
          id="my-input"
          v-model="categoryName"
          class="width-100 form-input category-button"
          placeholder="Category Name"
          type="text"
        />

        <div class="flex justify-end space-x-2">
          <button class="primary-button" @click="onCreateCategory">
            Create
          </button>
        </div>
        <div class="flex justify-end space-x-2">
          <button class="text-button" @click="showCreateCategoryModal = false">
            Close
          </button>
        </div>
      </div>
    </v-easy-dialog>
  </div>
</template>

<style scoped>
#memory-button-spacer {
  width: 20px;
}
</style>

<script>
import TopNav from '@/components/Shared/TopNav'
import { request } from '@/utils/interceptor'
import {
  LIFE_CATEGORY_URL,
  MEMORIES_URL,
  RELATIONSHIP_CATEGORY_URL
} from '@/utils/endpoints'
import IconImage from '@/assets/IconImage'
import IconClose from '@/assets/IconClose'
import VEasyDialog from 'v-easy-dialog'

export default {
  metaInfo: { title: 'New memory | Storyline' },
  name: 'NewMemory',
  data() {
    return {
      storyline: {},
      sliderIndex: 0,
      showErrorMessage: false,
      memory: {},
      images: [],
      medias: [],
      lifeCategories: [],
      relationShipCategories: [],
      selectedLifeCategory: {},
      selectedRelationShipCategory: {},
      body: '',
      showCreateCategoryModal: false,
      categoryName: ''
    }
  },
  mounted() {
    const id = this.$store.state.storyline.id
    const draftId = this.$store.state.memory.draftId
    if (id) {
      // If there is no draft, create one
      if (draftId == null) {
        this.newDraft();
      // Otherwise load it
      } else {
        request
        .get(`${MEMORIES_URL.replace('{id}', id)}/${draftId}`)
        .then(e => {
          // If the draft is not for this storyline, create a new draft
          if (e.data.storyline != id) {
            this.newDraft();
          // Load the draft
          } else {
            this.memory = e.data;

            e.data.media.data.forEach((media) => {
              this.images.push(media.paths.large.path);
              this.medias.push(media.id);
            });
            this.body = this.memory.body ?? "";
            this.selectedLifeCategory = this.memory.life_category ?? {};
            this.selectedRelationShipCategory = this.memory.creator_relationship ?? {};
          }
        })
      }

      request
        .get(LIFE_CATEGORY_URL.replace('{id}', id))
        .then(e => (this.lifeCategories = e.data.data))
      request
        .get(RELATIONSHIP_CATEGORY_URL.replace('{id}', id))
        .then(e => (this.relationShipCategories = e.data.data))
    }
  },
  components: { IconClose, IconImage, TopNav, VEasyDialog },
  computed: {
    title() {
      return `Memory of ${this.$store.state.storyline.firstName}`
    },
    message() {
      if (this.sliderIndex === 1) {
        return 'When was it?'
      } else if (this.sliderIndex === 2) {
        return 'How are you related?'
      } else if (this.sliderIndex === 3) {
        return 'Your memory is pending.'
      }
      return ''
    },
    description() {
      if (this.sliderIndex === 1) {
        return (
          'Don’t worry, it doesn’t have to be exact!\n' +
          'If you know the date, great. If not, choose a period!'
        )
      } else if (this.sliderIndex === 2) {
        return (
          'This will help everyone see how you’re \n' + 'connected.'
        )
      }
      return ''
    },
    buttonTitle() {
      if (this.sliderIndex === 2) return 'Post'
      else if (this.sliderIndex === 3) return 'View Memory'
      else return 'Continue'
    }
  },
  methods: {
    newDraft() {
      const id = this.$store.state.storyline.id;
      request
        .post(MEMORIES_URL.replace('{id}', id))
        .then(e => {
          // Record the draft memory id
          this.$store.commit('memory/setDraftId', e.data.id);
          this.memory = e.data;
        });
    },
    chooseFiles() {
      document.getElementById('fileUpload').click()
    },
    deleteMedia(imageIndex) {
      const mediaId = this.medias[imageIndex];
      const id = this.$store.state.storyline.id
      const memoryId = this.memory.id

      request.delete(`${MEMORIES_URL.replace('{id}', id)}/${memoryId}/media/${mediaId}`)
        .then(() => {
          this.images.splice(imageIndex, 1);
          this.medias.splice(imageIndex, 1);
       });
    },
    onFileUpload(e) {
      const id = this.$store.state.storyline.id
      const url = URL.createObjectURL(e.target.files[0])
      const memoryId = this.memory.id
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      request.post(
        `${MEMORIES_URL.replace('{id}', id)}/${memoryId}/media`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      .then(response => {
        this.images.push(url);
        this.medias.push(response.data.id);
      });
    },
    updateMemoryDraft() {
      const id = this.$store.state.storyline.id
      const memoryId = this.memory.id
      request.patch(`${MEMORIES_URL.replace('{id}', id)}/${memoryId}`, {
        body: this.body,
        life_category: this.selectedLifeCategory?.id ?? "none",
        creator_relationship: this.selectedRelationShipCategory?.id,
        status: 'pending'
      })
    },
    onBack() {
      if (this.sliderIndex > 0) {
        this.sliderIndex--;
      }
    },
    onContinue() {
      // body and image
      if (this.sliderIndex === 0) {
        // body must be set
        if (this.body.length > 0) {
          this.updateMemoryDraft();
          this.sliderIndex++;
        // Show error message
        } else {
          this.$notify({
            group: 'storyline',
            title: 'Memory content is required.',
            type: 'error'
          })
        }
      // Life category (optional)
      } else if (this.sliderIndex === 1) {
        this.updateMemoryDraft();
        this.sliderIndex++;
      // Relationship category
      } else if ( this.sliderIndex === 2 && this.selectedRelationShipCategory?.id) {
        this.updateMemoryDraft();
        this.$store.commit('memory/setDraftId', null);
        this.sliderIndex++;
      // Submit
      } else if (this.sliderIndex === 3) {

        this.$router.replace({
          name: 'memory_details',
          params: {
            memoryId: this.memory.id,
            storylineId: this.$store.state.storyline.id
          },
          replace: true
        })
      }
    },
    onSelectLifeCategory(e) {
      // Allow to be set to default by passing null
      this.selectedLifeCategory = e ?? {}
    },
    onSelectRelationShipCategory(e) {
      this.selectedRelationShipCategory = e
    },
    onCreateCategory() {
      const id = this.$store.state.storyline.id
      const url =
        this.sliderIndex === 1 ? LIFE_CATEGORY_URL : RELATIONSHIP_CATEGORY_URL
      if (this.categoryName) {
        request
          .post(url.replace('{id}', id), { name: this.categoryName })
          .then(e => {
            if (this.sliderIndex === 1) {
              this.lifeCategories.push(e.data)
            } else {
              this.relationShipCategories.push(e.data)
            }
          })
        this.showCreateCategoryModal = false
      }
    }
  }
}
</script>

<style scoped>
.bottom-button {
  margin: 28px;
  width: auto;
}

.input-container {
  padding: 28px 0 0 0;
}

.images-container {
  height: 300px;
}

.image-container {
  width: 245px;
  height: 245px;
  border-radius: 2px;
  margin: auto 6px;
}

.image-item {
  width: 245px;
  height: 245px;
  border-radius: 2px;
  object-fit: cover;
}

.asset-button {
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.image-overlay {
  top: 35px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 238px;
  height: 240px;
}

.category-button {
  margin-bottom: 20px;
}

.selected-category {
  background: white;
  color: var(--color-3);
}

.category-space {
  margin-right: 20px;
}

.cancel-button {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #61666e80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.created-message {
  margin-top: 50px;
}

.created-content {
  margin: 40px 28px;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
}

.created-badge {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  /*background: var(--color-2);*/
  font-size: 13px;
  color: var(--color-1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}

.memory-text-edit {
  border-width: 0;
  background-color: transparent;
  color: var(--color-3);
  height: calc(100% - 16px);
}

textarea:focus-visible {
  outline-width: 0;
}
</style>
