<template>
  <div>
    <top-nav
      title="Add a Storyeditor"
      show-back
      :message="message"
      :extended="message.length > 0"
    />
    <slider
      v-model="sliderIndex"
      :autoplay="false"
      :controlBtn="false"
      :indicators="false"
      animation="fade"
      class="flex-fill slider-container"
      height="auto"
      :touch="false"
    >
      <slider-item>
        <perfect-scrollbar class="flex-fill">
          <div class="options-container">
            <h2 class="text-primary text-center">Storyeditor Permissions</h2>
            <h4 class="options text-gray-2 options-first">
              Review and approve memories and condolences for the Storyline.
            </h4>
            <h4 class="options text-gray-2">
              Edit Storyline details, obituary and service events.
            </h4>
            <h4 class="options text-gray-2">
              Add and remove other Storyeditors.
            </h4>
            <h4 class="options text-gray-2">
              Create and manage resting places.
            </h4>
          </div>
        </perfect-scrollbar>
      </slider-item>
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="firstName"
            placeholder="First Name"
            autofocus
          />
        </div>
      </slider-item>
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="lastName"
            placeholder="Last Name"
            autofocus
          />
        </div>
      </slider-item>
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="email"
            placeholder="Email Address"
            autofocus
          />
        </div>
      </slider-item>
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="phoneNumber"
            placeholder="Phone Number"
            autofocus
          />
        </div>
      </slider-item>
    </slider>

    <button class="primary-button button-container" @click="onNext">
      {{ btnText }}
    </button>
  </div>
</template>

<script>
import TopNav from '../Shared/TopNav'
import { request } from '../../utils/interceptor'
export default {
  name: 'EditorNew',
  data() {
    return {
      sliderIndex: 0,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      btnText: 'Next',
      message: ''
    }
  },
  components: { TopNav },
  methods: {
    onNext() {
      switch (this.sliderIndex) {
        case 0:
          this.message = 'What is their first name?'
          this.sliderIndex++
          break
        case 1:
          if (this.firstName.length > 0) {
            this.sliderIndex++
            this.message = 'What is their last name?'
          }
          break
        case 2:
          if (this.lastName.length > 0) {
            this.sliderIndex++
            this.message = 'What is their email address?'
          }
          break
        case 3:
          if (this.email.length > 0) {
            this.sliderIndex++
            this.message = 'What is their mobile number?'
            this.btnText = 'Save'
          }
          break
        case 4:
          if (this.phoneNumber.length > 0)
            request
              .post(
                `/storylines/${this.$route.params.storylineId}/storyeditors`,
                {
                  first_name: this.firstName,
                  last_name: this.lastName,
                  email: this.email
                }
              )
              .then(() => {
                this.$notify({
                  group: 'storyline',
                  title: 'Successfully Invited'
                })
                this.$router.back()
              })
              .catch(() =>
                this.$notify({
                  group: 'storyline',
                  title: "You can't invite him",
                  type: 'error'
                })
              )
      }
    }
  }
}
</script>

<style scoped>
.options-container {
  padding: 32px 24px;
}

.button-container {
  width: auto;
  margin: 24px 32px;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  padding: 16px 0;
  min-height: 64px;
  border-bottom: solid 1px rgba(175, 175, 175, 0.55);
}

.options-first {
  margin-top: 48px;
}
</style>
