<template>
  <div class="flex-container column-container flex-fill">
    <div class="qr-background">
      <p class="nav-title">Scan a Headstone QR</p>
      <PhoneQR class="qrcode-image" />
      <h2 class="text-white background-widget">Scan a QR Code.</h2>
      <input
        v-model="keyword"
        class="form-input text-center text-color-3"
        placeholder="Scan Another QR Code"
        v-on:input="onKeyWordChange"
      />
    </div>
    <loader v-if="isLoading" class="flex-fill" />
    <div class="scroll-wrap">
      <perfect-scrollbar v-if="!isLoading" class="content-wrap flex-fill">
        <profile-list-item v-for="item in items" :key="item.id" :item="item" />
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import PhoneQR from '@/assets/PhoneQR'
import ProfileListItem from '@/components/Shared/ProfileListItem'
import { request } from '@/utils/interceptor'
import { SEARCH_URL } from '@/utils/endpoints'
import Loader from '@/components/Shared/Loader'

export default {
  name: 'QrCodeSearch',
  components: {
    PhoneQR,
    ProfileListItem,
    Loader
  },
  data() {
    return {
      items: [],
      keyword: '',
      isLoading: false
    }
  },
  methods: {
    onKeyWordChange() {
      if (!this.keyword) return
      this.isLoading = true
      request
        .get(SEARCH_URL + this.keyword)
        .then(e => {
          this.items = e.data?.data
          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped>
.qr-background {
  width: 100%;
  aspect-ratio: 375 / 337;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  padding: 47px 24px 24px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.qrcode-image {
  margin: auto;
}

.background-widget {
  margin-bottom: 24px;
}

.content-wrap {
  margin: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scroll-wrap {
  position: relative;
  flex: 1;
}
</style>
