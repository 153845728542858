<template>
  <div class="master-wrap">
    <div class="mobile-container">
      <router-view />
    </div>
    <notifications group="storyline" position="bottom center" />
  </div>
</template>

<script>
import { setHttpAppToken } from '@/utils/interceptor'

export default {
  beforeMount() {
    const token = this.$store.state.user.authToken
    if (token?.length > 0) {
      setHttpAppToken(this.$store.state.user.authToken)
    }
  }
}
</script>

<style lang="scss">
.master-wrap {
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
