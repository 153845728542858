<template>
  <div class="primary-background">
    <editor-nav title="Manage Storyline" :storyline-id="storylineId" />
    <perfect-scrollbar>
      <router-link
        class="item-container flex-container row-container justify-between"
        :to="{ name: 'editor_pending', params: { storylineId } }"
      >
        <p class="item-text text-white">Review pending items</p>
        <p class="item-text pending-badge text-primary">{{ pending }}</p>
      </router-link>
      <div class="item-container">
        <router-link
          class="item-text text-white"
          :to="{ name: 'editor_profile', params: { storylineId: storylineId } }"
        >
          Edit Storyline, Obituary, Services, and Links
        </router-link>
      </div>
      <div class="item-container">
        <router-link
          class="item-text text-white"
          :to="{ name: 'story_editors', params: { storylineId: storylineId } }"
          >Manage Storyeditors</router-link
        >
      </div>
      <div class="item-container">
        <router-link
          class="item-text text-white"
          :to="`/storyline/${storylineId}`"
          >View Storyline</router-link
        >
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import EditorNav from '../Shared/EditorNav'
import { request } from '../../utils/interceptor'
export default {
  name: 'EditorHome',
  data() {
    return {
      storyline: {},
      pending: 0
    }
  },
  mounted() {
    request
      .get(`/storylines/${this.storylineId}/pending?count=true`)
      .then(e => (this.pending = e.data.count))
  },
  components: { EditorNav },
  computed: {
    storylineId() {
      return this.$route.params.storylineId
    }
  }
}
</script>

<style scoped>
.item-container {
  padding: 21px 36px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}

.item-text {
  font-size: 16px;
}

.pending-badge {
  background-color: #f5ff00;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
