const state = {
  draftId: null
}

const actions = {}

const mutations = {
  setDraftId: (state, value) => {
    state.draftId = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
