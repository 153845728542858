<template>
  <div
    :class="extended || showTabs ? 'top-nav-extended' : ''"
    class="top-nav-container flex-container column-container"
  >
    <div class="nav-controllers">
      <a v-on:click="onBack">
        <IconBack />
      </a>
      <p class="nav-title">{{ title }}</p>
    </div>
    <div class="storyline-profile" v-if="storyline.first_name">
      <img
        v-if="storyline.profile_photo"
        :src="storyline.profile_photo"
        alt="profilePhoto"
        :class="extended ? 'avatar-extended' : 'avatar'"
      />
      <div v-else :class="extended ? 'avatar-extended' : 'avatar'">
        <span class="text-white">
          {{ storyline.first_name.charAt(0) }}
          {{ storyline.last_name.charAt(0) }}
        </span>
      </div>
      <div class="profile-data">
        <div class="row bottom-border">
          <h4 class="name text-white">{{ storyline.full_name }}</h4>
        </div>
        <div class="row life-data">
          <span class="living-years">{{ period }}</span>
          <span v-if="extended" class="birth-death-location">
            Birth: {{ storyline.birth_location.full_address }}
          </span>
          <span v-if="extended" class="birth-death-location">
            Death: {{ storyline.death_location.full_address }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="showTabs" :class="$route.name" class="storyline-nav">
      <span id="tab-profile" class="link profile" @click="slideBar('profile')">Profile</span>
      <span id="tab-obituary" class="link obituary" @click="slideBar('obituary')">Obituary</span>
      <span id="tab-links" class="link links" @click="slideBar('links')">Links</span>
      <div class="nav-indicator" :class="{
        'position-1': location == 'profile',
        'position-2': location == 'obituary',
        'position-3': location == 'links',
      }"></div>
    </div>
  </div>
</template>

<script>
import IconBack from '../../assets/IconBack'
import { request } from '../../utils/interceptor'

export default {
  name: 'EditorNav',
  components: { IconBack },
  props: {
    extended: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    showTabs: {
      type: Boolean,
      default: false
    },
    storylineId: {
      type: String
    }
  },
  data() {
    return {
      storyline: {},
      location: ''
    }
  },
  mounted() {
    let initalLocation = this.$route.path.split('/').slice(-1)[0]
    this.slideBar(initalLocation)

    request
      .get(`/storylines/${this.storylineId}`)
      .then(e => (this.storyline = e.data))
  },
  computed: {
    period() {
      return `${this.storyline?.birth_date?.substring(
        0,
        4
      )} - ${this.storyline?.death_date?.substring(0, 4)}`
    }
  },
  methods: {
    onBack() {
      this.$router.back()
    },
    slideBar(location) {
      this.location = location;

      let path
      const tab = document.getElementById(`tab-${location}`)
      if (location === 'profile') {
        path = `editor_profile`
      } else if (location === 'obituary') {
        path = `editor_obituary`
      } else if (location === 'links') {
        path = `editor_links`
      }
      let linkArray = document.querySelectorAll('.storyline-nav .link')
      linkArray.forEach(link => {
        link.classList.remove('is-active')
      })
      tab.classList.add('is-active')
      if (path !== this.$route.name)
        this.$router.replace({
          name: path,
          params: { storylineId: this.storylineId }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-nav-container {
  height: 186px;
  width: 100%;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  padding: 12px 18px 0 18px;
}

.top-nav-extended {
  height: 210px;
}

.nav-controllers {
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
}

.nav-title {
  width: 100%;
  font-size: 18px;
  color: white;
  text-align: center;
}

.living-years {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-family: 'sans-bold', serif;
}

.birth-death-location {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-top: 4px;
}

.qrcode {
  display: none !important;
}

.profile-data {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .row {
    &.bottom-border {
      align-items: center;
      border-bottom: solid 1px rgba(255, 255, 255, 0.3);
      display: flex;
      padding: 0 20px 10px 0;
      justify-content: space-between;
      //@media screen and (max-width: 650px) {
      //  padding: 0 0 10px 0;
      //}
    }

    &.life-data {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0 14px;
    }
  }

  .name {
    font-family: 'sans-bold', serif;
    font-size: 18px;
    font-weight: normal;
  }
}

.storyline-profile {
  display: flex;
  padding: 36px 0 0 8px;
}

.avatar {
  border-radius: 50%;
  display: flex;
  height: 64px;
  margin-right: 16px;
  width: 64px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
}

.avatar-extended {
  border-radius: 50%;
  display: flex;
  height: 84px;
  margin-right: 16px;
  width: 84px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
}

.storyline-nav {
  &.Profile {
    .link.profile {
      color: var(--white);
      font-family: 'sans-bold', serif;
    }

    .nav-indicator {
      transform: translateX(0);
    }
  }

  &.Obituary {
    .link.obituary {
      color: var(--white);
      font-family: 'sans-bold', serif;
    }

    .nav-indicator {
      transform: translateX(100%);
    }
  }

  .link {
    align-items: center;
    color: rgba(255, 255, 255, 0.45);
    cursor: pointer;
    display: flex;
    font-family: 'sans-light', serif;
    font-size: 16px;
    justify-content: center;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    width: calc(100% / 3);

    &.is-active {
      color: var(--white);
      font-family: 'sans-bold', serif;

      .icon {
        display: block;
      }
    }

    .icon {
      display: none;
      margin: 5px 0 0 10px;
      pointer-events: none;
    }
  }

  .nav-indicator {
    background-color: var(--color-1);
    bottom: 0;
    height: 4px;
    left: 0;
    position: absolute;
    transition: transform 500ms;
    width: calc(100% / 3);

    &.position-1 {
      transform: translateX(0);
    }

    &.position-2 {
      transform: translateX(100%);
    }

    &.position-3 {
      transform: translateX(200%);
    }
  }
}
</style>
