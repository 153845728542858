<template>
  <div
    class="width-100 height-100 flex-container align-center justify-center primary-background"
  >
    <qrcode-vue
      :size="300"
      :value="value"
      class-name="qrcode-container"
      level="H"
    />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'StorylineQRCode',
  components: {
    QrcodeVue
  },
  computed: {
    value() {
      return this.$store.state.storyline.url
    }
  }
}
</script>

<style scoped>
.qrcode-container {
  padding: 8px;
  background-color: white;
}
</style>
