<template>
  <div class="obituary-card">
    <h4 class="title">Obituary</h4>
    <div class="obituary">{{ obituary }}</div>
  </div>
</template>

<script>
export default {
  props: {
    obituary: String
  }
}
</script>

<style lang="scss" scoped>
.obituary-card {
  background: var(--white);
  border-bottom: solid 1px rgba(175, 175, 175, 0.55);
  padding: 15px 15px 50px;
  border-radius: 4px;

  .title {
    color: var(--color-2);
    font-size: 1.8rem;
    font-weight: bold;
  }

  .obituary {
    color: var(--gray-2);
    font-size: 1.8rem;
    margin-top: 30px;
    white-space: break-spaces;
  }
}
</style>
