<template>
  <div class="container">
    <perfect-scrollbar class="mobile-container">
      <div class="column-container">
        <!--        <img-->
        <!--          alt="storyline1"-->
        <!--          class="story-image"-->
        <!--          src="../../assets/welcome.png"-->
        <!--        />-->
        <div class="story-image">
          <img
            alt="storyline1"
            class="storyline-logo"
            src="../../assets/storyline-logo-white.svg"
          />
        </div>
        <h3 class="text-center widget-container text-primary">
          Enter your phone or email
        </h3>
        <div class="input-wrap">
          <input
            v-model="credential"
            class="form-input"
            placeholder="Mobile number or email address"
            type="email"
          />
        </div>
        <div class="widget-container">
          <button class="primary-button" v-on:click="checkCredential">
            Next
          </button>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { request } from '@/utils/interceptor'
import { CHECK_CREDENTIAL_URL, ENV } from '@/utils/endpoints'
import router from '@/router'
import { email, required } from 'vuelidate/lib/validators'

export default {
  metaInfo: { title: 'Storyline' },
  name: 'Auth',
  data() {
    return {
      credential: '',
      redirect: null
    }
  },
  validations: {
    credential: {
      required,
      email
    }
  },
  mounted() {
    this.redirect = this.$route.params.redirect;
  },
  methods: {
    async checkCredential() {
      if (this.credential.length < 6) {
        return
      }
      try {
        const {
          data: { method, index }
        } = await request.post(
          CHECK_CREDENTIAL_URL,
          JSON.stringify({
            value: this.credential.trim(),
            env: ENV
          })
        )
        await router.push({
          name: 'signin',
          params: { method, index, credential: this.credential, redirect: this.redirect }
        })
      } catch (e) {
        // Determine if credential is email or phone number
        let method = this.credential && this.credential.includes('@') ? 'email' : 'phone';
        await router.push({
          name: 'signup',
          params: { method: method, credential: this.credential }
        })
      }
    }
  }
}
</script>

<style scoped>
.story-image {
  width: 100%;
  height: 375px;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  justify-content: center;
}

.storyline-logo {
  width: 50%;
}

@media screen and (max-width: 675px) {
  .story-image {
    height: 55vw;
  }
}

.input-wrap {
  margin: 0 30px;
}

.widget-container {
  margin: 24px 30px;
}
</style>
