<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 25.669 37.337"
  >
    <path
      id="Path_57"
      data-name="Path 57"
      d="M347.383,327.533v-7a9.334,9.334,0,0,0-18.668,0v7a3.505,3.505,0,0,0-3.5,3.5v14a3.5,3.5,0,0,0,3.5,3.5h18.668a3.5,3.5,0,0,0,3.5-3.5v-14A3.5,3.5,0,0,0,347.383,327.533Zm-16.335-7a7,7,0,1,1,14,0v7h-14Zm17.5,24.5a1.168,1.168,0,0,1-1.167,1.167H328.714a1.168,1.168,0,0,1-1.167-1.167v-14a1.168,1.168,0,0,1,1.167-1.167h18.668a1.168,1.168,0,0,1,1.167,1.167Z"
      transform="translate(-325.214 -311.198)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPrivate',
  props: {
    color: {
      type: String,
      default: '#8d90a3'
    }
  }
}
</script>

<style scoped></style>
