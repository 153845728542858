<template>
  <svg
    id="Image_Icon"
    data-name="Image Icon"
    height="11.532"
    viewBox="0 0 14.459 11.532"
    width="14.459"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Shape"
      d="M14.23,11.532h-14A.23.23,0,0,1,0,11.3V.232A.23.23,0,0,1,.229,0h14a.23.23,0,0,1,.229.232V11.3A.23.23,0,0,1,14.23,11.532ZM9.867,6.357a.092.092,0,0,1,.067.029l3.15,3.3V1.39H1.375V9.334l3.3-4.483a.094.094,0,0,1,.073-.038.093.093,0,0,1,.075.035L7.619,8.326,9.8,6.381A.093.093,0,0,1,9.867,6.357Z"
      :fill="color"
      transform="translate(0)"
    />
    <path
      id="Shape-2"
      d="M.809,1.636A.813.813,0,0,0,1.617.818.814.814,0,0,0,.809,0,.813.813,0,0,0,0,.818.813.813,0,0,0,.809,1.636Z"
      data-name="Shape"
      :fill="color"
      transform="translate(8.939 2.633)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconImage',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style scoped></style>
