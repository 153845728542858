<template>
  <a class="link" :href="link.link_url" target="_blank">
    {{ link.title }}
  </a>
</template>

<script>
export default {
  props: {
    link: Object
  }
}
</script>

<style lang="scss" scoped>
.link {
  font-family: 'sans-regular', serif;
  border: 1px solid #1A5BA0;
  border-radius: 25px;
  color: #1A5BA0;
  height: 50px;
  margin: 22px 30px 0px 30px;
  text-align: center;
  display:flex;/*CSS3*/
  align-items:center;/*Vertical align*/
  justify-content:center;/*horizontal align*/
  
  -webkit-transition: all 250ms;
  -moz-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms;

  &:hover {
    color: white;
    background-color: #1A5BA0;
  };
}


</style>
