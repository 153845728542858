import axios from 'axios'

export const request = axios.create({
  headers: {}
})

export function setupHttpConfig() {
  request.defaults.baseURL = process.env.VUE_APP_BACKEND_API
  request.defaults.timeout = 30000
  request.defaults.headers['Content-Type'] = 'application/json'
  // you can add more default values for http requests here
}

export function setHttpAppToken(token) {
  if (!token) request.defaults.headers.Authorization = ''
  request.defaults.headers.Authorization = `Token ${token}`
}

export function interceptor() {
  axios.interceptors.request.use(function(request) {
    const baseUrl = process.env.VUE_APP_BACKEND_API
    // request.headers[
    //   'Authorization'
    // ] = `Bearer sy_live_272452cd5e03e16a107baf73fdf49d16617dde429aa478f85153eb306451ed2ad15cef38121213d8e7a398a57a0ff71b40c3993aedad9d7ea0f904904e351571`
    request.url = `${baseUrl}${request.url}`
    return request
  })
}
