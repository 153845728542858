<template>
    <div
      class="column-container height-100"
    >
      <top-nav
        :extended="true"
        :message="message"
        :title="title"
        show-back
      />
      <slider
        v-model="sliderIndex"
        :autoplay="false"
        :controlBtn="false"
        :indicators="false"
        animation="fade"
        class="flex-fill slider-container"
        height="auto"
        :touch="false"
      >
        <!-- Name -->
        <slider-item>
          <div class="height-100 width-100">
            <perfect-scrollbar class="input-container height-100">
                <input
                    id="first_name"
                    v-model="storyline.first_name"
                    class="width-100 form-input category-button"
                    placeholder="First Name"
                    type="text"
                />
                <input
                    id="middle_name"
                    v-model="storyline.middle_name"
                    class="width-100 form-input category-button"
                    placeholder="Middle Name"
                    type="text"
                />
                <input
                    id="last_name"
                    v-model="storyline.last_name"
                    class="width-100 form-input category-button"
                    placeholder="Last Name"
                    type="text"
                />
            </perfect-scrollbar>
          </div>
        </slider-item>
        <!-- Birthday -->
        <slider-item>
            <div class="height-100 width-100">
                <perfect-scrollbar class="input-container height-100">
                <input
                    id="birth_date_month"
                    v-model="storyline.birth_date_month"
                    class="width-100 form-input category-button"
                    placeholder="Birth Month"
                    type="number"
                />
                <input
                    id="birth_date_day"
                    v-model="storyline.birth_date_day"
                    class="width-100 form-input category-button"
                    placeholder="Birth Day"
                    type="number"
                />
                <input
                    id="birth_date_year"
                    v-model="storyline.birth_date_year"
                    class="width-100 form-input category-button"
                    placeholder="Birth Year"
                    type="number"
                />
                </perfect-scrollbar>
            </div>
        </slider-item>
        <!-- Birth Location -->
        <slider-item>
            <div class="height-100 width-100">
                <perfect-scrollbar class="input-container height-100">
                <input
                    id="birth_city"
                    v-model="storyline.birth_city"
                    class="width-100 form-input category-button"
                    placeholder="Birth City"
                    type="text"
                />
                <input
                    id="birth_state"
                    v-model="storyline.birth_state"
                    class="width-100 form-input category-button"
                    placeholder="Birth State"
                    type="text"
                />
                <input
                    id="birth_country"
                    v-model="storyline.birth_country"
                    class="width-100 form-input category-button"
                    placeholder="Birth Country"
                    type="text"
                />
                </perfect-scrollbar>
            </div>
        </slider-item>
        <!-- Death date -->
        <slider-item>
            <div class="height-100 width-100">
                <perfect-scrollbar class="input-container height-100">
                <input
                    id="death_date_month"
                    v-model="storyline.death_date_month"
                    class="width-100 form-input category-button"
                    placeholder="Death Month"
                    type="number"
                />
                <input
                    id="death_date_day"
                    v-model="storyline.death_date_day"
                    class="width-100 form-input category-button"
                    placeholder="Death Day"
                    type="number"
                />
                <input
                    id="death_date_year"
                    v-model="storyline.death_date_year"
                    class="width-100 form-input category-button"
                    placeholder="Death Year"
                    type="number"
                />
                </perfect-scrollbar>
            </div>
        </slider-item>
        <!-- Death Location -->
        <slider-item>
            <div class="height-100 width-100">
                <perfect-scrollbar class="input-container height-100">
                    <input
                        id="death_city"
                        v-model="storyline.death_city"
                        class="width-100 form-input category-button"
                        placeholder="Death City"
                        type="text"
                    />
                    <input
                        id="death_state"
                        v-model="storyline.death_state"
                        class="width-100 form-input category-button"
                        placeholder="Death State"
                        type="text"
                    />
                    <input
                        id="death_country"
                        v-model="storyline.death_country"
                        class="width-100 form-input category-button"
                        placeholder="Death Country"
                        type="text"
                    />
                </perfect-scrollbar>
            </div>
        </slider-item>
        <!-- Gender -->
        <slider-item>
            <div class="height-100 width-100">
                <perfect-scrollbar class="input-container height-100">
                    <select
                        id="gender"
                        v-model="storyline.gender"
                        class="width-100 form-input category-button"
                    >
                        <option disabled value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                        <option value="none">Prefer not to say</option>
                    </select>
                </perfect-scrollbar>
            </div>
        </slider-item>
        <!-- Privacy -->
        <slider-item>
            <div id="privacy-setting" class="height-100 width-100">
                <perfect-scrollbar class="flex column-container">
                    <div
                        :class="isPublic ? 'active-item' : ''"
                        class="flex-container row-container privacy-item separator"
                        @click="storyline.level = 'PUBLIC'"
                    >
                        <IconPublic :color="isPublic ? '#F5FF00' : null" />
                        <div class="flex-fill flex-container column-container option-container">
                            <h4 :class="isPublic ? 'text-white' : 'text-gray-2'">
                                Public
                            </h4>
                            <p :class="isPublic ? 'text-white' : 'text-gray-2'">
                                Storyline can be viewed by anyone who performs a search on the app,
                                scans the associated QR code, or follows a shared link.
                            </p>
                        </div>
                    </div>
                    <div
                        :class="isUnlisted ? 'active-item' : ''"
                        class="flex-container row-container privacy-item separator"
                        @click="storyline.level = 'UNLISTED'"
                    >
                        <IconUnlisted :color="isUnlisted ? '#F5FF00' : null" />
                        <div class="flex-fill flex-container column-container option-container">
                            <h4 :class="isUnlisted ? 'text-white' : 'text-gray-2'">
                                Unlisted
                            </h4>
                            <p :class="isUnlisted ? 'text-white' : 'text-gray-2'">
                                Storyline can be viewed by someone who scans the associated QR code
                                or follows a shared link. It will not appear in general searches.
                            </p>
                        </div>
                    </div>
                    <div
                        :class="isPrivate ? 'active-item' : ''"
                        class="flex-container row-container privacy-item"
                        v-on:click="storyline.level = 'PRIVATE'"
                    >
                        <IconPrivate :color="isPrivate ? '#F5FF00' : null" />
                        <div class="flex-fill flex-container column-container option-container">
                            <h4 :class="isPrivate ? 'text-white' : 'text-gray-2'">
                                Private
                            </h4>
                            <p :class="isPrivate ? 'text-white' : 'text-gray-2'">
                                Only those who request access and are approved by a Storyeditor may
                                view the Storyline.
                            </p>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </slider-item>
      </slider>
      <p class="text-error" v-if="!stepValid">
        {{ stepError }}
      </p>
      <div class="bottom-button flex-container row-container align-center">
        <button v-if="sliderIndex > 0" class="primary-button mr-1" v-on:click="onBack">
          Back
        </button>
        <div v-if="sliderIndex > 0" id="memory-button-spacer"></div>
        <button class="primary-button" v-on:click="onContinue" :disabled="!stepValid || submitted">
          {{ buttonTitle }}
        </button>
      </div>
    </div>
  </template>
  
<script>
  import TopNav from '@/components/Shared/TopNav'
  import IconPublic from '../../assets/IconPublic'
  import IconUnlisted from '../../assets/IconUnlisted'
  import IconPrivate from '../../assets/IconPrivate'
  import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators'
  import { request } from '@/utils/interceptor'

  export default {
    metaInfo: { title: 'New Storyline | Storyline' },
    name: 'NewStoryline',
    data() {
      return {
        // storyline: {
        //   company_key: 'company-5fcbfe283e444',
        //   first_name: 'Test',
        //   middle_name: 'Middle',
        //   last_name: 'Storyline',
        //   gender: 'male',
        //   birth_date_day: '01',
        //   birth_date_month: '01',
        //   birth_date_year: '1900',
        //   birth_city: 'Logan',
        //   birth_state: 'Utah',
        //   birth_country: 'US',
        //   death_date_day: '02',
        //   death_date_month: '02',
        //   death_date_year: '2000',
        //   death_city: 'Las Vegas',
        //   death_state: 'Nevada',
        //   death_country: 'USA',
        //   level: 'PRIVATE'
        // },
        storyline: {
          company_key: 'company-5fcbfe283e444',
          first_name: '',
          middle_name: '',
          last_name: '',
          gender: '',
          birth_date_day: '',
          birth_date_month: '',
          birth_date_year: '',
          birth_city: '',
          birth_state: '',
          birth_country: '',
          death_date_day: '',
          death_date_month: '',
          death_date_year: '',
          death_city: '',
          death_state: '',
          death_country: '',
          level: ''
        },
        submitted: false,
        sliderIndex: 0,
        sliderMax: 6,
        showErrorMessage: false,
        messages: [
          'What is their name?',
          'What is their birthday?',
          'What is their birth location?',
          'What is their death date?',
          'What is their death location?',
          'What is their gender?',
          'Select a privacy setting?'
        ],
        stepsProps: [
          ['first_name', 'last_name', 'middle_name'],
          ['birth_date_day', 'birth_date_month', 'birth_date_year'],
          ['birth_city', 'birth_state', 'birth_country'],
          ['death_date_day', 'death_date_month', 'death_date_year'],
          ['death_city', 'death_state', 'death_country'],
          ['gender'],
          ['level']
        ],
        stepErrors: [
          'First and last name required',
          'Birthday invalid',
          'All fields required',
          'Death date invalid',
          'All fields required',
          'Gender required',
          'Please pick a privacy level'
        ]
      }
    },
    mounted() {
    },
    components: { TopNav, IconPrivate, IconUnlisted, IconPublic },
    validations: {
      storyline: {
        first_name: { required },
        middle_name: { },
        last_name: { required },
        gender: { required },
        birth_date_day: { required, numeric, minValue: minValue(1), maxValue: maxValue(31) },
        birth_date_month: { required, numeric, minValue: minValue(1), maxValue: maxValue(12) },
        birth_date_year: { required, numeric, minValue: minValue(1000), maxValue: maxValue(3000) },
        birth_city: { required },
        birth_state: { required },
        birth_country: { required },
        death_date_day: { numeric, minValue: minValue(1), maxValue: maxValue(31) },
        death_date_month: { numeric, minValue: minValue(1), maxValue: maxValue(12) },
        death_date_year: { numeric, minValue: minValue(1000), maxValue: maxValue(3000) },
        death_city: { required },
        death_state: { required },
        death_country: { required },
        level: { required }
      },
    },
    computed: {
      title() {
        return `New Storyline`
      },
      message() {
        return this.messages[this.sliderIndex];
      },
      stepProps() {
        return this.stepsProps[this.sliderIndex];
      },
      stepError() {
        return this.stepErrors[this.sliderIndex];
      },
      stepValid() {
        for (const prop of this.stepProps) {
          if (this.$v.storyline[prop].$invalid) {
            return false;
          }
        }
        return true;
      },
      buttonTitle() {
        return this.sliderIndex === this.sliderMax ? 'Finish' : 'Next';
      },
      isPublic() {
        return this.storyline.level.toString().toLowerCase() === 'public';
      },
      isPrivate() {
        return this.storyline.level.toString().toLowerCase() === 'private';
      },
      isUnlisted() {
        return this.storyline.level.toString().toLowerCase() === 'unlisted';
      }
    },
    methods: {
      onBack() {
        if (this.sliderIndex > 0) {
          this.sliderIndex--;
          this.submitted = false;
        }
      },
      onContinue() {
        if (this.sliderIndex < this.sliderMax) {
          this.sliderIndex++;
        } else {
          this.submitted = true;

          request
          .post('/storylines', this.storyline)
          .then(e => {
            let id = e.data.id;
            this.$router.replace(`/editor/${id}`).then(() => {
              this.$notify({
                group: 'storyline',
                title: 'Storyline created!',
                type: 'success'
              });
            })
          })
          .catch(() => {
            this.submitted = false;
            this.$notify({
              group: 'storyline',
              title: 'Failed to create storyline!',
              type: 'error'
            });
          })
        }
      },
    }
  }
</script>
  
<style scoped>
  #privacy-setting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .privacy-item:hover {
    cursor: pointer;
  }
  .form-input {
    margin-bottom: 10px;
  }
  .bottom-button {
    margin: 28px;
    width: auto;
  }
  
  .input-container {
    padding: 28px 0 0 0;
    border-bottom: 0 !important;
  }

  textarea:focus-visible {
    outline-width: 0;
  }

  #memory-button-spacer {
    width: 20px;
  }

  .title-text {
  padding: 32px 15vw;
  width: 100%;
}

@media screen and (min-width: 675px) {
  .title-text {
    padding: 32px 100px;
  }
}

.button-container {
  width: calc(100vw - 64px);
  margin: 24px 32px;
}

@media screen and (min-width: 675px) {
  .button-container {
    width: 611px;
  }
}

.privacy-item {
  border-radius: 4px;
  margin: 0 24px;
  padding: 24px 20px;
}

.active-item {
  background: linear-gradient(20deg, #006cb1, #285197 70%);
}

.option-container {
  margin-left: 24px;
}
  </style>
  