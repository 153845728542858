<template>
  <div v-if="id" class="storyline-header">
    <div class="logo-container flex-container justify-center">
      <img
        src="../../assets/storyline-logo-white.svg"
        class="header-logo"
        alt="logo"
      />
    </div>
    <div class="nav-controllers">
      <a v-if="isAuthed" @click="goBack">
        <IconBack v-if="showBack" />
      </a>
      <div v-else />
      <StorylineActions />
    </div>
    <div class="storyline-profile">
      <img
        v-if="profilePhoto"
        :src="profilePhoto"
        alt="profilePhoto"
        class="avatar"
      />
      <div v-else class="avatar">
        <span class="text-white">
          {{ safeWrap(firstName).charAt(0) }}
          {{ safeWrap(lastName).charAt(0) }}
        </span>
      </div>
      <router-link :to="{ name: 'qrcode' }" class="qrcode-button">
        <IconProfileQrcode @click="goQrCode" />
      </router-link>
      <div class="profile-data">
        <div class="row bottom-border">
          <h4 class="name text-white">{{ fullName }}</h4>
        </div>
        <div class="row life-data">
          <span class="living-years">{{ period }}</span>
          <span class="birth-death-location">{{ birthLocations }}</span>
          <span class="birth-death-location">{{ deathLocations }}</span>
        </div>
      </div>
    </div>
    <div :class="$route.name" class="storyline-nav">
      <span class="link storyline" @click="slideBar('storyline')" :class="{ 'is-active': location == 'storyline'}">
        Storyline
      </span>
      <span
        class="link services" :class="{ 'is-active': location == 'services'}"
        @click="slideBar('services')"
        style="display: none"
        >Services</span
      >
      <span class="link obituary" @click="slideBar('obituary')" :class="{ 'is-active': location == 'obituary'}">Obituary</span>
      <span class="link links" @click="slideBar('links')" :class="{ 'is-active': location == 'links'}">Links</span>
      <div class="nav-indicator" :class="{
        'position-1': location == 'storyline',
        'position-2': location == 'obituary',
        'position-3': location == 'links',
      }"></div>
    </div>
  </div>
</template>

<script>
import StorylineActions from '@/components/Storyline/StorylineActions.vue'
import { mapActions, mapState } from 'vuex'
import IconBack from '@/assets/IconBack'
import IconProfileQrcode from '@/assets/IconProfileQrcode'

export default {
  props: {
    showBack: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      location: 'storyline'
    }
  },
  mounted() {
    let initalLocation = this.$route.path.split('/').slice(-1)[0];
    if (!initalLocation.startsWith('sl-')) {
      this.slideBar(initalLocation);
    }
  },
  components: {
    IconProfileQrcode,
    IconBack,
    StorylineActions
  },
  computed: {
    ...mapState('storyline', [
      'showStats',
      'id',
      'firstName',
      'middleName',
      'lastName',
      'profilePhoto',
      'birthDate',
      'deathDate',
      'birthLocation',
      'deathLocation'
    ]),
    fullName() {
      return `${this.firstName} ${this.middleName ? this.middleName : ''} ${
        this.lastName
      }`
    },
    birthLocations() {
      return `Birth: ${this.birthLocation.city}, ${this.birthLocation.state}`
    },
    deathLocations() {
      return `Death: ${this.deathLocation.city}, ${this.deathLocation.state}`
    },
    period() {
      return `${this.birthDate?.substring(0, 4)} - ${this.deathDate?.substring(
        0,
        4
      )}`
    },
    isAuthed() {
      return this.$store.state?.user?.authToken?.length > 0
    }
  },
  methods: {
    ...mapActions('modal', ['updateIsVisible']),
    ...mapActions('storyline', ['updateShowStats']),
    followStoryline() {
      this.updateIsVisible(true)
    },
    slideBar(location) {
      this.location = location;

      let path
      if (location === 'storyline') {
        path = `/storyline/${this.id}`
      } else if (location === 'services') {
        path = `/storyline/${this.id}/services`
      } else if (location === 'obituary') {
        path = `/storyline/${this.id}/obituary`
      } else if (location === 'links') {
        path = `/storyline/${this.id}/links`
      }
      if (path !== this.$route.path) this.$router.replace(path)
    },
    goQrCode() {
      this.$router.push({ name: 'qrcode' })
    },
    goBack() {
      this.$router.back()
    },
    safeWrap(param) {
      return param ?? ''
    }
  }
}
</script>

<style lang="scss" scoped>
.storyline-header {
  background: linear-gradient(
    20deg,
    rgba(0, 108, 177, 1) 0%,
    rgba(40, 81, 151, 1) 70%
  );
}

.header-logo {
  height: 24px;
}

.logo-container {
  position: absolute;
  top: 16px;
  right: 50%;
  left: 50%;
  z-index: 0;
}

.storyline-profile {
  display: flex;
  padding: 8px 24px 0 24px;
  position: relative;
  //@media screen and (max-width: 650px) {
  //  flex-direction: column;
  //  padding: 20px;
  //}
}

.profile-data {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .row {
    &.bottom-border {
      align-items: center;
      border-bottom: solid 1px rgba(255, 255, 255, 0.3);
      display: flex;
      padding: 0 20px 10px 0;
      justify-content: space-between;
      //@media screen and (max-width: 650px) {
      //  padding: 0 0 10px 0;
      //}
    }

    &.life-data {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0 14px;
    }
  }

  .name {
    font-family: 'sans-bold', serif;
    font-size: 18px;
    font-weight: normal;
  }
}

.avatar {
  border-radius: 50%;
  display: flex;
  height: 84px;
  margin-right: 16px;
  width: 84px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
}

.living-years {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-family: 'sans-bold', serif;
}

.birth-death-location {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-top: 4px;
}

.qrcode {
  display: none !important;
}

.storyline-nav {
  &.Storyline {
    .link.storyline {
      color: var(--white);
      font-family: 'sans-bold', serif;
    }

    .nav-indicator {
      transform: translateX(0);
    }
  }

  &.Services {
    .link.services {
      color: var(--white);
      font-family: 'sans-bold', serif;
    }

    .nav-indicator {
      transform: translateX(100%);
    }
  }

  &.Obituary {
    .link.obituary {
      color: var(--white);
      font-family: 'sans-bold', serif;
    }

    .nav-indicator {
      transform: translateX(200%);
    }
  }

  .link {
    align-items: center;
    color: rgba(255, 255, 255, 0.45);
    cursor: pointer;
    display: flex;
    font-family: 'sans-light', serif;
    font-size: 16px;
    justify-content: center;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    width: calc(100% / 3);

    &.is-active {
      color: var(--white);
      font-family: 'sans-bold', serif;

      .icon {
        display: block;
      }
    }

    .icon {
      display: none;
      margin: 5px 0 0 10px;
      pointer-events: none;
    }
  }

  .nav-indicator {
    background-color: var(--color-1);
    bottom: 0;
    height: 4px;
    left: 0;
    position: absolute;
    transition: transform 500ms;
    width: calc(100% / 3);

    &.position-1 {
      transform: translateX(0);
    }

    &.position-2 {
      transform: translateX(100%);
    }

    &.position-3 {
      transform: translateX(200%);
    }
  }
}

.nav-controllers {
  margin-top: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 12px 18px;
  justify-content: space-between;
}

.nav-title {
  font-size: 18px;
  color: white;
}

.qrcode-button {
  position: absolute;
  top: 72px;
  left: 48px;
}
</style>
