<template>
  <div>
    <top-nav
      title="Update Storyline"
      show-back
      :message="message"
      :extended="message.length > 0"
    />
    <slider
      v-model="sliderIndex"
      :autoplay="false"
      :controlBtn="false"
      :indicators="false"
      animation="fade"
      class="flex-fill slider-container"
      height="auto"
      :touch="false"
    >
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="firstName"
            placeholder="First Name"
            autofocus
          />
        </div>
      </slider-item>
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="middleName"
            placeholder="Middle Name"
            autofocus
          />
        </div>
      </slider-item>
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="lastName"
            placeholder="Last Name"
            autofocus
          />
        </div>
      </slider-item>
    </slider>

    <button class="primary-button button-container" @click="onNext">
      {{ btnText }}
    </button>
  </div>
</template>

<script>
import TopNav from '../Shared/TopNav'
import { request } from '../../utils/interceptor'

export default {
  name: 'EditorUpdateName',
  data() {
    return {
      sliderIndex: 0,
      firstName: '',
      lastName: '',
      middleName: '',
      btnText: 'Next',
      message: 'What is the first name?',
      storylineId: '',
      storyline: {}
    }
  },
  mounted() {
    this.storylineId = this.$route.params.storylineId
    request.get(`/storylines/${this.storylineId}`).then(e => {
      this.storyline = e.data
      this.firstName = this.storyline.first_name ?? ''
      this.lastName = this.storyline.last_name ?? ''
      this.middleName = this.storyline.middle_name ?? ''
    })
  },
  components: { TopNav },
  methods: {
    onNext() {
      switch (this.sliderIndex) {
        case 0:
          if (this.firstName.length > 0) {
            this.sliderIndex++
            this.message = 'What is the middle name?'
          }
          break
        case 1:
          if (this.middleName.length > 0) {
            this.sliderIndex++
            this.message = 'What is the last name?'
            this.btnText = 'Save'
          }
          break
        case 2:
          if (this.lastName.length > 0)
            request
              .patch(`/storylines/${this.storylineId}`, {
                first_name: this.firstName,
                last_name: this.lastName,
                middle_name: this.middleName
              })
              .then(() => {
                this.$notify({
                  group: 'storyline',
                  title: 'Successfully updated'
                })
                this.$router.back()
              })
              .catch(() =>
                this.$notify({
                  group: 'storyline',
                  title: "You can't updated this storyline",
                  type: 'error'
                })
              )
      }
    }
  }
}
</script>

<style scoped>
.button-container {
  width: auto;
  margin: 24px 32px;
}
</style>
