<template>
  <div class="memory-text">
    {{ memoryText.substring(0, textLength) }}
    <span
      v-if="textLength"
      class="show-more"
      @click="goTo(`/memory/${memoryID}`)"
      >...more
    </span>
  </div>
</template>

<script>
export default {
  props: {
    memoryText: String,
    textLength: Number,
    memoryID: String
  }
}
</script>

<style lang="scss" scoped>
.memory-text {
  color: var(--gray-2);
  padding: 0 30px;
  white-space: break-spaces;
  font-size: 14px;

  @media screen and (max-width: 650px) {
    padding: 0 20px;
  }

  .show-more {
    color: var(--gray-3);
    cursor: pointer;
  }
}
</style>
