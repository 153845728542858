<template>
  <svg
    height="99"
    viewBox="0 0 99 99"
    width="99"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group_26" data-name="Group 26" transform="translate(-138 -534)">
      <path
        id="Path_13"
        d="M49.5,0A49.5,49.5,0,1,1,0,49.5,49.5,49.5,0,0,1,49.5,0Z"
        data-name="Path 13"
        fill="#dfe4eb"
        opacity="0.101"
        transform="translate(138 534)"
      />
      <g id="Group_1" data-name="Group 1" transform="translate(35 408)">
        <g id="Group_2" data-name="Group 2" transform="translate(0 104)">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            fill="none"
            height="46"
            rx="3"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(140 49)"
            width="26"
          />
          <line
            id="Line_1"
            data-name="Line 1"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(140 88)"
            x2="26"
          />
          <line
            id="Line_2"
            data-name="Line 2"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(140 55)"
            x2="26"
          />
          <line
            id="Line_3"
            data-name="Line 3"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(151 91)"
            x2="4"
          />
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            fill="none"
            height="5"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(145 63)"
            width="5"
          />
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            fill="none"
            height="5"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(156 63)"
            width="5"
          />
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            fill="none"
            height="5"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(145 74)"
            width="5"
          />
          <rect
            id="Rectangle_5"
            data-name="Rectangle 5"
            fill="none"
            height="5"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(156 74)"
            width="5"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(153 62)"
            y2="4"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(144 71)"
            x1="3"
          />
          <path
            id="Path_2"
            d="M153,68v3h-4"
            data-name="Path 2"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
          />
          <line
            id="Line_6"
            data-name="Line 6"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(155 71)"
            x2="4"
          />
          <line
            id="Line_7"
            data-name="Line 7"
            fill="none"
            stroke="#f5ff00"
            stroke-miterlimit="10"
            stroke-width="1.5"
            transform="translate(153 73)"
            y2="4"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PhoneQR'
}
</script>

<style scoped></style>
