<template>
  <div class="column-container flex-container">
    <div class="top-nav-container">
      <p class="nav-title">Search by Name</p>
      <input
        v-model="keyword"
        class="search-box"
        v-on:input="onKeyWordChange"
      />
      <div class="search-icon">
        <IconSearch color="white" />
      </div>
    </div>
    <loader v-if="isLoading" class="flex-fill" />
    <perfect-scrollbar v-if="!isLoading" class="content-wrap flex-fill">
      <profile-list-item v-for="item in items" :key="getId(item)" :item="item" />
    </perfect-scrollbar>
  </div>
</template>

<script>
import { request } from '@/utils/interceptor'
import { SEARCH_URL } from '@/utils/endpoints'
import ProfileListItem from '@/components/Shared/ProfileListItem'
import Loader from '@/components/Shared/Loader'
import IconSearch from '@/assets/IconSearch'

export default {
  metaInfo: { title: 'Search | Storyline' },
  name: 'Search',
  components: { Loader, ProfileListItem, IconSearch },
  data() {
    return {
      items: [],
      keyword: '',
      isLoading: false
    }
  },
  methods: {
    onKeyWordChange() {
      if (!this.keyword) return
      this.isLoading = true
      request
        .get(SEARCH_URL + this.keyword)
        .then(e => {
          this.items = e.data?.data
          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
    },
    getId(item) {
      return item?.id
    }
  }
}
</script>

<style lang="scss" scoped>
.top-nav-container {
  height: 155px;
  width: 100%;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  flex-direction: column;
  padding: 22px 18px;
  position: relative;
}

.nav-title {
  font-size: 18px;
  color: white;
  width: 100%;
  text-align: center;
  margin-top: auto;
  margin-bottom: 24px;
}

.search-box {
  background-color: #ffffff60;
  border: 0 solid #ffffff60;
  height: 44px;
  border-radius: 22px;
  font-size: 18px;
  color: white;
  padding: 13px 15px;
}

.content-wrap {
  margin: 18px;
}

.search-icon {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

//@media screen and (min-width: 675px) {
//  .search-icon {
//    position: absolute;
//    right: calc(30px + (100vw - 675px) / 2);
//  }
//}
</style>
