<template>
  <div class="height-100 flex-container column-container">
    <top-nav :title="userName" />
    <loader v-if="isLoading" class="flex-fill" />
    <perfect-scrollbar v-else class="flex-fill" @ps-y-reach-end="scrollEndHandler">
      <div v-if="feeds.length > 0">
        <div class="memory-wrap">
          <Memory
            v-for="memory in feeds"
            :key="memory.id"
            :memory="memory"
            is-feed
          />
          <div class="more">
            <button v-if="hasMore && !isLoading" class="primary-button" @click="refreshStorylines">LOAD MORE</button>
            <p v-else>You're all caught up!</p>
          </div>
        </div>
      </div>
      <div v-else class="no-container">
        <IconSearch :size="50" color="#C6D2E3" />
        <h2 class="no-message">Follow your loved ones.</h2>
        <p class="no-description">
          Tap on the search icon or scan a QR code to get started. Once you find
          a Storyline you would like to follow, click “follow” for those
          memories to be shown here.
        </p>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import TopNav from '@/components/Shared/TopNav'
import { USERS_URL } from '@/utils/endpoints'
import { request } from '@/utils/interceptor'
import IconSearch from '@/assets/IconSearch'
import Memory from '@/components/Memory/Memory'
import Loader from '@/components/Shared/Loader'

export default {
  name: 'Storylines',
  data() {
    return {
      feeds: [],
      isLoading: false,
      hasMore: false
    }
  },
  components: { Loader, Memory, IconSearch, TopNav },
  mounted() {
    this.refreshStorylines()
  },
  methods: {
    refreshStorylines() {
      this.$store.state.user.user.id
      let url = `${USERS_URL}/${this.$store.state.user.user.id}/feed`
      if (this.hasMore) {
        url = `${url}?starting_after=${this.feeds.at(-1).id}`
      } else {
        this.isLoading = true
      }
      request
        .get(url)
        .then(e => {
          this.feeds.push(...e.data.data)
          this.isLoading = false
          this.hasMore = e.data.has_more
        })
        .catch(() => (this.isLoading = false))
    },
    scrollEndHandler() {
      if (this.hasMore && !this.isLoading) {
        this.refreshStorylines();
      }
    }
  },
  computed: {
    userName() {
      return `Welcome, ${this.$store.state.user.user.name}`
    }
  }
}
</script>

<style lang="scss" scoped>
.no-message {
  color: #5e7fb1;
  text-align: center;
  margin: 30px;
}

.no-description {
  color: #222222;
  opacity: 0.5;
  text-align: center;
}

.no-container {
  margin: 90px 42px 0 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.more {
  margin: 20px 0;
  text-align: center;
  color: var(--gray-2);

  button {
    width: 150px;
  }
}
</style>
