<template>
  <div>
    <top-nav
      show-back
      :title="title"
      :extended="!url"
      message="Upload a Profile Image"
      description="This will appear in searches and the Storyline."
    />
    <loader v-if="isLoading" />
    <div
      v-else-if="!url"
      class="flex-container justify-center align-center height-100 column-container"
    >
      <input id="fileUpload" hidden type="file" @change="onFileSelected" />
      <div
        class="upload-container flex-container justify-center align-center"
        @click="chooseFiles"
      >
        <IconUpload />
      </div>
      <h4 class="text-primary">Upload Image</h4>
    </div>
    <div v-else class="flex-fill align-center flex-container column-container">
      <Cropper
        :src="url"
        class="flex-fill"
        backgroundClass="crop-container"
        stencil-component="circle-stencil"
        imageClassname="width-100 height-100"
        @change="change"
      >
      </Cropper>
      <button class="primary-button btn-container" @click="onUpload">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TopNav from '../Shared/TopNav'
import IconUpload from '../../assets/IconUpload'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { request } from '../../utils/interceptor'
import Loader from '../Shared/Loader'

export default {
  name: 'EditorUploadPhoto',
  components: { Loader, IconUpload, TopNav, Cropper },
  mounted() {
    this.title = this.$route.params.title
    this.uploadUrl = this.$route.params.uploadUrl
    if (!this.uploadUrl) {
      this.$router.back()
    }
  },
  data() {
    return {
      url: '',
      imageData: null,
      title: '',
      uploadUrl: '',
      isLoading: false
    }
  },
  methods: {
    onFileSelected(e) {
      this.url = URL.createObjectURL(e.target.files[0])
    },
    chooseFiles() {
      document.getElementById('fileUpload').click()
    },
    change({ canvas }) {
      canvas.toBlob(blob => (this.imageData = blob))
    },
    onUpload() {
      this.isLoading = true
      const form = new FormData()
      form.append('profile_photo', this.imageData)
      request
        .post(this.uploadUrl, form)
        .then(e => {
          if (e.data.object === 'sl-user') {
            this.$store.commit('user/setUser', e.data)
          }
          this.$router.back()
        })
        .catch(e =>
          this.$notify({
            group: 'storyline',
            type: 'error',
            title: e.message
          })
        )
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped>
.upload-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  margin-bottom: 24px;
}

.crop-container {
  background: transparent;
}

.btn-container {
  margin: 24px;
  width: calc(100% - 48px);
}
</style>
