<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 25.609 25.61"
  >
    <g id="Group_34" data-name="Group 34" transform="translate(1 1)">
      <path
        id="Path_14"
        data-name="Path 14"
        d="M3635.85,1486.257l-7.786,2.225,2.225-7.787,15.016-15.017a2.752,2.752,0,0,1,3.893,0l1.668,1.669a2.752,2.752,0,0,1,0,3.893Z"
        transform="translate(-3628.064 -1464.872)"
        :fill="fill"
        :stroke="stroke"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_18"
        data-name="Line 18"
        x1="5.562"
        y1="5.562"
        transform="translate(15.016 3.031)"
        :fill="fill"
        :stroke="stroke"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconEdit',
  props: {
    size: {
      type: Number,
      default: 25
    },
    fill: {
      type: String,
      default: 'none'
    },
    stroke: {
      type: String,
      default: '#FFF'
    }
  }
}
</script>

<style scoped></style>
