<template>
  <svg
    height="15.445"
    viewBox="0 0 15.444 15.445"
    width="15.444"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="create-new-playlist-icon"
      d="M1221.712,4803.368h-4.1v-4.108a.786.786,0,0,0-1.523,0v4.108h-4.1a.786.786,0,0,0,0,1.524h4.1V4809a.786.786,0,0,0,1.523,0v-4.108h4.1a.786.786,0,0,0,0-1.524Z"
      fill="#fff"
      transform="translate(-4249.75 -2528.87) rotate(-45)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>

<style scoped></style>
