<template>
  <div>
    <top-nav title="Scan a Headstone QR" />
  </div>
</template>

<script>
import TopNav from '@/components/Shared/TopNav'

export default {
  metaInfo: { title: 'Scan Result | Storyline' },
  name: 'ScanResult',
  components: { TopNav }
}
</script>

<style scoped></style>
