<template>
  <perfect-scrollbar>
    <div class="editor-container" v-if="storyline.id">
      <div>
        <img
          v-if="storyline.profile_photo"
          :src="storyline.profile_photo"
          class="editor-avatar"
          alt="profile-img"
        />
        <div v-else class="editor-avatar">
          <span class="text-white">
            {{ storyline.first_name.charAt(0) }}
            {{ storyline.last_name.charAt(0) }}
          </span>
        </div>
        <router-link
          class="edit-container"
          :to="{
            name: 'editor_upload_image',
            params: {
              title: 'Update Storyline',
              uploadUrl: `/storylines/${storylineId}`
            }
          }"
        >
          <IconEdit :size="12" />
        </router-link>
      </div>
      <div
        class="item-container separator flex-container row-container justify-between"
      >
        <p class="text-gray-2">Full name</p>
        <router-link
          class="text-primary bold"
          :to="{
            name: 'editor_update_name',
            params: { storylineId: storylineId }
          }"
        >
          {{ storyline.full_name }}
        </router-link>
      </div>
      <div
        class="item-container separator flex-container row-container justify-between"
      >
        <p class="text-gray-2">Birth date</p>
        <p class="text-primary bold">
          {{ storyline.birth_date | moment('DD MMMM YYYY') }}
        </p>
      </div>
      <div
        class="item-container separator flex-container row-container justify-between"
      >
        <p class="text-gray-2">Birth in</p>
        <p class="text-primary bold">
          {{ storyline.birth_location.full_address }}
        </p>
      </div>
      <div
        class="item-container separator flex-container row-container justify-between"
      >
        <p class="text-gray-2">Death date</p>
        <p class="text-primary bold">
          {{ storyline.death_date | moment('DD MMMM YYYY') }}
        </p>
      </div>
      <div class="item-container flex-container row-container justify-between">
        <p class="text-gray-2">Death in</p>
        <p class="text-primary bold">
          {{ storyline.death_location.full_address }}
        </p>
      </div>
      <div class="item-container flex-container row-container justify-between">
        <p class="text-gray-2">Gender</p>
        <p class="text-primary bold">{{ storyline.gender }}</p>
      </div>
      <div class="item-container flex-container row-container justify-between">
        <p class="text-gray-2">Privacy Setting</p>
        <router-link
          class="text-primary bold"
          :to="{ name: 'editor_update_privacy', params: { storylineId } }"
          >{{ storyline.privacy }}</router-link
        >
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { request } from '../../utils/interceptor'
import IconEdit from '../../assets/IconEdit'

export default {
  name: 'EditorManage',
  components: { IconEdit },
  data() {
    return {
      storylineId: '',
      storyline: {}
    }
  },
  mounted() {
    this.storylineId = this.$route.params.storylineId
    request
      .get(`/storylines/${this.storylineId}`)
      .then(e => (this.storyline = e.data))
  }
}
</script>

<style scoped>
.editor-container {
  margin: 85px 16px 16px 16px;
  padding: 20px 0 0 0;
  background-color: white;
  border-radius: 6px;
}

.editor-avatar {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-1);
  background-color: var(--color-2);
  margin: -65px 24px 0 24px;
  z-index: 2;
}

.item-container {
  padding: 21px 24px;
}

.edit-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--color-2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -16px 0 0 54px;
  z-index: 1;
  position: absolute;
}
</style>
