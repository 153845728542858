<template>
  <div class="flex-container column-container">
    <top-nav v-if="isCameraAvailable" title="Scan a Headstone QR" />
    <qrcode-stream
      v-if="isCameraAvailable"
      @decode="onDecode"
      class="flex-fill"
      @init="onInit"
    ></qrcode-stream>
    <qr-code-search v-else/>
  </div>
</template>

<script>
import TopNav from '@/components/Shared/TopNav'
import QrCodeSearch from "./QrCodeSearch";

export default {
  metaInfo: { title: 'Scan QR Code | Storyline' },
  name: 'ScanCamera',
  components: {QrCodeSearch, TopNav },
  data() {
    return {
      isCameraAvailable: true
    }
  },
  methods: {
    onDecode(str) {
      if ((str ?? '')?.search('storyline') > 0) {
        this.$router.push(str)
      }
    },
    async onInit(promise) {
      try {
        await promise
        this.isCameraAvailable = true
      } catch (e) {
        this.isCameraAvailable = false
        this.$notify({
          group: 'storyline',
          type: 'error',
          title: e
        })
      }
    }
  }
}
</script>

<style scoped></style>
