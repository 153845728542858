<template>
  <svg
    height="14.987"
    viewBox="0 0 15 14.987"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="back_icon"
      d="M511,1744.853H499.563l5.25-5.245L503.5,1738.3l-7.5,7.494,7.5,7.494,1.313-1.311-5.25-5.245H511Z"
      data-name="back icon"
      fill="#fff"
      transform="translate(-496 -1738.296)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconBack'
}
</script>

<style scoped></style>
