<template>
  <svg
    class="icon"
    height="27.545"
    viewBox="0 0 27.547 27.545"
    width="27.547"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0)">
      <path
        :fill="layout === 'grid' ? '#50badd' : 'white'"
        d="M4451.425,1725.435h-10.672a.851.851,0,0,0-.853.852v10.672a.851.851,0,0,0,.853.852h10.672a.85.85,0,0,0,.852-.852v-10.672a.851.851,0,0,0-.852-.852Zm-9.82,1.7h8.969v8.968H4441.6Z"
        transform="translate(-4439.9 -1725.435)"
      />
      <path
        :fill="layout === 'grid' ? '#50badd' : 'white'"
        d="M4467.844,1725.684a.859.859,0,0,0-.6-.249h-10.672a.851.851,0,0,0-.852.852v10.672a.851.851,0,0,0,.852.852h10.672a.852.852,0,0,0,.853-.852v-10.672A.859.859,0,0,0,4467.844,1725.684Zm-10.423,1.455h8.968v8.968h-8.969Z"
        transform="translate(-4440.547 -1725.435)"
      />
      <path
        :fill="layout === 'grid' ? '#50badd' : 'white'"
        d="M4451.425,1741.252h-10.672a.852.852,0,0,0-.853.852v10.672a.852.852,0,0,0,.853.852h10.672a.851.851,0,0,0,.852-.852V1742.1a.851.851,0,0,0-.852-.852Zm-9.82,1.7h8.969v8.969H4441.6Z"
        transform="translate(-4439.9 -1726.083)"
      />
      <path
        :fill="layout === 'grid' ? '#50badd' : 'white'"
        d="M4467.242,1741.252h-10.672a.852.852,0,0,0-.852.852v10.672a.852.852,0,0,0,.852.852h10.672a.853.853,0,0,0,.853-.852V1742.1a.853.853,0,0,0-.853-.852Zm-9.82,1.7h8.968v8.969h-8.969Z"
        transform="translate(-4440.547 -1726.083)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconGrid',
  props: {
    layout: String
  }
}
</script>

<style scoped></style>
