<template>
  <div class="memory-header">
    <img
      v-if="creator.profile_photo"
      :src="creator.profile_photo"
      class="avatar"
      alt="avatar-img"
    />
    <div v-else class="default-avatar">
      <span class="initials">
        {{ creator.name.charAt(0) }}
        {{ creator.last_name.charAt(0) }}
      </span>
    </div>
    <div class="creator-data">
      <h4 v-if="!isFeed" class="title">
        {{ creator.name }} {{ creator.last_name }}
      </h4>
      <h5 v-if="!isFeed" class="subtitle">{{ creatorRelationship.name }}</h5>
      <p v-if="isFeed" class="feed-header">
        {{ creator.name }} {{ creator.last_name }} posted on
      </p>
      <p v-if="isFeed" class="feed-header">
        <span @click="goToStoryline" class="text-primary">{{ storyline.full_name }}</span
        >'s storyline.
      </p>
      <svg
        v-if="layout !== 'grid' && isAuthed"
        class="icon"
        height="22.124"
        viewBox="0 0 12.642 22.124"
        width="12.642"
        xmlns="http://www.w3.org/2000/svg"
        @click="goToMemoryDetail"
      >
        <path
          d="M2672.64,831.464l8.28,8.28-8.388,8.388h0a1.58,1.58,0,0,0,2.234,2.234h0l9.48-9.482h0a1.571,1.571,0,0,0,.484-1.137v-.007a1.571,1.571,0,0,0-.484-1.137h0l-9.48-9.482,0,0a1.58,1.58,0,1,0-2.124,2.339Z"
          fill="#285197"
          fill-rule="evenodd"
          transform="translate(-2672.09 -828.685)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MemoryHeader',
  props: {
    creator: Object,
    creatorRelationship: Object,
    storyline: Object,
    memoryID: String,
    isFeed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('storyline', ['layout']),
    isAuthed() {
      return this.$store.state?.user?.authToken?.length > 0
    }
  },
  methods: {
    ...mapActions('modal', ['updateIsVisible']),
    goToStoryline() {
      if (this.$store.state?.user?.authToken?.length > 0) {
        this.$router.push({
          path: `/storyline/${this.storyline.id}`,
        })
      }
    },
    goToMemoryDetail() {
      if (this.$store.state?.user?.authToken?.length > 0) {
        this.$router.push({
          name: 'memory_details',
          params: { memoryId: this.memoryID, storylineId: this.storyline.id }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.memory-header {
  align-items: center;
  display: flex;
  height: 90px;
  padding: 0 30px;
  width: 100%;
  @media screen and (max-width: 650px) {
    padding: 0 20px;
  }

  .avatar {
    border-radius: 50%;
    display: block;
    height: 40px;
    margin-right: 10px;
    width: 40px;
  }
  .creator-data {
    position: relative;
    flex: 1;

    .title {
      color: var(--color-2);
      font-family: 'sans-bold', serif;
      font-size: 14px;
      font-weight: normal;
    }

    .subtitle {
      color: var(--gray-3);
      font-size: 12px;
      font-weight: normal;
      text-transform: capitalize;
    }

    .icon {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.feed-header {
  color: #22222280;
}
</style>
