<template>
  <div class="flex-container column-container">
    <top-nav show-back title="Update My Account" :message="message" extended />
    <slider
      class="flex-fill"
      :indicators="false"
      :control-btn="false"
      v-model="sliderIndex"
      :autoplay="false"
      :touch="false"
    >
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="firstName"
            autofocus
          />
        </div>
      </slider-item>
      <slider-item>
        <div class="height-100 flex-container justify-center align-center">
          <input
            class="input-container text-primary text-center width-100"
            v-model="lastName"
            autofocus
          />
        </div>
      </slider-item>
    </slider>
    <button class="primary-button button-container" @click="onBtnClick">
      {{ btnTitle }}
    </button>
  </div>
</template>

<script>
import TopNav from '@/components/Shared/TopNav'
import { USERS_URL } from '@/utils/endpoints'
import { request } from '@/utils/interceptor'

export default {
  metaInfo: { title: 'Edit Profile | Storyline' },
  name: 'EditProfile',
  components: { TopNav },
  data() {
    return {
      sliderIndex: 0,
      firstName: '',
      lastName: ''
    }
  },
  methods: {
    onBtnClick() {
      switch (this.sliderIndex) {
        case 0:
          if (this.firstName.length > 0) this.sliderIndex++
          break
        default:
          if (this.lastName.length > 0) {
            const userId = this.$store.state.user.user.id
            request
              .patch(`${USERS_URL}/${userId}`, {
                name: this.firstName,
                last_name: this.lastName
              })
              .then(() =>
                this.$notify({
                  group: 'storyline',
                  title: 'Successfully updated'
                })
              )
              .catch(() =>
                this.$notify({
                  group: 'storyline',
                  title: 'Updating was failed',
                  type: 'error'
                })
              )
            this.$router.back()
          }
          return ''
      }
    }
  },
  computed: {
    message() {
      switch (this.sliderIndex) {
        case 0:
          return 'What is your first name?'
        case 1:
          return 'What is your last name?'
        default:
          return ''
      }
    },
    btnTitle() {
      switch (this.sliderIndex) {
        case 0:
          return 'Next'
        case 1:
          return 'Save'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>
.button-container {
  margin: 0 28px 24px 28px;
  width: auto;
}
</style>
