import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
// import Home from '@/components/Home/HomeView.vue'
import StorylineWrap from '@/components/Storyline/StorylineWrap.vue'
import StorylineView from '@/components/Storyline/StorylineView.vue'
import Services from '@/components/Services/ServicesView.vue'
import Obituary from '@/components/Obituary/ObituaryView.vue'
import Links from '@/components/Links/LinksView.vue'
import VueMeta from 'vue-meta'
import Auth from '@/components/Auth/Auth'
import SignIn from '@/components/Auth/SignIn'
import SignUp from '@/components/Auth/SignUp'
import WalkThru from '@/components/Auth/WalkThru'
import Main from '@/components/Main/Main'
import Storylines from '@/components/Storyline/Storylines'
import NewStoryline from '@/components/Storyline/NewStoryline.vue'
import AppWrap from '@/components/App/AppWrap'
import Search from '@/components/Search/Search'
import QrCode from '@/components/QrCode/QrCode'
import Following from '@/components/Following/Following'
import Account from '@/components/Account/Account'
import ScanResult from '@/components/QrCode/ScanResult'
import ScanCamera from '@/components/QrCode/ScanCamera'
import NewMemory from '@/components/Memory/New/NewMemory'
import PreviewMemory from '@/components/Memory/PreviewMemory'
import StorylineQRCode from '@/components/Storyline/StorylineQRCode'
import EditProfile from '@/components/Account/EditProfile'
import Profile from '@/components/Account/Profile'
import MyStorylines from '@/components/Account/MyStorylines'
import MemoryComments from '../components/Memory/MemoryComments'
import EditorHome from '../components/StoryEditor/EditorHome'
import StoryEditors from '../components/StoryEditor/StoryEditors'
import EditorDetails from '../components/StoryEditor/EditorDetails'
import EditorWrap from '../components/StoryEditor/EditorWrap'
import EditorNew from '../components/StoryEditor/EditorNew'
import EditorManage from '../components/StoryEditor/EditorManage'
import EditorMangeWrap from '../components/StoryEditor/EditorMangeWrap'
import EditorObituary from '../components/StoryEditor/EditorObituary'
import EditorLinks from '../components/StoryEditor/EditorLinks'
import EditorUpdateName from '../components/StoryEditor/EditorUpdateName'
import EditorUpdatePrivacy from '../components/StoryEditor/EditorUpdatePrivacy'
import EditorUploadPhoto from '../components/StoryEditor/EditorUploadPhoto'
import EditorPending from '../components/StoryEditor/EditorPending'

Vue.use(VueRouter)
Vue.use(VueMeta)

function AuthGuard(to, from, next) {
  // Bypass walkthrough due to bug
  if (store.state.user.authToken) {
    next()
  } else if (!store.state.user.authToken) {
    next({ name: 'auth' })
  } else if (
    !store.state?.user?.user?.completed_walk_through &&
    to.name !== 'workthru'
  ) {
    next({ name: 'workthru' })
  }
  if (
    store.state?.user?.user?.completed_walk_through &&
    to.name === 'workthru'
  ) {
    next({ name: 'storyline' })
  } else {
    next()
  }
}

function Guest(to, from, next) {
  if (store.state.user.authToken) {
    next({ name: 'storyline' })
  } else {
    next()
  }
}

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   beforeEnter: Guest
  // },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    beforeEnter: Guest
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn,
    beforeEnter: Guest
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    beforeEnter: Guest
  },
  {
    path: '/walk-thru',
    name: 'workthru',
    component: WalkThru,
    beforeEnter: AuthGuard
  },
  {
    path: '/storylines/new',
    name: 'new_storyline',
    component: NewStoryline,
    beforeEnter: AuthGuard
  },
  {
    path: '/',
    component: Main,
    name: 'main',
    children: [
      {
        path: '/',
        redirect: '/storyline'
      },
      {
        path: '/storyline',
        component: AppWrap,
        name: 'feed',
        children: [
          {
            path: '/',
            name: 'storyline',
            component: Storylines,
            beforeEnter: AuthGuard
          },
          {
            path: ':storylineId',
            component: StorylineWrap,
            children: [
              {
                name: 'StorylineView',
                path: '',
                component: StorylineView,
                meta: {
                  requiresAuth: true
                }
              },
              {
                name: 'Services',
                path: 'services',
                component: Services,
                meta: {
                  requiresAuth: true
                }
              },
              {
                name: 'Obituary',
                path: 'obituary',
                component: Obituary,
                meta: {
                  requiresAuth: true
                }
              },
              {
                name: 'Links',
                path: 'links',
                component: Links,
                meta: {
                  requiresAuth: true
                }
              },
              {
                name: 'qrcode',
                path: 'qrcode',
                component: StorylineQRCode
              }
            ]
          },
          {
            name: 'new_memory',
            path: ':storylineId/memory/new',
            component: NewMemory
          },
          {
            name: 'memory_details',
            path: ':storylineId/memory/:memoryId',
            component: PreviewMemory
          },
          {
            name: 'memory_comments',
            path: ':storylineId/memory/:memoryId/comments',
            component: MemoryComments
          }
        ]
      },
      {
        path: '/search',
        name: 'search',
        component: Search,
        beforeEnter: AuthGuard
      },
      {
        path: '/',
        component: AppWrap,
        name: 'qr_code',
        children: [
          {
            path: '/qrcode',
            name: 'qrcode',
            component: QrCode
          },
          {
            path: '/camera',
            name: 'scan_camera',
            component: ScanCamera
          },
          {
            path: '/result',
            name: 'scan_result',
            component: ScanResult
          }
        ],
        beforeEnter: AuthGuard
      },
      {
        path: '/following',
        name: 'following',
        component: Following,
        beforeEnter: AuthGuard
      },
      {
        path: '/account',
        name: 'setting',
        component: AppWrap,
        children: [
          {
            path: '',
            name: 'account',
            component: Account,
            children: [
              {
                path: '',
                name: 'profile',
                component: Profile
              },
              {
                path: '/storyline',
                name: 'my_storyline',
                component: MyStorylines
              }
            ]
          },
          {
            path: '/edit',
            name: 'edit',
            component: EditProfile
          },
          {
            path: '/editor/:storylineId',
            name: 'story_editor',
            component: EditorHome
          },
          {
            path: '/editor/:storylineId/editors',
            component: EditorWrap,
            children: [
              {
                name: 'story_editors',
                path: '',
                component: StoryEditors
              },
              {
                name: 'editor_details',
                path: ':editorId',
                component: EditorDetails
              }
            ]
          },
          {
            name: 'editor_new',
            path: '/editor/:storylineId/new',
            component: EditorNew
          },
          {
            path: '/editor',
            component: EditorMangeWrap,
            children: [
              {
                name: 'editor_profile',
                path: '/:storylineId/manage/profile',
                component: EditorManage
              },
              {
                name: 'editor_obituary',
                path: '/:storylineId/manage/obituary',
                component: EditorObituary
              },
              {
                name: 'editor_links',
                path: '/:storylineId/manage/links',
                component: EditorLinks
              }
            ]
          },
          {
            name: 'editor_update_name',
            path: '/editor/:storylineId/update-name',
            component: EditorUpdateName
          },
          {
            name: 'editor_update_privacy',
            path: '/editor/:storylineId/update-privacy',
            component: EditorUpdatePrivacy
          },
          {
            name: 'editor_upload_image',
            path: '/editor/:storylineId/upload-photo',
            component: EditorUploadPhoto
          },
          {
            name: 'editor_pending',
            path: '/editor/:storylineId/pending',
            component: EditorPending
          }
        ],
        beforeEnter: AuthGuard
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
