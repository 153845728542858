<template>
  <perfect-scrollbar>
    <StorylineFeedLayout />
    <div class="card-prompt" v-if="isAuthed">
      <div class="card-prompt-input" @click="onAddNewMemory">
        <p>Share a memory...</p>
      </div>
      <div class="card-prompt-types">
        <div class="card-prompt-type" @click="onAddNewMemory">
          <p>
            <IconAdd class="svg-small" color="#285197" />
            Write a story
          </p>
        </div>
        <div class="card-prompt-type" @click="onAddNewMemory">
          <p>
            <IconImage color="#285197" />
            Post a photo
          </p>
        </div>
        <div id="card-prompt-share" class="card-prompt-type" @click="onShare">
          <p>
            <IconShare color="#285197" />
            Share storyline
          </p>
        </div>
      </div>
    </div>
    <div :class="layout === 'grid' ? 'grid memory-wrap' : 'memory-wrap'">
      <Memory v-for="memory in memoryList" :key="memory.id" :memory="memory" />
    </div>
  </perfect-scrollbar>
</template>

<script>
import IconImage from '@/assets/IconImage'
import IconAdd from '@/assets/IconAdd'
import IconShare from '@/assets/IconShare'
import StorylineFeedLayout from '@/components/Storyline/StorylineFeedLayout.vue'
import Memory from '@/components/Memory/Memory.vue'
import { mapState } from 'vuex'

export default {
  props: {
    memoryList: Array
  },
  components: {
    StorylineFeedLayout,
    Memory,
    IconImage,
    IconAdd,
    IconShare
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('storyline', ['layout']),
    isAuthed() {
      return this.$store.state?.user?.authToken?.length > 0
    }
  },
  methods: {
    onAddNewMemory() {
      this.$router.push({ name: 'new_memory' })
    },
    onShare() {
      const id = this.$store.state.storyline.id;
      const path = this.$router.resolve({
        path: `/storyline/${id}`,
      }).href;
      const link = "https://app.storyline.co" + path;
      const shareLink = "http://www.facebook.com/share.php?u=" + link;
      
      window.open(shareLink, '_blank');
    }
  },
}
</script>

<style lang="scss" scoped>

  .card-prompt {
    background-color: #FFF;
    padding: 20px;
    // margin: 20px;
    border-bottom: 1px solid #eaeaea;
  }

  .card-prompt-input {
    background: #f0f2f5;
    color: gray;
    padding: 10px 15px;
    border-radius: 25px;

    &:hover {
      cursor: pointer;
    }
  }

  .card-prompt-types {
    margin-top: 25px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: auto auto auto;
    color: #285197;
  }

  .card-prompt-type {
    text-align: center;
    text-transform: uppercase;
  
    p svg {
      margin-right: 5px;
    }

    p svg.svg-small {
      width: 10px;
      padding-top: 5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  #card-prompt-share svg {
    padding-top: 2px;
  }
.memory-wrap {
  background-color: var(--white);

  &.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    padding: 30px;
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 30px 10px;
    }

    .memory {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
</style>
