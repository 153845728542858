<template>
  <router-link
    :to="{ name: 'StorylineView', params: { storylineId: itemId } }"
    class="item-container"
  >
    <img
      v-if="item.profile_photo"
      :src="item.profile_photo"
      alt="item-avatar"
      class="item-avatar"
    />
    <div v-else class="item-avatar">
      <span class="text-white">
        {{ item.first_name.charAt(0) }}
        {{ item.last_name.charAt(0) }}
      </span>
    </div>
    <div class="column-container item-info">
      <h4 class="item-name text-white">{{ fullName }}</h4>
      <p class="item-subtitle item-address">
        {{ fullAddress }}
      </p>
      <p class="item-subtitle">{{ period }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ProfileListItem',
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    period() {
      return `${this.item?.birth_date?.substring(
        0,
        4
      )} - ${this.item?.death_date?.substring(0, 4)}`
    },
    fullName() {
      return this.item?.full_name
    },
    fullAddress() {
      return this.item?.death_location?.full_address
    },
    itemId() {
      return this.item.id
    }
  }
}
</script>

<style scoped>
.item-container {
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: center;
  background-color: var(--color-2);
  margin-bottom: 12px;
}

.item-avatar {
  height: 68px;
  width: 68px;
  border-radius: 34px;
  object-fit: cover;
  display: flex;
  background-color: var(--gray-2);
  justify-content: center;
  align-items: center;
}

.item-name {
  font-size: 18px;
  font-weight: bold;
}

.item-subtitle {
  font-size: 14px;
  color: #c0cad8;
}

.item-address {
  margin-top: 5px;
  margin-bottom: 7px;
}

.item-info {
  margin-left: 16px;
}
</style>
