<template>
  <svg
    height="16.771"
    viewBox="0 0 16.781 16.771"
    width="16.781"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_7"
      :fill="color"
      d="M4459.514,725.237a5.58,5.58,0,1,0-5.893.006,8.537,8.537,0,0,0-5.439,6.448h1.425a7.135,7.135,0,0,1,13.932,0h1.424A8.538,8.538,0,0,0,4459.514,725.237Zm-7.134-4.726a4.193,4.193,0,1,1,4.193,4.193A4.2,4.2,0,0,1,4452.38,720.511Z"
      data-name="Path 7"
      transform="translate(-4448.182 -714.921)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconProfile',
  props: {
    color: {
      type: String,
      default: '#50badd'
    }
  }
}
</script>

<style scoped></style>
