<template>
  <!-- <svg
    :height="size"
    viewBox="0 0 16.651 16.667"
    :width="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9A2,2 0 0,1 6,7H9V9H6V21H18V9H15V7H18A2,2 0 0,1 20,9V21A2,2 0 0,1 18,23Z"
      :fill="color"
      transform="translate(-1211.389 -4798.667)"
    />
  </svg> -->

  <svg :height="size" :width="size" viewBox="0 0 24 24">
      <path 
        :fill="color" 
        stroke-width="0.5"
        d="M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9A2,2 0 0,1 6,7H9V9H6V21H18V9H15V7H18A2,2 0 0,1 20,9V21A2,2 0 0,1 18,23Z" />
  </svg>
</template>

<script>
export default {
  name: 'IconShare',
  props: {
    size: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style scoped></style>
