import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import main from './modules/main'
import user from './modules/user'
import storyline from './modules/storyline'
import memory from './modules/memory'
import service from './modules/service'
import modal from './modules/modal'
import invite from './modules/invite'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main,
    user,
    storyline,
    memory,
    service,
    modal,
    invite,
    auth
  },
  plugins: [new VuexPersistence().plugin]
})
