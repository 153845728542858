<template>
  <div class="service-wrap">
    <div class="service-header">
      <div class="date">
        <span class="month">{{ month }}</span>
        <span class="day">{{ monthDay }}</span>
      </div>
      <h4 class="title">{{ data.title.name }}</h4>
    </div>
    <div class="service-body">
      <div class="row">
        <div class="left">
          <span class="label">When</span>
        </div>
        <div class="right">
          <p class="paragraph">
            {{ weekday }}. {{ month }} {{ monthDay }}, {{ year }}<br />{{
              startTime
            }}
            - {{ endTime }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="left">
          <span class="label">Where</span>
        </div>
        <div class="right">
          <p class="paragraph">{{ data.location.name }}</p>
          <p class="paragraph">
            {{ data.location.street[0] }}<br />{{ data.location.city }},
            {{ data.location.state }} {{ data.location.zipcode }}
          </p>
        </div>
      </div>
      <div v-if="data.details" class="row">
        <div class="left">
          <span class="label">Details</span>
        </div>
        <div class="right">
          <p class="details paragraph">{{ data.details }}</p>
        </div>
      </div>
    </div>
    <div class="service-actions" style="display: none">
      <Button
        btnStyle="gradient"
        text="Share this event"
        @handleClick="shareEvent"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Form/Button.vue'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

export default {
  data() {
    return {}
  },
  props: {
    data: Object
  },
  components: {
    Button
  },
  computed: {
    weekday() {
      return format(parseISO(this.$props.data.start_time), 'iii')
    },
    monthDay() {
      return format(parseISO(this.$props.data.start_time), 'd')
    },
    month() {
      return format(parseISO(this.$props.data.start_time), 'MMM')
    },
    year() {
      return format(parseISO(this.$props.data.start_time), 'yyy')
    },
    startTime() {
      return format(parseISO(this.$props.data.start_time), 'h aaaa')
    },
    endTime() {
      return format(parseISO(this.$props.data.end_time), 'h aaaa')
    }
  },
  methods: {
    addCalendar() {},
    shareEvent() {}
  }
}
</script>

<style lang="scss" scoped>
.service-wrap {
  background-color: var(--white);
  border-radius: 10px;
  margin-top: 30px;
}

.service-header {
  align-items: center;
  border-bottom: solid 1px #c6d2e3;
  display: flex;
  padding: 30px;

  .date {
    align-items: center;
    background-color: var(--color-2);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: center;
    margin-right: 30px;
    width: 80px;

    .month {
      font-family: 'sans-bold';
      font-size: 1.5rem;
      line-height: 1;
      text-transform: uppercase;
    }

    .day {
      font-family: 'sans-bold';
      font-size: 2.5rem;
      line-height: 1;
    }
  }

  .title {
    color: var(--color-2);
    font-family: 'sans-bold';
    font-size: 2.1rem;
    font-weight: normal;
  }
}

.service-body {
  padding: 30px;

  .row {
    display: flex;
    margin-bottom: 30px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    .left {
      min-width: 110px;

      .label {
        color: var(--color-1);
        font-family: 'sans-bold';
        font-size: 1.8rem;
      }
    }

    .right {
      flex: 1;

      .paragraph {
        &.details {
          white-space: break-spaces;
        }

        color: var(--gray-2);
        font-size: 1.8rem;

        & + .paragraph {
          margin-top: 15px;
        }
      }
    }
  }
}

.service-actions {
  padding: 15px 30px 30px;

  .btn {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
