import { request } from '@/utils/interceptor'

const state = {
  storyline: {},
  memoryList: [],
  servicesList: [],
  condolencesList: []
}

const actions = {
  async fetchStoryline({ commit }, payload) {
    try {
      const response = await request.get(`/storylines/${payload}`)
      commit('setStoryline', response.data)
    } catch (error) {
      console.log(error)
    }
  },
  async fetchMemories({ commit }, payload) {
    try {
      const response = await request.get(
        `/storylines/${payload.storyline}/memories`
      )
      commit('setMemoryList', response.data.data)
    } catch (error) {
      console.log(error)
    }
  },
  async fetchServices({ commit }, payload) {
    try {
      const response = await request.get(
        `/storylines/${payload.storyline}/services`
      )
      commit('setServicesList', response.data.data)
    } catch (error) {
      console.log(error)
    }
  },
  async fetchCondolences({ commit }, payload) {
    try {
      const response = await request.get(
        `/storylines/${payload.storyline}/condolences`
      )
      commit('setCondolencesList', response.data.data)
    } catch (error) {
      console.log(error)
    }
  }
}

const mutations = {
  setStoryline: (state, storyline) => {
    state.storyline = storyline
  },
  setMemoryList: (state, memoryList) => {
    state.memoryList = memoryList
  },
  setServicesList: (state, servicesList) => {
    state.servicesList = servicesList
  },
  setCondolencesList: (state, condolencesList) => {
    state.condolencesList = condolencesList
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
