<template>
  <svg
    id="Group_161"
    data-name="Group 161"
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 35.728 25.399"
  >
    <path
      id="Path_59"
      data-name="Path 59"
      d="M1657.806,560.829a32.063,32.063,0,0,0-7.944-6.725l-1.5,1.534a28.716,28.716,0,0,1,7.368,5.983c-1.571,1.778-7.715,8.092-15.657,8.092a15.8,15.8,0,0,1-4.768-.759l-1.615,1.645.041.016a18.065,18.065,0,0,0,6.342,1.18c10.37,0,17.482-9.085,17.779-9.471l.218-.281v-.917Z"
      transform="translate(-1622.339 -548.903)"
      :fill="color"
    />
    <path
      id="Path_60"
      data-name="Path 60"
      d="M1648.748,547.3l-23.472,23.939,1.478,1.46,23.473-23.939Z"
      transform="translate(-1620.359 -547.302)"
      :fill="color"
    />
    <path
      id="Path_61"
      data-name="Path 61"
      d="M1627.949,566.393a29.458,29.458,0,0,1-6.764-5.639c1.568-1.778,7.7-8.091,15.657-8.091a15.638,15.638,0,0,1,4.032.549l1.657-1.69-.055-.018a17.9,17.9,0,0,0-5.634-.923c-10.37,0-17.483,9.085-17.781,9.471l-.216.281v.917l.258.3a32.862,32.862,0,0,0,7.347,6.376Z"
      transform="translate(-1618.845 -548.073)"
      :fill="color"
    />
    <path
      id="Path_62"
      data-name="Path 62"
      d="M1641.481,566.4a3.124,3.124,0,0,1-.766-.106l-1.594,1.626.009,0a5.222,5.222,0,0,0,2.352.559,5.316,5.316,0,0,0,4.71-7.76l-.005-.008-1.6,1.628a3.183,3.183,0,0,1,.121.818A3.238,3.238,0,0,1,1641.481,566.4Z"
      transform="translate(-1623.618 -550.458)"
      :fill="color"
    />
    <path
      id="Path_63"
      data-name="Path 63"
      d="M1635.272,562.273a5.348,5.348,0,0,0,.29,1.723l.019.056,1.767-1.8a3.236,3.236,0,0,1,3.151-3.215l1.77-1.8-.056-.019a5.253,5.253,0,0,0-1.638-.262A5.318,5.318,0,0,0,1635.272,562.273Z"
      transform="translate(-1622.712 -549.573)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconUnlisted',
  props: {
    color: {
      type: String,
      default: '#8d90a3'
    }
  }
}
</script>

<style scoped></style>
