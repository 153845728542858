<template>
  <perfect-scrollbar class="column-container">
    <img
      alt="storyline1"
      class="story-image"
      src="../../assets/background.png"
    />
    <div class="story-image overlay-container">
      <PhoneQR class="qrcode-image" />
      <h2 class="text-white title-container text-center">Scan a QR code.</h2>
      <p class="text-white text-center">
        Storyline is a place to preserve and share the memories of loved ones.
      </p>
      <p class="text-white no-description text-center">
        To view or contribute to a Storyline, use your phone’s camera to scan
        the QR code or select “Search by Name” below.
      </p>
    </div>
    <div class="btn-container">
      <button class="primary-button" v-on:click="goToScanCamera">
        Open Camera to Scan
      </button>
      <button class="text-button btn-spacing" v-on:click="goToSearch">
        Search by Name
      </button>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PhoneQR from '@/assets/PhoneQR'
import router from '@/router'

export default {
  metaInfo: { title: 'Scan QR Code | Storyline' },
  name: 'QrCode',
  components: { PhoneQR },
  methods: {
    goToSearch() {
      router.push({ name: 'search' })
    },
    goToScanCamera() {
      router.push({ name: 'scan_camera' })
    }
  }
}
</script>

<style scoped>
.story-image {
  width: 100%;
  aspect-ratio: 375 / 605;
  object-fit: cover;
}

.overlay-container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 50px 50px;
}

.qrcode-image {
  margin-bottom: 14px;
}

.title-container {
  margin: 14px 0 14px;
}

.no-description {
  margin-top: 28px;
}

.btn-container {
  margin: 24px 28px 24px;
}

.btn-spacing {
  margin-top: 16px;
}
</style>
