<template>
  <div class="flex-container column-container container">
    <perfect-scrollbar>
      <p class="text-primary count-container">
        ({{ count }}) Active Storyeditors
      </p>
      <div
        v-for="item in editors"
        :key="item.id"
        class="editor-container flex-container column-container align-center"
      >
        <img
          v-if="item.user.profile_photo"
          :src="item.user.profile_photo"
          class="editor-avatar"
          alt="profile-img"
        />
        <div v-else class="editor-avatar">
          <span class="text-white">
            {{ item.user.name.charAt(0) }}
            {{ item.user.last_name.charAt(0) }}
          </span>
        </div>
        <h4 class="editor-name text-primary">
          {{ item.user.name }} {{ item.user.last_name }}
        </h4>
        <p class="editor-info">{{ item.user.email }}</p>
        <p class="editor-info">{{ item.user.phone_number }}</p>
        <div class="btn-container">
          <button class="primary-button" @click="goGoEditorProfile(item)">
            Manage Storyeditor
          </button>
        </div>
      </div>
    </perfect-scrollbar>
    <button class="fab-position" @click="onAddNewEditor">
      <IconAdd />
    </button>
  </div>
</template>

<script>
import { request } from '../../utils/interceptor'
import IconAdd from '../../assets/IconAdd'

export default {
  name: 'StoryEditors',
  components: { IconAdd },
  data() {
    return {
      editors: [],
      storylineId: '',
      count: 0
    }
  },
  mounted() {
    this.storylineId = this.$route.params.storylineId
    request.get(`/storylines/${this.storylineId}/storyeditors`).then(e => {
      this.editors = e.data.data
      this.count = e.data.count
    })
  },
  methods: {
    goGoEditorProfile(item) {
      this.$router.push({
        name: 'editor_details',
        params: { userId: item.user.id, editorId: item.id }
      })
    },
    onAddNewEditor() {
      this.$router.push({ name: 'editor_new' })
    }
  }
}
</script>

<style scoped>
.editor-name {
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 10px;
}

.editor-container {
  margin: 8px;
  padding: 20px 0 10px 0;
  background-color: white;
  border-radius: 6px;
}

.editor-avatar {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
}

.editor-info {
  color: #22222280;
}

.btn-container {
  padding: 16px 20px 8px 20px;
  width: 100%;
}

.count-container {
  margin: 24px 8px;
}

.container {
  height: calc(var(--vh, 1vh) * 100 - 258px);
}
</style>
