<template>
  <div class="flex-container row-container pending-item align-center">
    <img
      v-if="item.user.profile_photo"
      class="pending-avatar"
      :src="item.user.profile_photo"
      alt="profile-avatar"
    />
    <div v-else class="pending-avatar">
      <span class="text-white">
        {{ item.user.name.charAt(0) }}
        {{ item.user.last_name.charAt(0) }}
      </span>
    </div>
    <h4 class="text-primary flex-fill pending-name">
      {{ item.user.name }} {{ item.user.last_name }}
    </h4>
    <button
      v-if="!(approved || rejected)"
      class="approve-button"
      @click="approveFollowing"
    >
      Approve
    </button>
    <button
      v-if="!(approved || rejected)"
      class="reject-button"
      @click="rejectFollowing"
    >
      X
    </button>
  </div>
</template>

<script>
import { request } from '../../utils/interceptor'

export default {
  name: 'EditorFollowingItem',
  props: {
    storylineId: String,
    item: Object
  },
  methods: {
    approveFollowing() {
      request
        .post(
          `/storylines/${this.storylineId}/following/${this.item.id}/approve`
        )
        .then(() => {
          this.approved = true
          this.$notify({
            group: 'storyline',
            title: 'A memory is approved.'
          })
        })
        .catch(e =>
          this.$notify({
            group: 'storyline',
            type: 'error',
            title: e.message
          })
        )
    },
    rejectFollowing() {
      request
        .post(`/storylines/${this.storylineId}/following/${this.item.id}/deny`)
        .then(() => {
          this.approved = true
          this.$notify({
            group: 'storyline',
            title: 'A memory is rejected.'
          })
        })
        .catch(e =>
          this.$notify({
            group: 'storyline',
            type: 'error',
            title: e.message
          })
        )
    }
  },
  data() {
    return {
      approved: false,
      rejected: false
    }
  }
}
</script>

<style scoped>
.pending-item {
  margin: 0 24px 16px 24px;
}
.pending-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--color-2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pending-name {
  margin: 0 12px;
}

.approve-button {
  padding: 8px 12px;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  color: white;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
}

.reject-button {
  padding: 8px 12px;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  background: transparent;
}
</style>
