<template>
  <div
    :class="
      extended ? (description ? 'top-nav-extended' : 'top-nav-medium') : ''
    "
    class="top-nav-container flex-container column-container"
  >
    <div class="nav-controllers">
      <a v-on:click="onBack">
        <IconBack v-if="showBack" />
      </a>
      <p class="nav-title">{{ title }}</p>
    </div>
    <div v-if="extended" class="flex-container column-container width-100">
      <h2 class="text-white width-100 text-center message-text">
        {{ message }}
      </h2>
      <p class="text-white width-100 text-center description-text">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import IconBack from '@/assets/IconBack'
import router from '@/router'

export default {
  name: 'TopNav',
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    showMore: {
      type: Boolean,
      default: false
    },
    extended: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    message: {
      type: String
    },
    description: {
      type: String
    }
  },
  components: { IconBack },
  methods: {
    onBack() {
      router.back()
    }
  }
}
</script>

<style scoped>
.top-nav-container {
  height: 80px;
  width: 100%;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  padding: 12px 18px;
}

.top-nav-extended {
  height: 195px;
}

.top-nav-medium {
  height: 140px;
}

.nav-controllers {
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
}

.message-text {
  margin: 32px 0 8px 0;
}

.description-text {
  margin: 0 0 12px 0;
}
</style>
