<template>
  <div class="not-authorized">
    <img alt="icon-lock" class="icon" src="/img/icon-lock.svg" />
    <p class="paragraph">This Storyline is private.</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.not-authorized {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0;

  .icon {
  }

  .paragraph {
    color: var(--color-2);
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 25px;
  }
}
</style>
