<template>
  <div class="memory-grid" @click="goToMemoryDetail">
    <div
      v-if="memory.media && memory.media.data.length"
      class="memory-type-media"
    >
      <div class="img-wrap">
        <img
          :src="memory.media.data[0].paths.small.path"
          alt="img"
          class="img"
        />
        <!-- <svg
          class="icon-video"
          v-if="memory.media[0].video"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background:new 0 0 512 512;"
          xml:space="preserve"
        >
          <path
            fill="#FFFFFF"
            d="M64,96h240c35.344,0,64,28.656,64,64v192c0,35.344-28.656,64-64,64H64c-35.344,0-64-28.656-64-64
    V160C0,124.656,28.656,96,64,96z"
          />
          <path
            fill="#FFFFF"
            d="M485.28,121.92L400,198.56v116.32l85.12,78.4
    c6.48,6.016,16.608,5.632,22.608-0.848c2.736-2.944,4.256-6.816,4.272-10.832V133.92c0.064-8.832-7.04-16.048-15.872-16.128
    C492.128,117.76,488.256,119.232,485.28,121.92z"
          />
        </svg> -->
      </div>
    </div>
    <div v-else class="memory-type-text-wrap">
      <div class="memory-type-text">
        <MemoryHeader
          :creator="memory.creator"
          :creatorRelationship="memory.creator_relationship"
          :memoryID="memory.id"
        />
        <MemoryText
          v-if="memory.body"
          :memoryID="memory.id"
          :memoryText="memory.body"
          :textLength="70"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MemoryHeader from '@/components/Memory/MemoryHeader.vue'
import MemoryText from '@/components/Memory/MemoryText.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    memory: Object
  },
  components: {
    MemoryHeader,
    MemoryText
  },
  methods: {
    ...mapActions('modal', ['updateIsVisible']),
    goToMemoryDetail() {
      this.updateIsVisible(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.memory-grid {
  display: flex;
  height: 100%;

  .memory-header {
    height: auto;
    padding: 15px 15px 0;

    .title {
      font-size: 1.6rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }
  }

  .memory-text {
    font-size: 1.4rem;
    margin-top: 0;
    padding: 15px;
  }
}

.memory-type-text-wrap {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .memory-type-text {
    border: solid 1px #d8d8d8;
    border-radius: 2px;
    height: 195px;
    width: 195px;
    width: 100%;
    @media screen and (max-width: 730px) {
      height: 190px;
      width: 190px;
    }
    @media screen and (max-width: 450px) {
      height: 340px;
      width: 340px;
    }
  }
}

.memory-type-media {
  height: 100%;
  width: 100%;

  .img-wrap {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .img {
    height: 195px;
    width: 195px;
    object-fit: cover;
    @media screen and (max-width: 730px) {
      height: 190px;
      width: 190px;
    }
    @media screen and (max-width: 450px) {
      height: 340px;
      width: 340px;
    }
  }
}
</style>
