<template>
  <svg
    height="16.771"
    viewBox="0 0 18.295 16.771"
    width="18.295"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Group_216"
      data-name="Group 216"
      transform="translate(24185 -5630.872)"
    >
      <g
        id="Group_23"
        data-name="Group 23"
        transform="translate(-24185 5630.872)"
      >
        <g
          id="Group_21"
          data-name="Group 21"
          transform="translate(2.006 4.782)"
        >
          <path
            id="Path_10"
            :fill="color"
            d="M-29.136,708.623H-33.7a.711.711,0,0,1-.715-.709v-4.467h-2.289v4.467a.711.711,0,0,1-.714.709H-41.99a.711.711,0,0,1-.714-.709V697.343a.711.711,0,0,1,.714-.709.711.711,0,0,1,.714.709v9.862h3.14v-4.467a.711.711,0,0,1,.714-.709H-33.7a.711.711,0,0,1,.714.709v4.467h3.14v-9.862a.711.711,0,0,1,.714-.709.711.711,0,0,1,.714.709v10.571a.711.711,0,0,1-.714.709Z"
            data-name="Path 10"
            transform="translate(42.704 -696.634)"
          />
        </g>
        <g id="Group_22" data-name="Group 22" transform="translate(0)">
          <path
            id="Path_11"
            :fill="color"
            d="M-27.943,700.928a.69.69,0,0,1-.408-.133l-8.036-5.8-8.036,5.8a.7.7,0,0,1-.981-.164.713.713,0,0,1,.163-.989l8.444-6.1a.7.7,0,0,1,.819,0l8.444,6.1a.712.712,0,0,1,.162.989.7.7,0,0,1-.573.3Z"
            data-name="Path 11"
            transform="translate(45.535 -693.414)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHome',
  props: {
    color: {
      type: String,
      default: '#50badd'
    }
  }
}
</script>

<style scoped></style>
