<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="17.417"
    viewBox="0 0 19 17.417"
  >
    <g
      id="Group_273"
      data-name="Group 273"
      transform="translate(-155.5 -125.667)"
    >
      <g
        id="Group_272"
        data-name="Group 272"
        transform="translate(155.5 125.667)"
      >
        <path
          id="Path_104"
          data-name="Path 104"
          d="M164.972,155.917h-8.285a1.189,1.189,0,0,1-1.187-1.187V142.855a1.189,1.189,0,0,1,1.188-1.187h14.25a1.189,1.189,0,0,1,1.188,1.188v4.75a.4.4,0,1,1-.792,0v-4.75a.4.4,0,0,0-.4-.4h-14.25a.4.4,0,0,0-.4.4V154.73a.4.4,0,0,0,.4.4h8.285a.4.4,0,1,1,0,.792Z"
          transform="translate(-155.5 -140.084)"
          fill="#285197"
        />
        <path
          id="Path_105"
          data-name="Path 105"
          d="M171.729,182.459H155.9a.4.4,0,0,1,0-.792h15.833a.4.4,0,0,1,0,.792Z"
          transform="translate(-155.5 -176.125)"
          fill="#285197"
        />
        <path
          id="Path_106"
          data-name="Path 106"
          d="M187.9,128.834a.4.4,0,0,1-.4-.4v-2.375a.4.4,0,0,1,.792,0v2.375A.4.4,0,0,1,187.9,128.834Z"
          transform="translate(-184.333 -125.667)"
          fill="#285197"
        />
        <path
          id="Path_107"
          data-name="Path 107"
          d="M283.9,128.834a.4.4,0,0,1-.4-.4v-2.375a.4.4,0,1,1,.792,0v2.375A.4.4,0,0,1,283.9,128.834Z"
          transform="translate(-270.833 -125.667)"
          fill="#285197"
        />
      </g>
      <path
        id="Path_108"
        data-name="Path 108"
        d="M263.854,222.375a4.354,4.354,0,1,1,4.354-4.354A4.359,4.359,0,0,1,263.854,222.375Zm0-7.917a3.562,3.562,0,1,0,3.562,3.562A3.566,3.566,0,0,0,263.854,214.459Z"
        transform="translate(-93.708 -79.292)"
        fill="#285197"
      />
      <path
        id="Path_109"
        data-name="Path 109"
        d="M299.9,232.834a.4.4,0,0,1-.4-.4v-2.375a.4.4,0,0,1,.792,0v2.375A.4.4,0,0,1,299.9,232.834Z"
        transform="translate(-129.75 -93.708)"
        fill="#285197"
      />
      <path
        id="Path_110"
        data-name="Path 110"
        d="M301.479,256.042a.4.4,0,0,1-.28-.116l-1.583-1.583a.4.4,0,0,1,.56-.56l1.583,1.583a.4.4,0,0,1-.28.676Z"
        transform="translate(-129.75 -115.333)"
        fill="#285197"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCalendar'
}
</script>

<style scoped></style>
