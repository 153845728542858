<template>
  <div class="comment-container">
    <img
      v-if="comment.creator.profile_photo"
      class="profile-avatar"
      :src="comment.creator.profile_photo"
      alt="profile-avatar"
    />
    <div v-else class="profile-avatar">
      <span class="text-white">
        {{ comment.creator.name.at(0) }}
        {{ comment.creator.last_name.at(0) }}
      </span>
    </div>
    <div class="flex-fill flex-container column-container">
      <p class="text-gray-2">
        <span class="text-primary">
          {{ comment.creator.name }}
          {{ comment.creator.last_name }}: </span
        >{{ comment.message }}
      </p>
      <p class="text-gray-2 info-text">
        {{ comment.creation_time | moment('from', 'now') }}
        <span class="delete-button" @click="deleteComment">Delete</span>
      </p>
    </div>
    <span class="action">
      <svg
        v-if="!comment.is_liked"
        class="icon"
        height="12"
        viewBox="0 0 24.243 20.448"
        width="12"
        xmlns="http://www.w3.org/2000/svg"
        @click="likeComment()"
      >
        <path
          class="path"
          d="M17.432,0c-2.078,0-4.509,2.356-5.811,3.874C10.32,2.356,7.889,0,5.811,0,2.132,0,0,2.869,0,6.522,0,12.59,11.622,19.369,11.622,19.369S23.243,12.59,23.243,6.779C23.243,3.127,21.111,0,17.432,0Z"
          fill="none"
          stroke="#c6c8d1"
          stroke-width="1"
          transform="translate(0.5 0.5)"
        />
      </svg>
      <svg
        v-else
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 18 15"
        @click="dislikeComment()"
      >
        <path
          id="Shape"
          d="M13.5,0C11.891,0,10.008,1.825,9,3,7.992,1.825,6.109,0,4.5,0,1.651,0,0,2.222,0,5.05,0,9.75,9,15,9,15s9-5.25,9-9.75C18,2.422,16.349,0,13.5,0Z"
          fill="#285197"
        />
      </svg>
    </span>
  </div>
</template>

<script>
import { MEMORIES_URL } from '../../utils/endpoints'
import { request } from '../../utils/interceptor'

export default {
  name: 'CommentItem',
  props: {
    comment: Object
  },
  data() {
    return {}
  },
  methods: {
    likeComment() {
      let url = `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
        this.memoryId
      }/comments/${this.comment.id}/like`
      request.post(url).then(() => (this.comment.is_liked = true))
    },
    dislikeComment() {
      let url = `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
        this.memoryId
      }/comments/${this.comment.id}/unlike`
      request.post(url).then(() => (this.comment.is_liked = false))
    },
    deleteComment() {
      request
        .delete(
          `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
            this.memoryId
          }/comments/${this.comment.id}`
        )
        .then(() =>
          this.$notify({
            group: 'storyline',
            title: 'Comment was deleted.'
          })
        )
        .catch(() =>
          this.$notify({
            group: 'storyline',
            title: 'You are not allowed to delete that comment.',
            type: 'error'
          })
        )
    }
  }
}
</script>

<style scoped>
.comment-container {
  padding: 24px 0;
  display: flex;
  flex-direction: row;
}

.profile-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
  margin-right: 8px;
}

.info-text {
  margin-top: 10px;
  font-size: 12px;
}

.delete-button {
  margin-left: 16px;
}
</style>
