import { render, staticRenderFns } from "./ObituaryView.vue?vue&type=template&id=0a1f7bed&scoped=true&"
import script from "./ObituaryView.vue?vue&type=script&lang=js&"
export * from "./ObituaryView.vue?vue&type=script&lang=js&"
import style0 from "./ObituaryView.vue?vue&type=style&index=0&id=0a1f7bed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a1f7bed",
  null
  
)

export default component.exports