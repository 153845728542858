<template>
  <div v-if="!approved" class="flex-container pending-item column-container">
    <div
      v-if="item.media.count > 0"
      class="flex-container row-container align-center"
    >
      <p
        v-if="item.media.count > 0"
        class="flex-fill align-center item-subheader"
      >
        <span class="text-primary">
          {{ item.creator.name }} {{ item.creator.last_name }}
        </span>
        posted {{ item.media.count }} photos:
      </p>
      <button class="approve-button" @click="approveMemory">
        Approve
      </button>
      <button class="reject-button" @click="rejectMemory">
        X
      </button>
    </div>
    <perfect-scrollbar
      class="image-scroller row-container"
      v-if="item.media.count > 0"
    >
      <img
        v-for="photo in item.media.data"
        :src="photo.paths.small.path"
        :key="photo.id"
        alt="photo-item"
        class="pending-image"
      />
    </perfect-scrollbar>
    <div
      v-if="!item.approved"
      class="flex-container row-container align-center"
    >
      <p class="flex-fill item-subheader">
        <span class="text-primary">
          {{ item.creator.name }} {{ item.creator.last_name }}
        </span>
        said:
      </p>
      <button class="approve-button" @click="approveMemory">
        Approve
      </button>
      <button class="reject-button" @click="rejectMemory">
        X
      </button>
    </div>
    <p class="text-primary item-body" v-if="!item.approved">{{ item.body }}</p>
    <!--    <div-->
    <!--      v-if="!item.approved"-->
    <!--      class="flex-container row-container align-center"-->
    <!--    >-->
    <!--      <p class="flex-fill item-subheader">-->
    <!--        <span class="text-primary">-->
    <!--          {{ item.creator.name }} {{ item.creator.last_name }}-->
    <!--        </span>-->
    <!--        commented on-->
    <!--        <span class="text-primary">{{ item.for_storyline.full_name }}'s</span>-->
    <!--        memory:-->
    <!--      </p>-->
    <!--      <button class="approve-button" @click="approveImage">-->
    <!--        Approve-->
    <!--      </button>-->
    <!--      <button class="reject-button" @click="rejectImage">-->
    <!--        X-->
    <!--      </button>-->
    <!--    </div>-->
    <!--    <p class="text-primary item-body" v-if="!item.approved">{{ item.body }}</p>-->
  </div>
</template>

<script>
import { request } from '../../utils/interceptor'

export default {
  name: 'EditorMemeoryItem',
  props: {
    storylineId: String,
    item: Object
  },
  methods: {
    approveMemory() {
      request
        .post(
          `/storylines/${this.storylineId}/memories/${this.item.id}/approve`
        )
        .then(() => (this.approved = true))
        .catch(e =>
          this.$notify({
            group: 'storyline',
            type: 'error',
            title: e.message
          })
        )
    },
    rejectMemory() {
      request
        .post(`/storylines/${this.storylineId}/memories/${this.item.id}/deny`)
        .then(() => (this.approved = true))
        .catch(e =>
          this.$notify({
            group: 'storyline',
            type: 'error',
            title: e.message
          })
        )
    }
  },
  data() {
    return {
      approved: false
    }
  }
}
</script>

<style scoped>
.pending-item {
  margin: 0 24px 16px 24px;
}

.pending-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
}

.image-scroller {
  padding: 18px 0 24px;
  border-bottom: solid 1px rgba(175, 175, 175, 0.55);
  margin-bottom: 24px;
}

.item-body {
  margin: 16px 0 24px 0;
  border-bottom: solid 1px rgba(175, 175, 175, 0.55);
  padding-bottom: 24px;
  padding-right: 64px;
}

.item-subheader {
  margin-right: 12px;
}

.approve-button {
  padding: 8px 12px;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  color: white;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
}

.reject-button {
  padding: 8px 12px;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  background: transparent;
}
</style>
