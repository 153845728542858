<template>
  <div class="memory-list">
    <memory-header
      :creator="memory.creator"
      :creatorRelationship="memory.creator_relationship"
      :memoryID="memory.id"
      :storyline="memory.for_storyline"
      :is-feed="isFeed"
    />
    <memory-media
      v-if="memory.media.data.length"
      :mediaList="memory.media.data"
    />
    <memory-text
      v-if="memory.body"
      :memoryID="memory.id"
      :memoryText="memory.body"
    />
    <memory-actions
      :commentCount="memory.count_comments"
      :creationDate="memory.creation_date"
      :likeCount="memory.count_likes"
      :memoryId="memory.id"
      :storylineId="memory.for_storyline.id"
      :isLiked="memory.is_liked"
    />
    <!--    <comment v-if="memory.first_comment" :firstComment="memory.first_comment" />-->
  </div>
</template>

<script>
import MemoryHeader from '@/components/Memory/MemoryHeader.vue'
import MemoryText from '@/components/Memory/MemoryText.vue'
import MemoryMedia from '@/components/Memory/MemoryMedia.vue'
import MemoryActions from '@/components/Memory/MemoryActions.vue'
// import Comment from '@/components/Memory/Comment.vue'

export default {
  props: {
    memory: Object,
    isFeed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MemoryHeader,
    MemoryText,
    MemoryMedia,
    MemoryActions
    // Comment
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
