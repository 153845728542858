import { request, setHttpAppToken } from '@/utils/interceptor'
import { CONFIRM_CODE_URL, ENV, USERS_URL } from '@/utils/endpoints'
import router from '@/router'

const state = {
  authToken: '',
  user: {}
}

const actions = {
  async confirmCode({ commit }, payload) {
    try {
      const { code, index, redirect } = payload
      const { data } = await request.post(CONFIRM_CODE_URL, {
        code,
        index,
        env: ENV
      })
      commit('setUserAuth', data)
      
      // Default path
      let route = { name: 'workthru' };

      // If redirect path was supplied, go there
      if (redirect != null) {
        route = { path: redirect };
      }

      await router.replace(route);
    } catch (e) {
      console.log(e)
      this.$notify({
        group: 'storyline',
        type: 'error',
        title: e.message
      })
    }
  },
  async completeWalkThrough({ commit, state }) {
    try {
      const { data } = await request.patch(`${USERS_URL}/${state.user.id}`, {
        completed_walk_through: true
      })
      console.log(data)
      commit('setUserInfo', data)
      await router.replace({ name: 'workthru' })
    } catch (e) {
      console.log(e)
    }
  },
  resetUserState({ commit }) {
    commit('resetState')
    router.replace({ name: 'auth' })
  }
}

const mutations = {
  setUserAuth: (state, status) => {
    console.log(state, status)
    state.authToken = status.token
    state.user = status.user
    setHttpAppToken(state.authToken)
  },
  setUserInfo: (state, status) => {
    state.user = status
  },
  setUser: (state, user) => {
    state.user = user
  },
  resetState: state => {
    state.authToken = ''
    state.user = {}
    setHttpAppToken(undefined)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
