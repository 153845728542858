<template>
  <div class="flex-container column-container">
    <editor-nav
      v-if="storylineId"
      title="Edit Storyline"
      :storyline-id="storylineId"
      show-tabs
    />
    <router-view></router-view>
  </div>
</template>

<script>
import EditorNav from '../Shared/EditorNav'
export default {
  name: 'EditorMangeWrap',
  components: { EditorNav },
  data() {
    return {
      storylineId: ''
    }
  },
  beforeMount() {
    this.storylineId = this.$route.params.storylineId
  }
}
</script>

<style scoped></style>
