<template>
  <div class="memories-wrap height-100">
    <Loader v-if="loading" />
    <StorylineFeed v-else-if="memoryList.length > 0" :memoryList="memoryList" />
    <div
      v-else
      class="height-100 flex-container column-container align-center justify-center empty-container"
    >
      <h3 class="text-primary">Share a Memory.</h3>
      <p class="empty-description text-center">
        <span v-if="isAuthed">
          Be one of the first to share a memory of {{ firstName }} by tapping on the plus
        icon in the lower right corner.
        </span>
        <span v-else>
          <a class="text-primary cursor-pointer" @click="goToSignup">Sign up</a> or <a class="text-primary cursor-pointer" @click="goToLogin">Sign in</a> to view and share memories of {{ firstName }}.
        </span>
      </p>
    </div>
    <button v-if="isAuthed" class="fab-position" @click="onAddNewMemory">
      <IconAdd />
    </button>
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>

<script>
import Loader from '@/components/Shared/Loader.vue'
import StorylineFeed from '@/components/Storyline/StorylineFeed.vue'
import { mapActions, mapState } from 'vuex'
import IconAdd from '@/assets/IconAdd'

export default {
  data() {
    return {
      loading: true
    }
  },
  components: {
    Loader,
    StorylineFeed,
    IconAdd
  },
  computed: {
    ...mapState('main', ['memoryList']),
    ...mapState('storyline', ['layout', 'firstName']),
    isAuthed() {
      return this.$store.state?.user?.authToken?.length > 0
    }
  },
  methods: {
    ...mapActions('main', ['fetchMemories']),
    onAddNewMemory() {
      this.$router.push({ name: 'new_memory' })
    },
    goToSignup() {
      this.$router.push({ name: 'signup'})
    },
    goToLogin() {
      this.$router.push({ name: 'auth', params: { redirect: this.$route.path} })
    }
  },
  async created() {
    const payload = {
      storyline: this.$route.params.storylineId
    }
    await this.fetchMemories(payload)
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.memories-wrap {
  padding: 0;
  display: contents;
}

.empty-container {
  margin: 0 18vw;
}

@media screen and (min-width: 675px) {
  .empty-container {
    margin: 0 120px;
  }
}

.empty-description {
  color: #22222280;
  margin-top: 32px;
}
</style>
