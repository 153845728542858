<template>
    <div class="flex-fill contents-box column-container">
        <div v-if="mode === 'add' || mode === 'edit'" id="editor" class="width-100 flex-fill">
            <h2 style="text-transform: capitalize">{{ mode }} Link</h2>
            <input
                id="link-title"
                v-model="link.title"
                class="width-100 form-input"
                placeholder="Title"
                type="text"
            />
            <p class="text-error" v-if="errors.title">{{ errors.title.message }}</p>
            <textarea
                class="flex-fill edit-text text-primary"
                v-model="link.link_url"
                placeholder="URL"
            ></textarea>
            <p class="text-error" v-if="errors.link_url">{{ errors.link_url.message }}</p>
            <button class="primary-button" @click="createLink" :disabled="submitted">
                <span v-if="submitted">LOADING...</span>
                <span v-else>ADD</span>
            </button>
            <button class="text-button" @click="setMode('view')">CANCEL</button>
        </div>
        <div v-else-if="storyline.links" class="flex-fill width-100 scroll-box">
            <perfect-scrollbar class="p-scroll">
                <div v-for="link in storyline.links.data"
                    :key="link.id"
                    id="link"
                    class="flex-container"
                >
                    <div class="column-container width-100">
                        <h3>{{ link.title }}</h3>
                        <a :href="link.link_url" target="_blank" class="link-url">{{ link.link_url }}</a>
                    </div>
                    <button class="primary-button link-action" @click="deleteLink(link)"><IconClose :size="12"/></button>

                </div>
                
            </perfect-scrollbar>
        </div>
        <button v-if="mode === 'view'" class="fab-position" @click="setMode('add')">
            <icon-add />
        </button>
    </div>
  </template>
  
  <script>
  import { request } from '../../utils/interceptor'
  import IconAdd from '../../assets/IconAdd'
  import IconClose from '../../assets/IconClose'
  
  export default {
    name: 'EditorLinks',
    components: { IconAdd, IconClose },
    data() {
      return {
        storylineId: '',
        storyline: {},
        mode: 'view',
        link: {},
        submitted: false,
        errors: {}
      }
    },
    beforeMount() {
      this.refresh()
    },
    methods: {
        refresh() {
            this.storylineId = this.$route.params.storylineId
            request
                .get(`/storylines/${this.storylineId}`)
                .then(e => (this.storyline = e.data))
        },
        setMode(mode) {
            if (mode == 'add') {
                this.link = {}
            }
            this.mode = mode
        },
        editLink(link) {
            this.link = link
            this.setMode('edit')
        },
        createLink() {
            this.submitted = true;

            let url = `/storylines/${this.storyline.id}/links`
            request.post(url, this.link)
            .then((response) => {
                this.submitted = false;
                if (response.status == 200) {
                    this.$notify({
                        group: 'storyline',
                        title: 'Link created!'
                    })
                    this.refresh()
                    this.setMode('view')
                } else {
                    this.$notify({
                        group: 'storyline',
                        title: 'Failed to create link! Try again.',
                        type: 'error'
                    })
                }
            })
            .catch((error) => {
                this.submitted = false;
                this.errors = error.response.data.errors ?? {};
                this.$notify({
                  group: 'storyline',
                  title: 'Failed to create link!',
                  type: 'error'
                })
            });
        },
        deleteLink(link) {
            if(confirm(`Are you sure you want to delete link '${link.title}'`)) {
                let url = `/storylines/${this.storyline.id}/links/${link.id}`
                request.delete(url, this.link)
                .then((response) => {
                    if (response.status == 200) {
                        this.$notify({
                            group: 'storyline',
                            title: 'Link deleted!'
                        })
                        this.refresh();
                    } else {
                        this.$notify({
                            group: 'storyline',
                            title: 'Failed to delete link! Try again.',
                            type: 'error'
                        })
                    }
                })
                .catch(() => {
                    this.$notify({
                        group: 'storyline',
                        title: 'Failed to delete link!',
                        type: 'error'
                    })
                });
            }
        }
    }
  }
  </script>
  
  <style scoped>
  .place-title {
    margin: 50px 0 36px;
  }
  
  .contents-box {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .body-text {
    font-size: 18px;
    padding: 22px 30px;
  }
  
  .scroll-box {
    position: relative;
  }
  
  .save-btn {
    width: calc(100vw - 48px);
    margin: 24px;
  }
  
  @media screen and (min-width: 675px) {
    .save-btn {
      width: 627px;
    }
  }
  
  .p-scroll {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #editor {
    padding: 10px;
  }

  #editor button {
    margin-top: 10px;
  }

  #link-title {
    margin-top: 16px;
  }

  .edit-text {
    background: white;
    border: 1px solid #5E7FB1;
    border-radius: 12px;
    padding: 10px 18px;
    font-size: 14px;
    width: 100%;
    height: 150px;
    margin-top: 12px;
  }

  #link {
    padding: 20px 10px;
    border-bottom: 1px solid #eaeaea;
  }

  .link-url, .link-url:visited {
    text-decoration: underline;
    color: inherit;
  }
  

  .link-action {
    width: 42px;
    height: 42px;
    margin-left: 10px;
  }

  </style>
  