<template>
  <div v-if="showCompanyLogo && company.logo" id="company-logo-container">
    <div class="company-logo-flex">
      <div>
        <img :src="company.logo" />
      </div>
    </div>
  </div>
  <div v-else class="flex-container column-container">
    <StorylineHeader />
    <Loader v-if="loading" />
    <div v-else-if="privacyType !== 'PRIVATE'" class="storyline-wrap">
      <transition :name="transitionName">
        <router-view class="storyline-view"></router-view>
      </transition>
    </div>
    <NotAuthorized v-else />
  </div>
</template>

<style scoped lang="scss">
#company-logo-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .company-logo-flex {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    div {
      img {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}
</style>

<script>
import Loader from '@/components/Shared/Loader.vue'
import StorylineHeader from '@/components/Storyline/StorylineHeader.vue'
import NotAuthorized from '@/components/Shared/NotAuthorized.vue'
import { mapActions, mapState } from 'vuex'
// import IconAdd from '@/assets/IconAdd'

export default {
  // If directly accessed, show company logo
  beforeRouteEnter (to, from, next) {
    if (from.name === null) {
      to.meta.showCompanyLogo = true;
    }
    next()
  },
  metaInfo() {
    return {
      title: `${this.firstName}'s Storyline`
    }
  },
  data() {
    return {
      transitionName: 'slide-left',
      showCompanyLogo: false,
      loading: true
    }
  },
  // On creation, set showCompanyLogo true, and revert after 2 seconds
  created () {
    if (this.$route.meta.showCompanyLogo) {
      this.showCompanyLogo = true
      setTimeout(() => {
        this.showCompanyLogo = false
      }, 2000)
    }
    this.refresh();
  },
  components: {
    // IconAdd,
    Loader,
    StorylineHeader,
    NotAuthorized
  },
  computed: {
    ...mapState('storyline', ['privacyType', 'firstName', 'company'])
  },
  methods: {
    ...mapActions('storyline', ['fetchStoryline']),
    loadStoryline(storylineId) {
      this.fetchStoryline(storylineId)
    },
    async refresh() {
      await this.loadStoryline(this.$route.params.storylineId)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.storyline-wrap {
  background: var(--white);
  flex: 1;
  display: contents;
}

.storyline-view {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
</style>
