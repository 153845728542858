const state = {}

const actions = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
