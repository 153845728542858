<template>
  <div v-if="id" class="display-wrap">
    <div class="display-option" @click="changeLayout('full')">
      <IconFull :layout="layout" />
    </div>
    <div class="display-option" @click="changeLayout('grid')">
      <IconGrid :layout="layout" />
    </div>
    <div class="display-option" @click="changeLayout('list')">
      <IconList :layout="layout" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import IconList from '@/assets/IconList'
import IconGrid from '@/assets/IconGrid'
import IconFull from '@/assets/IconFull'

export default {
  data() {
    return {}
  },
  components: { IconFull, IconGrid, IconList },
  computed: {
    ...mapState('storyline', ['layout', 'showStats', 'id', 'condolenceCount'])
  },
  methods: {
    ...mapActions('storyline', ['updateLayout']),
    changeLayout(layout) {
      this.updateLayout(layout)
    }
  }
}
</script>

<style lang="scss" scoped>
.display-wrap {
  background-color: var(--color-3);
  display: flex;
  justify-content: space-around;

  .display-option {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 60px;

    .icon {
      &:hover,
      &.is-active {
        .path {
          fill: #50badd;
        }
      }

      .path {
        fill: var(--white);
      }
    }
  }
}
</style>
