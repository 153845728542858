<template>
  <svg
    class="icon"
    height="21.596"
    viewBox="0 0 32.54 21.596"
    width="32.54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_18"
      :fill="layout === 'list' ? '#50badd' : 'white'"
      d="M4383.2,1751.773h-20.988a1.134,1.134,0,0,1,0-2.232H4383.2a1.134,1.134,0,0,1,0,2.232"
      transform="translate(-4361.284 -1730.69)"
    />
    <path
      id="Path_19"
      :fill="layout === 'list' ? '#50badd' : 'white'"
      d="M4383.2,1741.31h-20.988a1.134,1.134,0,0,1,0-2.232H4383.2a1.134,1.134,0,0,1,0,2.232"
      transform="translate(-4361.284 -1729.38)"
    />
    <path
      id="Path_20"
      :fill="layout === 'list' ? '#50badd' : 'white'"
      d="M4383.2,1730.846h-20.988a1.134,1.134,0,0,1,0-2.232H4383.2a1.134,1.134,0,0,1,0,2.232"
      transform="translate(-4361.284 -1728.069)"
    />
    <path
      id="Path_21"
      :fill="layout === 'list' ? '#50badd' : 'white'"
      d="M4391.874,1729.781a1.662,1.662,0,1,0,1.662-1.661A1.661,1.661,0,0,0,4391.874,1729.781Z"
      transform="translate(-4362.658 -1728.12)"
    />
    <path
      id="Path_22"
      :fill="layout === 'list' ? '#50badd' : 'white'"
      d="M4391.874,1740.189a1.662,1.662,0,1,0,1.662-1.66A1.661,1.661,0,0,0,4391.874,1740.189Z"
      transform="translate(-4362.658 -1729.436)"
    />
    <path
      id="Path_23"
      :fill="layout === 'list' ? '#50badd' : 'white'"
      d="M4391.874,1750.68a1.662,1.662,0,1,0,1.662-1.662A1.661,1.661,0,0,0,4391.874,1750.68Z"
      transform="translate(-4362.658 -1730.744)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconList',
  props: {
    layout: String
  }
}
</script>

<style scoped></style>
