<template>
  <div class="main-container">
    <router-view
      v-if="isAuthed"
      class="body-container flex-container column-container"
    ></router-view>
    <router-view
      v-else
      class="auth-body-container flex-container column-container"
    ></router-view>
    <bottom-nav v-if="isAuthed" />
  </div>
</template>

<script>
import BottomNav from '@/components/Shared/BottomNav'

export default {
  name: 'Main',
  components: { BottomNav },
  computed: {
    isAuthed() {
      return this.$store.state?.user?.authToken?.length > 0
    }
  }
}
</script>

<style scoped>
.main-container {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.body-container {
  height: calc(var(--vh, 1vh) * 100 - 48px);
  width: 100%;
  position: absolute;
}

.auth-body-container {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: absolute;
}
</style>
