export const ENV = 'production'

export const CHECK_CREDENTIAL_URL = '/authentication/request'
export const CONFIRM_CODE_URL = '/authentication/confirm'
export const USERS_URL = '/users'
export const SEARCH_URL = '/storylines?query='
export const MEMORIES_URL = '/storylines/{id}/memories'
export const LIFE_CATEGORY_URL = '/storylines/{id}/lifecategories'
export const RELATIONSHIP_CATEGORY_URL = '/storylines/{id}/relationships'
export const FOLLOW_STORYLINE_URL = '/storylines/{id}/follow'
export const UNFOLLOW_STORYLINE_URL = '/storylines/{id}/unfollow'
