<template>
  <div class="flex-container column-container">
    <top-nav :title="title" show-back />
    <loader v-if="isLoading" class="flex-fill" />
    <perfect-scrollbar class="flex-fill" v-else>
      <vue-slick-carousel
        v-if="images.length > 0"
        :arrows="false"
        :dots="true"
        :infinite="false"
      >
        <div class="img-wrap" v-for="image in images" :key="image.id">
          <img
            class="width-100"
            :src="image.paths.large.path"
            alt="post-image"
          />
        </div>
      </vue-slick-carousel>
      <memory-actions
        :comment-count="memory.count_comments"
        :like-count="memory.count_likes"
        :creation-date="memory.creation_date"
        :memory-id="memory.id"
        :storyline-id="storylineId"
        :is-liked="memory.is_liked"
      />
      <p v-if="!memory.approved" class="notify-message">
        Memory is pending approval by Storyeditor(s)
      </p>
      <div v-if="creator.name" class="flex-container row-container creator-content">
        <img
          v-if="creator.profile_photo"
          :src="memory.creator.profile_photo"
          class="creator-avatar"
          alt="creator-avatar"
        />
        <div v-else class="creator-avatar">
          <span class="text-white">
            {{ creator.name.charAt(0) }}
            {{ creator.last_name.charAt(0) }}
          </span>
        </div>
        <div class="flex-fill flex-container creator-info column-container">
          <p class="text-primary bold">{{ creator.name }}</p>
          <p class="relation">{{ memory.creator_relationship.name }}</p>
        </div>
      </div>
      <p class="creator-content">{{ memory.body }}</p>
      <div class="created-date-container flex-container row-container">
        <icon-calendar />
        <p class="created-date">
          {{ memory.creation_date | moment('MMMM YYYY') }}
        </p>
      </div>
      <div v-if="memory.count_comments > 0" class="comments-container">
        <router-link
          class="text-gray"
          :to="{ name: 'memory_comments', params: { storylineId, memoryId } }"
          >View all {{ memory.count_comments }} comments</router-link
        >
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { MEMORIES_URL } from '@/utils/endpoints'
import { request } from '@/utils/interceptor'
import TopNav from '@/components/Shared/TopNav'
import MemoryActions from './MemoryActions'
import IconCalendar from '../../assets/IconCalendar'
import Loader from '../Shared/Loader'

export default {
  metaInfo() {
    if (this.$store)
      return {
        title: `${this.$store?.state?.storyline?.firstName}'s Storyline`
      }
    else
      return {
        title: 'Details | Storyline'
      }
  },
  name: 'PreviewMemory',
  components: {
    Loader,
    IconCalendar,
    MemoryActions,
    TopNav,
    VueSlickCarousel
  },
  data() {
    return {
      memory: {},
      memoryId: '',
      storylineId: '',
      isLoading: false
    }
  },
  mounted() {
    this.memoryId = this.$route.params.memoryId
    this.storylineId = this.$route.params.storylineId
    this.isLoading = true
    request
      .get(`${MEMORIES_URL.replace('{id}', this.storylineId)}/${this.memoryId}`)
      .then(e => {
        this.memory = e.data
        this.isLoading = false
      })
      .catch(() => (this.isLoading = false))
  },
  computed: {
    title() {
      return `${this.$store.state.storyline.firstName}'s Storyline`
    },
    images() {
      return this.memory.media?.data ?? []
    },
    creator() {
      return this.memory?.creator ?? { last_name: '', name: '' }
    }
  }
}
</script>

<style scoped>
.creator-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-2);
  justify-content: center;
  align-items: center;
  display: flex;
}

.creator-content {
  margin: 10px 24px;
}

.creator-info {
  margin-left: 10px;
}

.relation {
  font-size: 12px;
  color: var(--gray-2);
  margin-top: 2px;
}

.created-date {
  color: #22222280;
  margin: 0 8px;
  font-size: 12px;
}

.created-date-container {
  padding: 4px 24px 24px;
}

.comments-container {
  border-top: 1px solid #afafaf;
  padding: 28px 24px;
}
</style>
