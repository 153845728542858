<template>
  <div class="flex-fill contents-box column-container">
    <textarea
      class="flex-fill edit-text text-primary"
      v-if="isEdit"
      v-model="bodyText"
    />
    <div
      v-else-if="!storyline.obituary"
      class="flex-container column-container align-center"
    >
      <icon-add color="#C6D2E3" :size="52" />
      <h2 class="text-primary place-title">Add obituary details</h2>
      <p class="text-gray-2">Tap on the plus icon</p>
      <p class="text-gray-2">to get started.</p>
    </div>
    <div v-else class="flex-fill width-100 scroll-box">
      <perfect-scrollbar class="p-scroll">
        <p class="body-text text-primary">{{ storyline.obituary }}</p>
      </perfect-scrollbar>
    </div>
    <button v-if="!isEdit" class="fab-position" @click="onEdit">
      <icon-edit v-if="storyline.obituary" />
      <icon-add v-else />
    </button>
    <button v-else class="primary-button save-btn" @click="onSave">Save</button>
  </div>
</template>

<script>
import { request } from '../../utils/interceptor'
import IconEdit from '../../assets/IconEdit'
import IconAdd from '../../assets/IconAdd'

export default {
  name: 'EditorObituary',
  components: { IconAdd, IconEdit },
  data() {
    return {
      storylineId: '',
      storyline: {},
      isEdit: false,
      bodyText: ''
    }
  },
  beforeMount() {
    this.storylineId = this.$route.params.storylineId
    request
      .get(`/storylines/${this.storylineId}`)
      .then(e => (this.storyline = e.data))
  },
  methods: {
    onSave() {
      request
        .patch(`/storylines/${this.storylineId}`, { obituary: this.bodyText })
        .then(() => (this.storyline.obituary = this.bodyText))
        .catch(e =>
          this.$notify({
            group: 'storyline',
            type: 'error',
            title: e.message
          })
        )
      this.isEdit = false
    },
    onEdit() {
      this.bodyText = this.storyline.obituary
      this.isEdit = true
    }
  }
}
</script>

<style scoped>
.place-title {
  margin: 50px 0 36px;
}

.contents-box {
  justify-content: center;
  align-items: center;
  display: flex;
}

.body-text {
  font-size: 18px;
  padding: 22px 30px;
}

.scroll-box {
  position: relative;
}

.save-btn {
  width: calc(100vw - 48px);
  margin: 24px;
}

@media screen and (min-width: 675px) {
  .save-btn {
    width: 627px;
  }
}

.p-scroll {
  position: absolute;
  width: 100%;
  height: 100%;
}

.edit-text {
  font-size: 18px;
  padding: 12px;
  margin: 10px 18px;
  width: calc(100% - 48px);
  border-width: 0;
}
</style>
