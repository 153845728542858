<template>
  <div :class="layout === 'list' ? 'has-padding' : ''" class="media-wrap">
    <vue-slick-carousel
      :arrows="false"
      :dots="true"
      :infinite="false"
      :variableWidth="layout === 'list'"
      :swipe="mediaList.length > 1"
    >
      <div
        v-for="(media, index) in mediaList"
        :key="index"
        :class="layout === 'list' ? 'is-list' : ''"
        class="img-wrap"
      >
        <inner-image-zoom 
          :class="layout === 'list' ? 'has-radius' : ''"
          :src="media.paths.large.path"
          :zoomSrc="media.paths.xlarge.path"
          :fullscreenOnMobile="true"
          :hideHint="true"
        />
        <img
          v-if="media.video"
          alt="icon-video"
          class="icon-video"
          src="/img/icon-video.svg"
          @click="playVideo"
        />
        <video
          v-if="media.video"
          :class="layout === 'list' ? 'has-radius' : ''"
          class="video-container"
          controls
        >
          <source :src="media.video" type="video/mp4" />
        </video>
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { mapState } from 'vuex'

// Inner Image Zoom Library
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

export default {
  props: {
    mediaList: Array
  },
  components: {
    VueSlickCarousel,
    InnerImageZoom
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('storyline', ['layout'])
  }
}
</script>

<style lang="scss" scoped>
.media-wrap {
  margin-bottom: 30px;

  &.has-padding {
    padding-left: 30px;
    @media screen and (max-width: 650px) {
      padding-left: 20px;
    }
  }

  .img-wrap {
    position: relative;

    &.is-list {
      max-width: 300px;
      padding-right: 25px;
    }

    &.play-video {
      .icon-video {
        opacity: 0;
        transition: opacity 250ms;
      }

      .video-container {
        opacity: 1;
        transition: opacity 250ms;
      }
    }

    .img {
      display: block;
      width: 100%;

      &.has-radius {
        border-radius: 20px;
      }
    }

    .icon-video {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      z-index: 1;
    }
  }

  .video-container {
    background: var(--black);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    &.has-radius {
      border-radius: 20px;
    }
  }
}
</style>
