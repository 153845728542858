const state = {
  isVisible: true
}

const actions = {
  updateIsVisible({ commit }, payload) {
    commit('setIsVisible', payload)
  }
}

const mutations = {
  setIsVisible: (state, visible) => {
    state.isVisible = visible
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
