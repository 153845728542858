import { request } from '@/utils/interceptor'

const state = {
  layout: 'full',
  showStats: false,
  id: '',
  birthDate: '',
  birthLocation: {},
  deathDate: '',
  deathLocation: {},
  facebookLink: '',
  firstName: '',
  genderType: '',
  instagramLink: '',
  lastName: '',
  middleName: '',
  obituary: '',
  privacyType: '',
  profilePhoto: '',
  shareVisible: false,
  url: '',
  isFollowApproved: false,
  isFollowing: false,
  isManaging: false,
  links: [],
  company: {}
}

const actions = {
  async fetchStoryline({ dispatch }, payload) {
    try {
      const { data } = await request.get(`/storylines/${payload}`)

      dispatch('updatePrivacyType', data?.privacy ?? '')
      dispatch('updateBirthDate', data?.birth_date ?? '')
      dispatch('updateBirthLocation', data?.birth_location ?? {})
      dispatch('updateDeathDate', data?.death_date ?? '')
      dispatch('updateDeathLocation', data?.death_location?? {})
      dispatch('updateFacebookLink', data?.facebook_link ?? '')
      dispatch('updateFirstName', data?.first_name ?? '')
      dispatch('updateGenderType', data?.gender_type ?? '')
      dispatch('updateId', data?.id ?? '')
      dispatch('updateInstagramLink', data?.instagram_link ?? '')
      dispatch('updateLastName', data?.last_name ?? '')
      dispatch('updateMiddleName', data?.middle_name ?? '')
      dispatch('updateObituary', data?.obituary ?? '')
      // if (data?.profile_photo)
      dispatch('updateProfilePhoto', data?.profile_photo ?? '')
      dispatch('updateUrl', data?.url ?? '')
      dispatch('updateIsFollowApproved', data?.user_follow_approved ?? false)
      dispatch('updateIsFollowing', data?.user_follows ?? false)
      dispatch('updateIsManaging', data?.user_manages ?? false)
      dispatch('updateLinks', data?.links?.data ?? [])
      dispatch('updateCompany', data?.company ?? {})
    } catch (error) {
      console.log(error)
    }
  },
  updateLayout: ({ commit }, payload) => {
    commit('setLayout', payload)
  },
  updateShowStats: ({ commit }, payload) => {
    commit('setShowStats', payload)
  },
  updateId: ({ commit }, payload) => {
    commit('setId', payload)
  },
  updateBirthDate: ({ commit }, payload) => {
    commit('setBirthDate', payload)
  },
  updateBirthLocation: ({ commit }, payload) => {
    commit('setBirthLocation', payload)
  },
  updateDeathDate: ({ commit }, payload) => {
    commit('setDeathDate', payload)
  },
  updateDeathLocation: ({ commit }, payload) => {
    commit('setDeathLocation', payload)
  },
  updateFacebookLink: ({ commit }, payload) => {
    commit('setFacebookLink', payload)
  },
  updateFirstName: ({ commit }, payload) => {
    commit('setFirstName', payload)
  },
  updateGenderType: ({ commit }, payload) => {
    commit('setGenderType', payload)
  },
  updateInstagramLink: ({ commit }, payload) => {
    commit('setInstagramLink', payload)
  },
  updateLastName: ({ commit }, payload) => {
    commit('setLastName', payload)
  },
  updateMiddleName: ({ commit }, payload) => {
    commit('setMiddleName', payload)
  },
  updateObituary: ({ commit }, payload) => {
    commit('setObituary', payload)
  },
  updatePrivacyType: ({ commit }, payload) => {
    commit('setPrivacyType', payload)
  },
  updateProfilePhoto: ({ commit }, payload) => {
    commit('setProfilePhoto', payload)
  },
  updateUrl: ({ commit }, payload) => {
    commit('setUrl', payload)
  },
  updateShareVisible: ({ commit }, payload) => {
    commit('setShareVisible', payload)
  },
  updateIsFollowApproved: ({ commit }, payload) => {
    commit('setIsFollowApproved', payload)
  },
  updateIsFollowing: ({ commit }, payload) => {
    commit('setIsFollowing', payload)
  },
  updateIsManaging: ({ commit }, payload) => {
    commit('setIsManaging', payload)
  },
  updateLinks: ({ commit }, payload) => {
    commit('setLinks', payload)
  },
  updateCompany: ({ commit }, payload) => {
    commit('setCompany', payload)
  }
}

const mutations = {
  setLayout: (state, layout) => {
    state.layout = layout
  },
  setShowStats: (state, payload) => {
    state.showStats = payload
  },
  setId: (state, id) => {
    state.id = id
  },
  setBirthDate: (state, birthdate) => {
    state.birthDate = birthdate
  },
  setBirthLocation: (state, birthLocation) => {
    state.birthLocation = birthLocation
  },
  setDeathDate: (state, deathDate) => {
    state.deathDate = deathDate
  },
  setDeathLocation: (state, deathLocation) => {
    state.deathLocation = deathLocation
  },
  setFacebookLink: (state, facebookLink) => {
    state.facebookLink = facebookLink
  },
  setFirstName: (state, firstName) => {
    state.firstName = firstName
  },
  setGenderType: (state, genderType) => {
    state.genderType = genderType
  },
  setInstagramLink: (state, instagramLink) => {
    state.instagramLink = instagramLink
  },
  setLastName: (state, lastName) => {
    state.lastName = lastName
  },
  setMiddleName: (state, middleName) => {
    state.middleName = middleName
  },
  setObituary: (state, obituary) => {
    state.obituary = obituary
  },
  setPrivacyType: (state, privacyType) => {
    state.privacyType = privacyType
  },
  setProfilePhoto: (state, profilePhoto) => {
    state.profilePhoto = profilePhoto
  },
  setUrl: (state, url) => {
    state.url = url
  },
  setShareVisible: (state, value) => {
    state.shareVisible = value
  },
  setIsFollowApproved: (state, value) => {
    state.isFollowApproved = value
  },
  setIsFollowing: (state, value) => {
    state.isFollowing = value
  },
  setIsManaging: (state, value) => {
    state.isManaging = value
  },
  setLinks: (state, value) => {
    state.links = value
  },
  setCompany: (state, value) => {
    state.company = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
