<template>
  <svg
    :height="size"
    :width="size"
    viewBox="0 0 16.627 16.909"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Group_218"
      data-name="Group 218"
      opacity="0.7"
      transform="translate(15846 11765.903)"
    >
      <g
        id="Group_8"
        data-name="Group 8"
        transform="translate(-15846 -11765.903)"
      >
        <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
          <path
            id="Path_8"
            :fill="color"
            d="M4383.353,623.623a7.255,7.255,0,1,1,7.257-7.255,7.263,7.263,0,0,1-7.257,7.255Zm0-13.055a5.8,5.8,0,1,0,5.8,5.8,5.81,5.81,0,0,0-5.8-5.8Z"
            data-name="Path 8"
            transform="translate(-4376.098 -609.113)"
          />
        </g>
        <g id="Group_7" data-name="Group 7" transform="translate(11.12 11.401)">
          <path
            id="Path_9"
            :fill="color"
            d="M4400.726,634.972a.725.725,0,0,1-.515-.213l-4.053-4.052a.728.728,0,0,1,1.029-1.029l4.053,4.052a.728.728,0,0,1-.514,1.243Z"
            data-name="Path 9"
            transform="translate(-4395.945 -629.464)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSearch',
  props: {
    color: {
      type: String,
      default: '#50badd'
    },
    size: {
      type: Number,
      default: 17
    }
  }
}
</script>

<style scoped></style>
