<template>
  <svg
    :height="size"
    viewBox="0 0 16.651 16.667"
    :width="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="create-new-playlist-icon"
      d="M1227.136,4805.837h-6.26v-6.267a1.2,1.2,0,0,0-2.324,0v6.267h-6.26a1.2,1.2,0,0,0,0,2.325h6.26v6.266a1.2,1.2,0,0,0,2.324,0v-6.266h6.26a1.2,1.2,0,0,0,0-2.325Z"
      :fill="color"
      transform="translate(-1211.389 -4798.667)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAdd',
  props: {
    size: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style scoped></style>
