<template>
  <div class="container">
    <perfect-scrollbar class="mobile-container">
      <div class="column-container">
        <div class="story-image">
          <img
            alt="storyline1"
            class="storyline-logo"
            src="../../assets/storyline-logo-white.svg"
          />
        </div>
        <h3 class="text-center widget-container text-primary">
          Confirm Login Code
        </h3>
        <div class="input-wrap">
          <input
            v-model="code"
            :placeholder="'Code from ' + message"
            class="form-input"
            type="tel"
          />
        </div>
        <div class="widget-container">
          <button class="primary-button" v-on:click="login">
            Confirm Login Code
          </button>
        </div>
        <h4 class="text-center widget-container text-primary">
          Check your {{ message }}s > enter the code
        </h4>
        <a
          v-if="!resend"
          class="text-center bottom-link text-gray"
          v-on:click="resentCode"
        >
          Didn’t get a {{ message }}?
        </a>
        <a v-else class="text-center bottom-link text-gray">Sent!</a>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
// import { request } from '@/utils/interceptor'
// import { CHECK_CREDENTIAL_URL } from '@/utils/endpoints'

import router from '@/router'
import { request } from '@/utils/interceptor'
import { CHECK_CREDENTIAL_URL, ENV } from '@/utils/endpoints'

export default {
  metaInfo: { title: 'Storyline' },
  name: 'SignIn',
  data() {
    return {
      code: '',
      method: null,
      index: null,
      credential: null,
      message: null,
      resend: false,
      redirect: null
    }
  },
  mounted() {
    this.method = this.$route.params.method
    this.index = this.$route.params.index
    this.credential = this.$route.params.credential
    this.redirect = this.$route.params.redirect

    if (this.method === 'phone' || this.method === 'email and phone') {
      this.message = 'text message'
    } else if (this.method === 'email') {
      this.message = 'code'
    } else {
      router.back()
    }
  },
  methods: {
    async login() {
      const code = this.code.replaceAll('-', '').trim()
      if (code.length !== 6) {
        this.$notify({
          group: 'storyline',
          type: 'error',
          title: 'Please input your 6 digit confirm code.'
        })
        return
      }
      await this.$store.dispatch('user/confirmCode', {
        code,
        index: this.index,
        redirect: this.redirect
      })
    },
    async resentCode() {
      try {
        const {
          data: { index }
        } = await request.post(CHECK_CREDENTIAL_URL, {
          value: this.credential.trim(),
          env: ENV
        })
        this.resend = true
        this.index = index
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>
.story-image {
  width: 100%;
  height: 183px;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  justify-content: center;
  align-content: center;
}

.storyline-logo {
  width: 50%;
}

@media screen and (max-width: 675px) {
  .story-image {
    height: 27vw;
  }
}

.input-wrap {
  margin: 0 30px;
}

.widget-container {
  margin: 24px 30px;
}

.bottom-link {
  margin: 0 30px 24px 30px;
}
</style>
