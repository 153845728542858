<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36.93 21.07"
  >
    <g
      id="Group_369"
      data-name="Group 369"
      transform="translate(-40.701 -245.279)"
    >
      <path
        id="Path_64"
        data-name="Path 64"
        d="M1709.359,559.9c-5.666-6.274-11.287-9.148-18.245-9.317-6.673.216-12.73,3.341-18.006,9.287a2.32,2.32,0,0,0-.013,2.463c5.463,6.1,11.694,9.32,18.024,9.32h0c6.3,0,12.556-3.139,18.09-9.076A2.526,2.526,0,0,0,1709.359,559.9Zm-18.187,9.461c-5.824,0-11.482-2.949-15.961-8.309,4.824-5.374,10.393-8.213,16.121-8.213,5.657,0,11.157,2.84,15.923,8.216C1702.792,566.412,1697.09,569.359,1691.172,569.359Z"
        transform="translate(-1632.047 -305.302)"
        :fill="color"
        fill-rule="evenodd"
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M1694.666,556.87h0a5.549,5.549,0,0,0-5.5,5.4A5.465,5.465,0,0,0,1694.6,568h.1a5.564,5.564,0,1,0-.033-11.128Zm.031,8.8a3.419,3.419,0,0,1-2.4-1.008,3.159,3.159,0,0,1-.96-2.3,3.413,3.413,0,0,1,3.3-3.208l.023-.339v.339a3.494,3.494,0,0,1,2.424,1.076,3.191,3.191,0,0,1,1,2.271A3.492,3.492,0,0,1,1694.7,565.669Z"
        transform="translate(-1635.49 -306.62)"
        :fill="color"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPublic',
  props: {
    color: {
      type: String,
      default: '#8d90a3'
    }
  }
}
</script>

<style scoped></style>
