import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupHttpConfig } from '@/utils/interceptor'
import VueSocialSharing from 'vue-social-sharing'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import EasySlider from 'vue-easy-slider'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'v-dropdown-menu/dist/v-dropdown-menu.css'
import '@/assets/global.css'
import VueMoment from 'vue-moment'
import QrcodeVue from 'qrcode.vue'
import VueQrcodeReader from 'vue-qrcode-reader'
import DropdownMenu from 'v-dropdown-menu'

Vue.config.productionTip = false
Vue.use(VueSocialSharing)
Vue.use(PerfectScrollbar)
Vue.use(Notifications)
Vue.use(VueQrcodeReader)
Vue.use(Vuelidate)
Vue.use(EasySlider)
Vue.use(VueMoment)
Vue.use(QrcodeVue)
Vue.use(DropdownMenu)

setupHttpConfig()
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
