<template>
  <div class="flex-container column-container">
    <top-nav title="Comments" show-back />
    <loader v-if="isLoading" />
    <perfect-scrollbar v-else class="scroll-container">
      <p class="text-color-1">
        {{ creator.name }} {{ creator.last_name }} shared a memory:
      </p>
      <p class="post-body">{{ memory.body }}</p>
      <comment-item v-for="item in comments" :comment="item" :key="item.id" />
    </perfect-scrollbar>
    <div class="input-container">
      <img
        v-if="profilePhoto"
        class="profile-avatar"
        :src="profilePhoto"
        alt="profile-avatar"
      />
      <div v-else class="default-avatar">
        <span class="initials">
          {{ this.$store.state.user.user.name.charAt(0) }}
          {{ this.$store.state.user.user.last_name.charAt(0) }}
        </span>
      </div>
      <input class="form-input flex-fill" v-model="message" />
      <p class="text-primary post-button" @click="postComment">POST</p>
    </div>
  </div>
</template>

<script>
import TopNav from '../Shared/TopNav'
import { request } from '../../utils/interceptor'
import { MEMORIES_URL } from '../../utils/endpoints'
import Loader from '../Shared/Loader'
import CommentItem from './CommentItem'
export default {
  name: 'MemoryComments',
  components: { CommentItem, Loader, TopNav },
  data() {
    return {
      storylineId: '',
      memoryId: '',
      memory: {},
      comments: [],
      hasMore: false,
      isLoading: false,
      message: ''
    }
  },
  mounted() {
    this.memoryId = this.$route.params.memoryId
    this.storylineId = this.$route.params.storylineId
    this.loadMemory()
    this.loadComments()
  },
  methods: {
    async loadComments() {
      this.isLoading = true
      let url = `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
        this.memoryId
      }/comments`
      if (this.hasMore) {
        url = `${url}?starting_after=${this.comments.at(-1).id}`
      } else {
        this.isLoading = true
      }
      try {
        const { data } = await request.get(url)
        this.comments = data.data
        this.hasMore = data.has_more
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async loadMemory() {
      let url = `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
        this.memoryId
      }`
      try {
        const { data } = await request.get(url)
        this.memory = data
      } catch (e) {
        console.log(e)
      }
    },
    async postComment() {
      if (this.message.length > 0) {
        await request
          .post(
            `${MEMORIES_URL.replace('{id}', this.storylineId)}/${
              this.memoryId
            }/comments`,
            { message: this.message }
          )
          .then(e => this.comments.push(e.data))
      }
    }
  },
  computed: {
    creator() {
      return this.memory?.creator ?? {}
    },
    profilePhoto() {
      return this.$store.state.user.user.profile_photo
    }
  }
}
</script>

<style scoped>
.scroll-container {
  flex: 1;
  padding: 16px 24px;
}

.input-container {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px;
}

.profile-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
  margin-right: 8px;
}

.post-button {
  position: absolute;
  right: 28px;
}

.post-body {
  font-size: 21px;
  color: var(--gray-2);
  padding: 16px 0;
  border-bottom: 1px solid #afafaf;
}

.initials {
  padding-left: 0 !important;
}
</style>
