<template>
  <div class="memory">
    <MemoryList
      v-if="layout === 'full' || layout === 'list'"
      :memory="memory"
      :is-feed="isFeed"
    />
    <MemoryGrid v-else :memory="memory" />
  </div>
</template>

<script>
import MemoryList from '@/components/Memory/MemoryList.vue'
import MemoryGrid from '@/components/Memory/MemoryGrid.vue'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    memory: Object,
    isFeed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MemoryList,
    MemoryGrid
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('storyline', ['layout'])
  },
  methods: {
    ...mapActions('modal', ['updateIsVisible']),
    playVideo() {
      const target = event.target
      const parent = target.closest('.img-wrap')
      parent.classList.add('play-video')
      const video = document.querySelector('.play-video .video-container')
      video.play()
    },
    goTo(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.memory {
  border-bottom: solid 1px rgba(175, 175, 175, 0.5);
  padding-bottom: 8px;
}
</style>
