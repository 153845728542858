<template>
  <div class="actions">
    <!--    <SocialSharing />-->
    <Button
      v-if="!isAuthed"
      btnStyle="white is-hollow"
      text="Sign In"
      @handleClick="goToLogin"
    />
    <Button
      v-else-if="!isFollowing"
      btnStyle="white is-hollow"
      text="Follow"
      @handleClick="followStoryline"
    />
    <Button
    v-else-if="!isFollowApproved"
      btnStyle="white is-hollow"
      text="Requested"
      @handleClick="unfollowStoryline"
    />
    <Button
    v-else
      btnStyle="white is-hollow"
      text="Unfollow"
      @handleClick="unfollowStoryline"
    />
    <!--    <dropdown-menu v-model="showMore" direction="right">-->
    <!--      <a @click="onMoreClick" slot="trigger" class="more-button dropdown-toggle"-->
    <!--        ><IconMore-->
    <!--      /></a>-->
    <!--      <ul slot="body">-->
    <!--        <li @click="toggleSocialSharing">Share</li>-->
    <!--        <li v-if="isFollowing" @click="unfollowStoryline">Unfollow</li>-->
    <!--        <li @click="toggleRelationship">My Relationship</li>-->
    <!--        <li v-if="isManaging" @click="toggleManage">Manage</li>-->
    <!--      </ul>-->
    <!--    </dropdown-menu>-->
  </div>
</template>

<script>
// import SocialSharing from '@/components/Shared/SocialSharing.vue'
import Button from '@/components/Form/Button.vue'
import { mapActions, mapState } from 'vuex'
import { request } from '@/utils/interceptor'
import { FOLLOW_STORYLINE_URL, UNFOLLOW_STORYLINE_URL } from '@/utils/endpoints'
// import IconMore from '@/assets/IconMore'

export default {
  data() {
    return {
      showMore: false
    }
  },
  components: {
    // IconMore,
    Button
  },
  computed: {
    ...mapState('storyline', ['isFollowApproved', 'isFollowing', 'isManaging', 'id']),
    isAuthed() {
      return this.$store.state?.user?.authToken?.length > 0
    }
  },
  methods: {
    ...mapActions('storyline', ['updateIsFollowing']),
    followStoryline() {
      request
        .post(FOLLOW_STORYLINE_URL.replace('{id}', this.id))
        .then(() => this.updateIsFollowing(!this.isFollowing))
    },
    unfollowStoryline() {
      request
        .post(UNFOLLOW_STORYLINE_URL.replace('{id}', this.id))
        .then(() => this.updateIsFollowing(!this.isFollowing))
    },
    toggleSocialSharing() {
      // this.updateIsFollowing(!this.isFollowing)
    },
    toggleRelationship() {
      // this.updateIsFollowing(!this.isFollowing)
    },
    toggleManage() {
      // this.updateIsFollowing(!this.isFollowing)
    },
    onMoreClick() {
      this.showMore = true
    },
    goToLogin() {
      this.$router.push({ name: 'auth', params: { redirect: this.$route.path} })
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-left: 16px;

  .icon {
    cursor: pointer;
    margin-right: 20px;
  }

  .btn {
    font-size: 1.1rem;
    min-height: 24px;
  }
}

.more-button {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  font-size: 12px;
  color: var(--gray-2);
  margin: 12px;
}

.v-dropdown-menu__container {
  width: auto;
}
</style>
