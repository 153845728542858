<template>
  <button
    :class="btnStyle"
    :disabled="isDisabled"
    class="btn"
    @click="handleClick"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    btnStyle: String,
    isDisabled: Boolean
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 2em;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: auto;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  min-height: 44px;
  opacity: 1;
  outline: none;
  padding: 0.65em 1.15em;
  text-align: center;
  text-decoration: none;
  transition: opacity 500ms;

  :disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.35;
  }

  &.white {
    background-color: var(--white);
    color: var(--color-1);

    &.is-hollow {
      background-color: transparent !important;
      border-color: #86b1d4;
      color: var(--white);
    }

    &:active {
      opacity: 0.5;
    }
  }

  &.color-1 {
    background-color: var(--color-1);
    color: var(--white);

    &.is-hollow {
      background-color: transparent !important;
      border-color: var(--color-1);
      color: var(--color-1);
    }

    &:active {
      filter: brightness(0.7);
    }
  }

  &.gradient {
    background: linear-gradient(
      20deg,
      rgba(0, 108, 177, 1) 0%,
      rgba(40, 81, 151, 1) 70%
    );
    color: var(--white);
  }
}
</style>
