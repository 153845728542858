import { request } from '@/utils/interceptor'

const state = {
  inviteSending: false,
  inviteStatus: ''
}

const actions = {
  async sendInvite({ commit }, payload) {
    try {
      const response = await request.post('/invites', payload)
      if (response.data.id) {
        commit('setInviteStatus', 'sent')
        commit('setSendingInvite', false)
      }
    } catch (error) {
      commit('setSendingInvite', false)
      commit('setInviteStatus', 'error')
      console.log(error)
    }
  },
  updateSendingInvite({ commit }, payload) {
    commit('setSendingInvite', payload)
  },
  updateInviteStatus({ commit }, payload) {
    commit('setInviteStatus', payload)
  }
}

const mutations = {
  setSendingInvite: (state, status) => {
    state.inviteSending = status
  },
  setInviteStatus: (state, status) => {
    state.inviteStatus = status
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
