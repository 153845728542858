<template>
  <svg
    id="Upload_icon"
    data-name="Upload icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40.705"
    height="37.88"
    viewBox="0 0 40.705 37.88"
  >
    <path
      id="UPLOAD"
      d="M2847.736,571.718h-36.008a2.358,2.358,0,0,1-2.348-2.367V557.512a2.348,2.348,0,1,1,4.7,0v9.471h31.312v-9.471a2.349,2.349,0,1,1,4.7,0v11.839A2.359,2.359,0,0,1,2847.736,571.718Zm-10.958-25.253a2.338,2.338,0,0,1-1.746-.784h0l-2.951-3.306v14.349h0a2.349,2.349,0,1,1-4.7,0h0V542.375l-2.95,3.306h0a2.345,2.345,0,0,1-4.094-1.584,2.367,2.367,0,0,1,.6-1.584h0l7.045-7.892h0a2.335,2.335,0,0,1,3.49,0h0l7.044,7.892h0a2.371,2.371,0,0,1-1.745,3.951Z"
      transform="translate(-2809.38 -533.838)"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconUpload'
}
</script>

<style scoped></style>
