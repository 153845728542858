<template>
  <div class="container">
    <perfect-scrollbar class="mobile-container">
      <div class="column-container">
        <div class="story-image">
          <img
              alt="storyline1"
              class="storyline-logo"
              src="../../assets/storyline-logo-white.svg"
          />
        </div>
        <div class="input-wrap">
          <input
            v-model="name"
            class="form-input"
            placeholder="First name"
            type="text"
          />
          <input
            v-model="lastName"
            class="form-input"
            placeholder="Last name"
            type="text"
          />
          <input
            v-model="email"
            class="form-input"
            placeholder="Email address"
            type="text"
          />
          <input
            v-model="phoneNumber"
            class="form-input"
            placeholder="Phone number"
            type="tel"
          />
        </div>
        <div class="widget-container">
          <button class="primary-button" v-on:click="signup">
            Create an Account
          </button>
        </div>

        <a class="text-center bottom-link text-gray" v-on:click="gotoLogin">
          Already have an account? Sign in
        </a>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { request } from '@/utils/interceptor'
import { USERS_URL } from '@/utils/endpoints'

import router from '@/router'

export default {
  metaInfo: { title: 'Storyline' },
  name: 'SignUp',
  data() {
    return {
      method: null,
      name: null,
      lastName: null,
      email: null,
      phoneNumber: null
    }
  },
  mounted() {
    this.method = this.$route.params.method

    if (this.method === 'phone') {
      this.phoneNumber = this.$route.params.credential
    } else if (this.method === 'email') {
      this.email = this.$route.params.credential
    }
  },
  methods: {
    async signup() {
      try {
        const {
          data: { method, index }
        } = await request.post(USERS_URL, {
          name: this.name,
          last_name: this.lastName,
          email: this.email,
          phone_number: this.phoneNumber,
          env: 'production'
        })
        router.push({
          name: 'signin',
          params: { method, index, credential: this.email },
          replace: true
        })
      } catch (e) {
        console.log(e.message)
      }
    },
    gotoLogin() {
      router.replace('signup')
    }
  }
}
</script>

<style scoped>
.story-image {
  width: 100%;
  height: 183px;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  justify-content: center;
  align-content: center;
}

.storyline-logo {
  width: 50%;
}

@media screen and (max-width: 675px) {
  .story-image {
    height: 27vw;
  }
}

.input-wrap {
  margin: 30px 30px 0px 30px;
}

.form-input {
  margin: 4px 0;
}

.widget-container {
  margin: 10px 30px;
}

.bottom-link {
  margin: 10px 30px 0 30px;
}
</style>
