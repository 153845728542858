<template>
  <div class="column-container flex-container">
    <div class="top-nav-container">
      <p class="nav-title">Following</p>
      <input
        v-model="keyword"
        class="search-box"
        v-on:input="onKeyWordChange"
      />
      <div class="search-icon">
        <IconSearch color="white" />
      </div>
    </div>
    <Loader v-if="isLoading" class="flex-fill" />
    <perfect-scrollbar v-if="!isLoading" class="content-wrap flex-fill">
      <div
        v-for="item in filteredItems"
        :key="item.id"
        class="story-container flex-container column-container align-center"
      >
        <img
          v-if="item.profile_photo"
          :src="item.profile_photo"
          class="story-avatar"
          alt="profile-img"
        />
        <div v-else class="story-avatar">
          <span class="text-white">
            {{ item.first_name.charAt(0) }}
            {{ item.last_name.charAt(0) }}
          </span>
        </div>
        <h4 class="story-name text-primary">{{ item.full_name }}</h4>
        <p class="story-info">{{ item.birth_location.full_address }}</p>
        <p class="story-info">{{ item.death_location.full_address }}</p>
        <p class="story-info">{{ period(item) }}</p>
        <div class="btn-container">
          <button class="primary-button" @click="onViewStoryline(item)">
            View Storyline
          </button>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { request } from '@/utils/interceptor'
import { USERS_URL } from '@/utils/endpoints'
import Loader from '@/components/Shared/Loader'
import IconSearch from '@/assets/IconSearch'

export default {
  metaInfo: { title: 'Following | Storyline' },
  name: 'Following',
  components: { IconSearch, Loader },
  data() {
    return {
      items: [],
      filteredItems: [],
      keyword: '',
      isLoading: false
    }
  },
  mounted() {
    const id = this.$store.state.user.user.id
    this.isLoading = true
    request.get(`${USERS_URL}/${id}/following`).then(e => {
      this.items = e.data.data
      this.filteredItems = this.items
      this.isLoading = false
    })
  },
  computed: {},
  methods: {
    onKeyWordChange() {
      if (this.keyword.length < 1) return
      this.filteredItems = this.items.filter(e =>
        e.full_name.includes(this.keyword)
      )
    },
    onViewStoryline(item) {
      this.$router.push(`/storyline/${item.id}`)
    },
    period(item) {
      return `${item?.birth_date?.substring(
        0,
        4
      )} - ${item?.death_date?.substring(0, 4)}`
    }
  }
}
</script>

<style scoped>
.top-nav-container {
  height: 155px;
  width: 100%;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  flex-direction: column;
  padding: 22px 18px;
  position: relative;
}

.nav-title {
  font-size: 18px;
  color: white;
  width: 100%;
  text-align: center;
  margin-top: auto;
  margin-bottom: 24px;
}

.search-box {
  background-color: #ffffff60;
  border: 0 solid #ffffff60;
  height: 44px;
  border-radius: 22px;
  font-size: 18px;
  color: white;
  padding: 13px 15px;
}

.content-wrap {
  margin: 12px;
}

.story-container {
  margin: 8px;
  padding: 20px 0 10px 0;
  background-color: white;
  border-radius: 6px;
}

.story-avatar {
  width: 74px;
  height: 74px;
  border-radius: 37px;
  object-fit: cover;
}

.story-name {
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 10px;
}

.story-info {
  color: #22222280;
}

.btn-container {
  padding: 16px 20px 8px 20px;
  width: 100%;
}

.search-icon {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

/*@media screen and (min-width: 675px) {*/
/*  .search-icon {*/
/*    position: absolute;*/
/*    right: calc(30px + (100vw - 675px) / 2);*/
/*  }*/
/*}*/
</style>
