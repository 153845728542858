<template>
  <div>
    <editor-nav
      extended
      title="Review Pending Items"
      :storyline-id="storylineId"
    />
    <perfect-scrollbar>
      <div class="header-container">
        <p class="header-text">FOLLOW REQUESTS</p>
        <p class="header-tails">pending</p>
      </div>
      <editor-following-item
        v-for="item in followings"
        :key="item.id"
        :item="item"
        :storyline-id="storylineId"
      />
      <div class="header-container">
        <p class="header-text">MEMORIES</p>
        <p class="header-tails">pending</p>
      </div>
      <editor-memeory-item
        v-for="item in memories"
        :key="item.id"
        :item="item"
        :storyline-id="storylineId"
      />
    </perfect-scrollbar>
  </div>
</template>

<script>
import EditorNav from '../Shared/EditorNav'
import { request } from '../../utils/interceptor'
import EditorFollowingItem from './EditorFollowingItem'
import EditorMemeoryItem from './EditorMemeoryItem'
export default {
  name: 'EditorPending',
  components: { EditorMemeoryItem, EditorFollowingItem, EditorNav },
  data() {
    return {
      storylineId: '',
      memories: [],
      followings: []
    }
  },
  beforeMount() {
    this.storylineId = this.$route.params.storylineId
    request.get(`/storylines/${this.storylineId}/pending`).then(e => {
      this.memories = e.data.data.memories
      this.followings = e.data.data.following
    })
  }
}
</script>

<style scoped>
.header-container {
  background-color: var(--color-2);
  padding: 14px 24px 8px 24px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.header-text {
  font-size: 18px;
  text-transform: uppercase;
  color: white;
}

.header-tails {
  font-size: 12px;
  color: white;
}
</style>
