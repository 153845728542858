<template>
  <div class="flex-fill flex-container column-container">
    <perfect-scrollbar class="flex-fill">
      <div class="profile-card">
        <div class="profile-avatar" @click="goToUploadAvatar">

          <img
            v-if="photo"
            :src="photo"
          />
          <div v-else>
            <span class="text-white">
              {{ profilePlaceholder }}
            </span>
          </div>

          <div class="profile-icon-edit">
            <IconEdit fill="#285197" stroke="#FFF"/>
          </div>
        </div>
        <router-link class="profile-item" :to="{ name: 'edit' }">
          <p class="profile-title">Full name</p>
          <p class="profile-value text-color-2 bold">{{ name }}</p>
        </router-link>
        <div class="profile-item">
          <p class="profile-title">Phone</p>
          <p class="profile-value text-color-2 bold">{{ phone }}</p>
        </div>
        <div class="profile-item">
          <p class="profile-title">Email</p>
          <p class="profile-value text-color-2 bold">{{ email }}</p>
        </div>
        <div class="profile-item">
          <p class="profile-title">App Version</p>
          <p class="profile-value">1.11.1</p>
        </div>
      </div>
    </perfect-scrollbar>
    <button class="text-button logout-button" @click="logout">Sign out</button>
  </div>
</template>

<script>
import IconEdit from '../../assets/IconEdit'

export default {
  metaInfo: { title: 'Profile | Storyline' },
  name: 'Profile',
  components: { IconEdit },
  computed: {
    email() {
      return this.$store.state.user.user.email
    },
    phone() {
      return this.$store.state.user.user.phone_number
    },
    photo() {
      return this.$store.state.user.user.profile_photo
    },
    name() {
      return `${this.$store.state.user.user.name} ${this.$store.state.user.user.last_name} `
    },
    profilePlaceholder() {
      return `${this.$store.state.user.user.name.charAt(
        0
      )} ${this.$store.state.user.user.last_name.charAt(0)}`
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/resetUserState')
    },
    goToUploadAvatar() {
      this.$router.push({
        name: 'editor_upload_image',
        params: {
          title: 'Update Avatar',
          uploadUrl: `/users/${this.$store.state.user.user.id}`
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-card {
  margin: 68px 20px 0 20px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
}

.profile-avatar {
  width: 95px;
  height: 95px;
  border-radius: 48px;
  margin-top: -40px;
  border: 2px solid var(--color-1);
  background-color: var(--color-2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 95px;
    height: 95px;
    border-radius: 48px;
    border: 2px solid var(--color-1);
    background-color: var(--color-2);

    &:hover {
      cursor: pointer;
    }
  }
}

.profile-icon-edit {
  position: absolute;
  right: -12.5px;
  top: 0px;
  margin: 0 8px 8px 0;
  height: 25px;
  width: 25px;
  background: #fff;
  border-radius: 50%;

  svg {
    height: 15px;
    width: 15px;
    margin-top: 5px;
    margin-left: 5px;
  }
}

.profile-item {
  border-bottom: 1px solid var(--gray-2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-title {
  color: var(--gray-2);
  margin: 24px auto 16px 4px;
}

.profile-value {
  margin: 24px 20px 16px 0;
}
</style>
