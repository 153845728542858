<template>
  <perfect-scrollbar class="obituary-scroll">
    <obituary v-if="obituary" :obituary="obituary" />
    <!--    <CondolenceList />-->
  </perfect-scrollbar>
</template>

<script>
import Obituary from '@/components/Obituary/Obituary.vue'
// import CondolenceList from '@/components/Condolence/CondolenceList.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Obituary
    // CondolenceList
  },
  computed: {
    ...mapState('storyline', ['obituary'])
  },
  methods: {
    onClickObituary() {}
  }
}
</script>

<style lang="scss" scoped>
.obituary-wrap {
  display: contents;
}

.obituary-scroll {
  padding: 24px;
  background: var(--white);
}
</style>
