<template>
  <div class="column-container primary-background">
    <div class="top-nav-container flex-container column-container">
      <p class="nav-title">Manage Account</p>
      <div :class="$route.name" class="storyline-nav">
        <span class="link my-storyline" @click="slideBar('my_storyline')">
          My Storylines
        </span>
        <span class="link profile" @click="slideBar('profile')"
          >Account Details</span
        >
        <div class="nav-indicator position-2"></div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  metaInfo: { title: 'Account Settings | Storyline' },
  name: 'Account',
  components: {},
  methods: {
    slideBar(location) {
      // let path
      let indicator = document.querySelector('.nav-indicator')
      const tab = event.target
      if (location === 'my_storyline') {
        // path = `/storyline/${this.id}`
        indicator.classList.add('position-1')
        indicator.classList.remove('position-2')
      } else if (location === 'profile') {
        // path = `/storyline/${this.id}/services`
        indicator.classList.add('position-2')
        indicator.classList.remove('position-1')
      }

      let linkArray = document.querySelectorAll('.storyline-nav .link')
      linkArray.forEach(link => {
        link.classList.remove('is-active')
      })
      tab.classList.add('is-active')
      if (location !== this.$route.name) this.$router.push({ name: location })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-nav-container {
  height: 130px;
  width: 100%;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  display: flex;
  padding: 12px 18px 0 18px;
}

.logout-button {
  color: var(--color-1);
  margin: 0 auto;
  width: auto;
}

.nav-title {
  margin-top: auto;
  margin-bottom: 16px;
  width: 100%;
  font-size: 18px;
  color: white;
  text-align: center;
}

.storyline-nav {
  &.profile {
    .link.profile {
      color: var(--white);
    }

    .nav-indicator {
      transform: translateX(0);
    }
  }

  &.my_storyline {
    .link.my-storyline {
      color: var(--white);
    }

    .nav-indicator {
      transform: translateX(100%);
    }
  }

  .link {
    align-items: center;
    color: rgba(255, 255, 255, 0.45);
    cursor: pointer;
    display: flex;
    font-family: 'sans-light', serif;
    font-size: 16px;
    justify-content: center;
    padding: 18px 0;
    text-align: center;
    text-decoration: none;
    width: calc(100% / 2);

    &.is-active {
      color: var(--white);
      font-family: 'sans-bold', serif;

      .icon {
        display: block;
      }
    }

    .icon {
      display: none;
      margin: 5px 0 0 10px;
      pointer-events: none;
    }
  }

  .nav-indicator {
    background-color: var(--color-1);
    bottom: 0;
    height: 4px;
    left: 0;
    position: absolute;
    transition: transform 500ms;
    width: calc(100% / 2);

    &.position-1 {
      transform: translateX(0);
    }

    &.position-2 {
      transform: translateX(100%);
    }
  }
}
</style>
