<template>
  <div class="container">
    <perfect-scrollbar class="mobile-container">
      <div class="column-container">
        <slider
          v-model="sliderIndex"
          :autoplay="false"
          :controlBtn="false"
          :indicators="false"
          :touch="false"
          animation="fade"
          class="slider-container"
          height="auto"
        >
          <slider-item v-for="(e, index) in list" :key="index">
            <div class="story-background">
              <img :src="e.image" alt="storyline1" class="show-image" />
            </div>
            <h2 class="text-center text-wrap text-primary">
              {{ e.text }}
            </h2>
          </slider-item>
        </slider>
        <div class="indicator-container">
          <div
            v-for="(e, index) in list"
            :key="index"
            :class="
              index === sliderIndex ? 'indicator-item-active' : 'indicator-item'
            "
          >
            <div v-if="sliderIndex === index" class="indicator-item-inside" />
          </div>
        </div>
        <div class="skip-wrap">
          <button class="text-button" v-on:click="skip">
            Skip
          </button>
        </div>
        <div class="continue-wrap">
          <button class="primary-button" v-on:click="onContinue">
            Continue
          </button>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider'
import router from '@/router'
import image1 from '@/assets/walkthrough_1.png'
import image2 from '@/assets/walkthrough_2.png'
import image3 from '@/assets/walkthrough_3.png'

export default {
  metaInfo: { title: 'Storyline' },
  name: 'WalkThru',
  components: {
    Slider,
    SliderItem
  },
  data() {
    return {
      list: [
        {
          text:
            'Build your loved one’s Storyline profile with pictures, videos, memories, and more.',
          image: image1
        },
        {
          text: 'Share with others with a simple link or QR code.',
          image: image2
        },
        {
          text: 'Let their legacy live on for generations to enjoy.',
          image: image3
        }
      ],
      sliderIndex: 0
    }
  },
  mounted() {
  },
  methods: {
    async onContinue() {
      if (this.sliderIndex < 2) {
        this.sliderIndex++
      } else {
        await this.skip()
      }
    },
    async skip() {
      await this.$store.dispatch('user/completeWalkThrough')
      await router.push({
        name: 'signup',
        params: { credential: this.credential }
      })
    }
  }
}
</script>

<style scoped>
.slider-container {
  width: 100%;
  height: 1188px !important;
}

.story-background {
  width: 100%;
  height: 837px;
  background: linear-gradient(20deg, #006cb1, #285197 70%);
  padding: 32px 32px 0 32px;
  overflow: hidden;
}

@media screen and (max-width: 675px) {
  .story-background {
    height: 124vw;
  }

  .slider-container {
    height: 176vw !important;
  }
}


.show-image {
  object-fit: fill;
  width: 100%;
  overflow: hidden;
}

.text-wrap {
  margin: 64px 48px 32px 48px;
  height: 135px;
}

.skip-wrap {
  margin: 0 30px;
}

.continue-wrap {
  margin: 16px 30px;
}

.indicator-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  top: 878px;
}

@media screen and (max-width: 675px) {
  .indicator-container {
    top: 130vw;
  }
}

.indicator-item-active {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: var(--color-2);
  margin: 0 5px;
}

.indicator-item-inside {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: var(--color-2);
  border: 2px solid white;
  margin: 1px 1px;
}

.indicator-item {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: var(--gray-1);
  margin: 0 8px;
}
</style>
