<template>
  <perfect-scrollbar class="flex-fill">
    <perfect-scrollbar class="storylines-container">
      <Loader v-if="isLoading" />
      <div
        v-for="item in storylines"
        :key="item.id"
        class="story-container flex-container column-container align-center"
      >
        <img
          v-if="item.profile_photo"
          :src="item.profile_photo"
          class="story-avatar"
          alt="profile-img"
        />
        <div v-else class="story-avatar">
          <span class="text-white">
            {{ item.first_name.charAt(0) }}
            {{ item.last_name.charAt(0) }}
          </span>
        </div>
        <h4 class="story-name text-primary">{{ item.full_name }}</h4>
        <p class="story-info">{{ item.death_location.full_address }}</p>
        <p class="story-info">{{ period(item) }}</p>
        <p
          v-if="item.pending.count"
          class="notify-message text-primary width-100 text-center"
        >
          {{ item.pending.count }} Pending Items
        </p>
        <div class="btn-container">
          <button class="primary-button" @click="goToStoryEditor(item)">
            Manage
          </button>
        </div>
      </div>
    </perfect-scrollbar>
    <button class="fab-position" @click="goToNewStoryline">
        <icon-add />
    </button>
  </perfect-scrollbar>
</template>

<script>
import { request } from '@/utils/interceptor'
import { USERS_URL } from '@/utils/endpoints'
import Loader from '@/components/Shared/Loader'
  import IconAdd from '../../assets/IconAdd'

export default {
  metaInfo: { title: 'My Storylines | Storyline' },
  name: 'MyStorylines',
  components: { Loader, IconAdd },
  data() {
    return {
      storylines: [],
      isLoading: false
    }
  },
  mounted() {
    const id = this.$store.state.user.user.id
    this.isLoading = true
    request
      .get(`${USERS_URL}/${id}/storylines`)
      .then(e => {
        this.storylines = e.data.data
        this.isLoading = false
      })
      .catch(() => (this.isLoading = false))
  },
  computed: {},
  methods: {
    goToStoryEditor(item) {
      this.$router.push({
        name: 'story_editor',
        params: { storylineId: item.id }
      })
    },
    period(item) {
      return `${item?.birth_date?.substring(
        0,
        4
      )} - ${item?.death_date?.substring(0, 4)}`
    },
    goToNewStoryline() {
      this.$router.push({
        name: 'new_storyline'
      })
    }
  }
}
</script>

<style scoped>
.storylines-container {
  background-color: #f5f5f5;
  padding: 6px 12px;
}

.story-container {
  margin: 6px;
  padding: 20px 0 10px 0;
  background-color: white;
  border-radius: 6px;
}

.story-avatar {
  width: 74px;
  height: 74px;
  border-radius: 37px;
  object-fit: cover;
  background-color: var(--color-2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.story-name {
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 10px;
}

.story-info {
  color: #22222280;
}

.notify-message {
  margin-top: 18px;
}

.btn-container {
  margin-top: 15px;
  padding: 0 20px;
  width: 100%;
}

.fab-position {
  position: fixed;
}
</style>
