<template>
  <perfect-scrollbar>
    <div class="editor-container">
      <img
        v-if="editor.profile_photo"
        :src="editor.profile_photo"
        class="editor-avatar"
        alt="profile-img"
      />
      <div v-else class="editor-avatar">
        <span class="text-white">
          {{ editor.name.charAt(0) }}
          {{ editor.last_name.charAt(0) }}
        </span>
      </div>
      <div
        class="item-container separator flex-container row-container justify-between"
      >
        <p class="text-gray-2">Full name</p>
        <p class="text-primary bold">
          {{ editor.name }} {{ editor.last_name }}
        </p>
      </div>
      <div
        class="item-container separator flex-container row-container justify-between"
      >
        <p class="text-gray-2">Email</p>
        <p class="text-primary bold">{{ editor.email }}</p>
      </div>
      <div class="item-container flex-container row-container justify-between">
        <p class="text-gray-2">Phone</p>
        <p class="text-primary bold">{{ editor.phone_number }}</p>
      </div>
    </div>
    <div class="width-100 justify-center">
      <p class="text-primary delete-button text-center" @click="deleteEditor">
        Remove this Storyeditor
      </p>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { request } from '../../utils/interceptor'
import { USERS_URL } from '../../utils/endpoints'

export default {
  name: 'EditorDetails',
  data() {
    return {
      editor: {},
      storylineId: '',
      userId: '',
      editorId: ''
    }
  },
  mounted() {
    this.storylineId = this.$route.params.storylineId
    this.userId = this.$route.params.userId
    this.editorId = this.$route.params.editorId
    request
      .get(`${USERS_URL}/${this.userId}`)
      .then(e => (this.editor = e.data))
      .catch(() =>
        this.$notify({
          group: 'storyline',
          title: 'You are not allowed to manage that editor.',
          type: 'error'
        })
      )
  },
  methods: {
    deleteEditor() {
      request
        .delete(`/storylines/${this.storylineId}/storyeditors/${this.editorId}`)
        .then(() => this.$router.back())
        .catch(() =>
          this.$notify({
            group: 'storyline',
            title: 'You are not allowed to manage that editor.',
            type: 'error'
          })
        )
    }
  }
}
</script>

<style scoped>
.editor-container {
  margin: 85px 16px 16px 16px;
  padding: 20px 0 0 0;
  background-color: white;
  border-radius: 6px;
}

.editor-avatar {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-1);
  background-color: var(--color-2);
  margin: -65px 24px 0 24px;
}

.item-container {
  padding: 21px 24px;
}

.separator {
  border-bottom: solid 1px rgba(175, 175, 175, 0.55);
}

.delete-button {
  margin: 32px 0;
}
</style>
