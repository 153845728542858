<template>
  <div class="services-wrap">
    <Loader v-if="loading" />
    <div v-else>
      <Service
        v-for="service in servicesList"
        :key="service.id"
        :data="service"
      />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Shared/Loader.vue'
import Service from '@/components/Services/Service.vue'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      loading: true
    }
  },
  components: {
    Loader,
    Service
  },
  computed: {
    ...mapState('main', ['servicesList'])
  },
  methods: {
    ...mapActions('main', ['fetchServices'])
  },
  async created() {
    const payload = {
      storyline: this.$route.params.storylineId
    }
    await this.fetchServices(payload)
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.services-wrap {
  background-color: var(--color-3);
  padding: 0 30px 30px;
  display: contents;
}
</style>
