<template>
  <div class="links-wrap">
    <Link
      v-for="link in links"
      :key="link.id"
      :link="link"
    />
  </div>
</template>

<script>
import Link from '@/components/Links/Link.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Link
  },
  computed: {
    ...mapState('storyline', ['links'])
  }
}
</script>

<style lang="scss" scoped>
.links-wrap {
  background-color: var(--color-3);
  padding: 15px 15px 50px;
  display: contents;
}
</style>
