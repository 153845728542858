var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('perfect-scrollbar',[(_vm.storyline.id)?_c('div',{staticClass:"editor-container"},[_c('div',[(_vm.storyline.profile_photo)?_c('img',{staticClass:"editor-avatar",attrs:{"src":_vm.storyline.profile_photo,"alt":"profile-img"}}):_c('div',{staticClass:"editor-avatar"},[_c('span',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.storyline.first_name.charAt(0))+" "+_vm._s(_vm.storyline.last_name.charAt(0))+" ")])]),_c('router-link',{staticClass:"edit-container",attrs:{"to":{
          name: 'editor_upload_image',
          params: {
            title: 'Update Storyline',
            uploadUrl: ("/storylines/" + _vm.storylineId)
          }
        }}},[_c('IconEdit',{attrs:{"size":12}})],1)],1),_c('div',{staticClass:"item-container separator flex-container row-container justify-between"},[_c('p',{staticClass:"text-gray-2"},[_vm._v("Full name")]),_c('router-link',{staticClass:"text-primary bold",attrs:{"to":{
          name: 'editor_update_name',
          params: { storylineId: _vm.storylineId }
        }}},[_vm._v(" "+_vm._s(_vm.storyline.full_name)+" ")])],1),_c('div',{staticClass:"item-container separator flex-container row-container justify-between"},[_c('p',{staticClass:"text-gray-2"},[_vm._v("Birth date")]),_c('p',{staticClass:"text-primary bold"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.storyline.birth_date,'DD MMMM YYYY'))+" ")])]),_c('div',{staticClass:"item-container separator flex-container row-container justify-between"},[_c('p',{staticClass:"text-gray-2"},[_vm._v("Birth in")]),_c('p',{staticClass:"text-primary bold"},[_vm._v(" "+_vm._s(_vm.storyline.birth_location.full_address)+" ")])]),_c('div',{staticClass:"item-container separator flex-container row-container justify-between"},[_c('p',{staticClass:"text-gray-2"},[_vm._v("Death date")]),_c('p',{staticClass:"text-primary bold"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.storyline.death_date,'DD MMMM YYYY'))+" ")])]),_c('div',{staticClass:"item-container flex-container row-container justify-between"},[_c('p',{staticClass:"text-gray-2"},[_vm._v("Death in")]),_c('p',{staticClass:"text-primary bold"},[_vm._v(" "+_vm._s(_vm.storyline.death_location.full_address)+" ")])]),_c('div',{staticClass:"item-container flex-container row-container justify-between"},[_c('p',{staticClass:"text-gray-2"},[_vm._v("Gender")]),_c('p',{staticClass:"text-primary bold"},[_vm._v(_vm._s(_vm.storyline.gender))])]),_c('div',{staticClass:"item-container flex-container row-container justify-between"},[_c('p',{staticClass:"text-gray-2"},[_vm._v("Privacy Setting")]),_c('router-link',{staticClass:"text-primary bold",attrs:{"to":{ name: 'editor_update_privacy', params: { storylineId: _vm.storylineId } }}},[_vm._v(_vm._s(_vm.storyline.privacy))])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }