<template>
  <svg
    height="16.73"
    viewBox="0 0 20.076 16.73"
    width="20.076"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group_5" data-name="Group 5" opacity="0.7">
      <path
        id="Path_6"
        :fill="color"
        d="M4495.592,655.241a1.238,1.238,0,0,1-.886-.369l-7.692-7.777a5.054,5.054,0,0,1,0-7.116,4.976,4.976,0,0,1,7.06,0l1.516,1.532,1.508-1.53a4.975,4.975,0,0,1,7.063,0,5.053,5.053,0,0,1,0,7.116l-7.681,7.772a1.238,1.238,0,0,1-.884.372Zm-5.052-15.057a3.271,3.271,0,0,0-2.339.974,3.38,3.38,0,0,0,0,4.76l7.392,7.472,7.382-7.471a3.379,3.379,0,0,0,0-4.759,3.306,3.306,0,0,0-4.689,0l-2.1,2.131a.836.836,0,0,1-.6.25h0a.842.842,0,0,1-.595-.248l-2.11-2.134A3.288,3.288,0,0,0,4490.541,640.184Z"
        data-name="Path 6"
        transform="translate(-4485.55 -638.511)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconLike',
  props: {
    color: {
      type: String,
      default: '#50badd'
    }
  }
}
</script>

<style scoped></style>
